import React from 'react'
import { useState, useEffect } from 'react'
import GridChartMnrAsset from './gridChartMnrAsset';
import Loading from './loading';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import Enumerable from 'linq';
import { orderBy } from "@progress/kendo-data-query";
import 'hammerjs';
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { FaSyncAlt } from "react-icons/fa";
import { ColumnMenu } from "./columnMenu";
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeriesLabels,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import { formatNumber, formatDate } from '@telerik/kendo-intl';

const MjrMnrAssetDetails = ({ data, mnrData, astData, performAllData }) => {
    debugger;

    const [mjrPie, setMjrPie] = useState(1);
    const [mnrRadioStat, setMnrRadioStat] = useState('checked');
    const [mjrDonut, setMjrDonut] = useState(0);
    const [mjrBar, setMjrBar] = useState(0);
    const [mjrRadioStat, setMjrRadioStat] = useState('checked');
    const [updatedMnrDataNew, setUpdatedMnrDataNew] = useState(mnrData);
    const [updatedAssetDataNew, setUpdatedAssetDataNew] = useState(astData);
    const [selMjrAsset, SetSelMjrAsset] = useState('');
    const [sort, setSort] = React.useState([]);
    const [mnrArea, setMnrArea] = useState(0);
    const [mnrBar, setMnrBar] = useState(1);
    const [mnrLine, setMnrLine] = useState(0);

    const [updatedAssetData, setUpdatedAssetData] = useState(astData);

    const [selMnrAsset, SetSelMnrAsset] = useState('');

    const [sortAsset, setAssetSort] = React.useState([]);
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.mvPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.mvPercent.toFixed(2)}% \n $${formatNumber(props.dataItem.mv, "##,#.00")}`;
    };
    const chartDefaultV4Colors = [
        "#235ee7",
        "#dce4ea",
        "#4ac9c9",
        "#d3dffb",
        "#7c9ff2",
        "#f3b021",
        "#f8d0c0",
    ];
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const defaultTooltipRender = ({ point }) => `$${formatNumber(point.value, "##,#.00")}`;

    const handleSetBarMnr = () => {
        setMnrArea(0);
        setMnrBar(1);
        setMnrLine(0);
        setMnrRadioStat('checked');

    }
    const handleSetArea = () => {
        setMnrBar(0);
        setMnrArea(1);
        setMnrLine(0);
        setMnrRadioStat('');
    }
    const handleRefresh = () => {
        debugger;
        setUpdatedMnrDataNew(mnrData);
        setUpdatedAssetDataNew(astData);
        setUpdatedAssetData(astData);
        SetSelMjrAsset('');
        SetSelMnrAsset('');
        // forceUpdate();
    }
    //const forceUpdate = React.useCallback(() => updateState({}), []);
    const plotareaclick = (e) => {
debugger;
        var mjrAsetTypeNm = e.category;
        SetSelMjrAsset(mjrAsetTypeNm);
        //  var mjrAsetTypeRow=Enumerable.from(data).where(w => w.mjrAstType === mjrAsetTypeNm);
        var mjrAsetTypeRow = Enumerable.from(data)
            .where(w => w.mjrAstType === mjrAsetTypeNm).toArray();

        var mjrAsetTypeId = mjrAsetTypeRow[0].mjrAstTypId;
        var mnrAstdata = Enumerable.from(mnrData).where(w => w.mjrAstTypId === mjrAsetTypeId)
            .toArray();
        var assetData = Enumerable.from(astData).where(w => w.mjrAstTypId === mjrAsetTypeId)
            .toArray();
            
        setUpdatedMnrDataNew(mnrAstdata);
        setUpdatedAssetDataNew(assetData);
        setUpdatedAssetData(assetData);
        //localStorage.setItem('changeSelect', "0");
        // localStorage.setItem('changeMinor', "0");

    }

    const handleSetPieMjr = (e) => {
        debugger;
        setMjrPie(1);
        setMjrDonut(0);
        setMjrRadioStat('checked');

    }
    const handleSetDonutMjr = () => {
        setMjrPie(0);
        setMjrDonut(1);
        setMjrRadioStat('');

    }

    function subtractYears(years) {
        var date = new Date(localStorage.getItem("processingDate"));
        date.setFullYear(date.getFullYear() - years);
        debugger;
        date = (date.getMonth() + 1).toString().padStart(2, '0') + "/" + (date.getDate().toString().padStart(2, '0')) + "/" + (date.getFullYear());

        //date=date.toString();
        localStorage.setItem("businessDate", date);
        return date;
    }

    const FormatLongNumber = ({ value }) => {

        if (value == 0) {

            return 0;

        }

        else {



            if (value <= 999 && value >= -999) {

                return value;

            }



            // thousands

            else if (value >= 1000 && value <= 999999) {

                return (value / 1000) + 'K';

            }

            // millions

            else if (value >= 1000000 && value <= 999999999) {

                return (value / 1000000) + 'M';

            }

            // billions

            else if (value >= 1000000000 && value <= 999999999999) {

                return (value / 1000000000) + 'B';

            }

            else if (value <= -1000 && value >= -999999) {

                return (value / 1000) + 'K';

            }

            else if (value <= -1000000 && value >= -999999999) {

                return (value / 1000) + 'M';

            }

            else if (value <= -1000000000 && value >= -999999999999) {

                return (value / 1000) + 'B';

            }

            else

                return value;

        }

    }
    const onRowClick = e => {
        debugger;
         var mnrAsetType = e.dataItem.mnrAstTypId;
       
         SetSelMnrAsset(e.dataItem.mnrAstType);
         var assetData = Enumerable.from(astData).where(w => w.mnrAstTypId === mnrAsetType)
             .toArray();
 
         setUpdatedAssetData(assetData);
        //  localStorage.setItem('changeSelect', "0");
        //  localStorage.setItem('changeMinor', "1");
         //var Data = mnrData.find((mjrTypeDtls) => mjrTypeDtls.mjrAstTypId === mjrAsetType);
         //console.log( Data);
 
     };

    return (
        <div>
            <div className='row py-1 my-1 mx-1'>
                <div className='col-sm-10 col-md-6 col-lg-6 my-1'>

                    <div className='card rounded h-100'>

                    <div className='card-header'><button className='btnCustom btn-link' onClick={handleRefresh}><FaSyncAlt ></FaSyncAlt></button> <span className='tableheader justify-content-center ' style={{verticalAlign:'middle'}}>Asset Allocation</span></div>
                        <div className='card-body'>

                            <div className="form-check mt-1 k-text-center py-2 mb-2">
                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" defaultChecked={mjrRadioStat} className="btn-check form-check-input" name="btnradioMjr" id="radio1Mjr"  onClick={handleSetPieMjr} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="radio1Mjr">Pie Chart</label>

                                    <input type="radio" className="btn-check form-check-input" name="btnradioMjr" id="radio2Mjr" onClick={handleSetDonutMjr} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="radio2Mjr">Donut Chart</label>


                                </div>
                            </div>
                            {mjrPie === 1 ?
                                <Chart onSeriesClick={plotareaclick}
                                    //  seriesColors={chartDefaultV4Colors} 
                                    style={{ height: "400px" }}>
                                    {/* <ChartTitle text="Major Asset Chart" /> */}
                                    <ChartLegend position="bottom" />
                                    <ChartValueAxis>
                                        <ChartValueAxisItem
                                            // title={{
                                            //     text: "Percentage",
                                            // }}
                                            min={0}
                                            labels={{
                                                visible: true,

                                                // rotation: 85,
                                                format: "d",
                                                content: FormatLongNumber

                                            }}
                                        />
                                    </ChartValueAxis>
                                    {/* <ChartTooltip  /> */}
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type="pie"
                                            // overlay={{
                                            //     gradient: "sharpBevel",
                                            //   }}
                                            data={data}
                                            field="mv"
                                            categoryField="mjrAstType"
                                            colorField='chrtColorId'
                                            autoFit={true}
                                            labels={{
                                                visible: true,
                                                format: "d",
                                                content: labelContent1,
                                            }}
                                        >
                                            {(
                                                <ChartSeriesLabels
                                                    position="outsideEnd"
                                                    background="none"
                                                    content={labelContent1}
                                                />
                                            )}
                                        </ChartSeriesItem>
                                    </ChartSeries>
                                </Chart>
                                :
                                <Chart onSeriesClick={plotareaclick}
                                    //  seriesColors={chartDefaultV4Colors} 
                                    style={{ height: "400px" }}>
                                    {/* <ChartTitle text="Major Asset Chart" /> */}
                                    <ChartLegend position="bottom" />
                                    <ChartValueAxis>
                                        <ChartValueAxisItem
                                            // title={{
                                            //     text: "Percentage",
                                            // }}
                                            min={0}
                                            labels={{
                                                visible: true,

                                                // rotation: 85,
                                                format: "d",
                                                content: FormatLongNumber

                                            }}
                                        />
                                    </ChartValueAxis>
                                    {/* <ChartTooltip  /> */}
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type="donut"
                                            // overlay={{
                                            //     gradient: "sharpBevel",
                                            //   }}
                                            data={data}
                                            field="mv"
                                            categoryField="mjrAstType"
                                            colorField='chrtColorId'
                                            autoFit={true}
                                            labels={{
                                                visible: true,
                                                format: "d",
                                                content: labelContent1,
                                            }}
                                        />
                                    </ChartSeries>
                                </Chart>

                            }

                        </div>

                    </div>

                </div>

                <div className='col-sm-10 col-md-6 col-lg-6 my-1'>

                    <div className='card rounded h-100'>

                        <div className='card-header tableheader'>Net Flows &nbsp;&nbsp;&nbsp;&nbsp;  From:&nbsp;{subtractYears(1)}&nbsp;&nbsp; To:&nbsp;{localStorage.getItem("processingDate")}</div>
                        <div className='card-body'>

                            <div className='row py-2 mx-1 px-1'>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Start Market
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].startMarket.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Receipt
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].receipt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Cash Receipt
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].cshRcpt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Asset Receipt
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].astRcpt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Disbursement
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].disbursement.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Cash Disbursement
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].cshDsb.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Asset Disbursement
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].astDsb.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Fees
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].fees.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Income
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].income.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Realized G/L
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].rlGainLoss.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            Unrealized G/L
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].ulGainLoss.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-6 py-2'>
                                    <div className='card rounded'>
                                        <div className='card-header'>
                                            End Market
                                        </div>
                                        <div className='card-body'>
                                            ${performAllData == undefined ? 0 : performAllData[0].endMarket.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>


            </div>

            {/* minorStart */}

            <div className="row my-2 mx-1">
                <div className="col-md-12 card-header tableheader">Minor Asset Type
                    {selMjrAsset !== '' ? <>- {selMjrAsset}</> : <></>}
                </div>

                <div className="col col-md-6 col-sm-10 py-2">
                    <div className="card rounded h-100">

                        <div className="">

                            <Grid style={{ height: "560px" }}
                                // data={data}
                                data={orderBy(updatedMnrDataNew, sort)}
                                onRowClick={onRowClick}
                                resizable={true}
                                reorderable={true}
                                // groupable={{
                                //   footer: "visible",
                                // }}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                            // pageSize={pageSize}
                            //  total={total}
                            //filterable={true}
                            //  onDataStateChange={onDataStateChange}
                            // {...dataState}

                            //  cellRender={cellRender}
                            >

                                <Column field="mnrAstType" menu={true} title="Category" width="auto"
                                     />

                                <Column field="mv" title="Market Value($)" cell={NumberCell}
                                    headerClassName='rightHeader'
                                    width="auto" format="{0:n2}" filter="numeric" filterable={false}
                                   // columnMenu={ColumnMenu}
                                // footerCell={TotalPaymentCell}
                                />
                                <Column field="mvPercent" menu={true} cell={NumberCell}
                                    headerClassName='rightHeader'
                                    title="Market Value(%)" format="{0:n2}" width="150px" 
                                   // columnMenu={ColumnMenu} 
                                   />

                            </Grid>

                        </div>
                    </div>
                </div>

                <div className="col col-md-6 col-sm-10 py-2">
                    <div className="card rounded h-100">
                        <div className="">

                            <div className="form-check mt-1 k-text-center py-2 mb-2">
                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" defaultChecked={mnrRadioStat} className="btn-check form-check-input" name="btnradioMnr" id="radio1Mnr" onClick={handleSetBarMnr} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="radio1Mnr">Column Chart</label>

                                    <input type="radio" className="btn-check form-check-input" name="btnradioMnr" id="radio2Mnr" onClick={handleSetArea} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="radio2Mnr">Bar Chart</label>

                                    {/* <input type="radio" className="btn-check form-check-input" name="btnradioMnr" id="radio3Mnr" onClick={handleSetLine} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="radio3Mnr">Line Chart</label> */}

                                </div>
                            </div>

                            {

                                mnrBar === 1
                                    ?

                                    <Chart onSeriesClick={plotareaclick}
                                        seriesColors={chartDefaultV4Colors} style={{ height: "500px" }}>
                                        {/* <ChartTitle text="Major Asset Chart" /> */}
                                        <ChartLegend position="bottom" />

                                        <ChartValueAxis>
                                            <ChartValueAxisItem
                                                // title={{
                                                //     text: "Percentage",
                                                // }}
                                                min={0}
                                                labels={{
                                                    visible: true,

                                                    // rotation: 85,
                                                    //format: "d",
                                                    content: FormatLongNumber

                                                }}
                                            />
                                        </ChartValueAxis>
                                        <ChartCategoryAxis>
                                            <ChartCategoryAxisItem
                                                labels={{
                                                    visible: true,
                                                    rotation: 85,
                                                    format: "d",
                                                }}
                                            //  categories={categoryAxis} 
                                            />
                                        </ChartCategoryAxis>
                                        <ChartTooltip render={defaultTooltipRender} />
                                        <ChartSeries>
                                            <ChartSeriesItem
                                                type="column"
                                                data={updatedMnrDataNew}
                                                field="mv"
                                                categoryField="mnrAstType"

                                                labels={{
                                                    visible: false,
                                                    content: labelContent1,
                                                }}
                                            />
                                        </ChartSeries>
                                    </Chart>
                                    : mnrArea === 1 ?
                                        <Chart onSeriesClick={plotareaclick}
                                            seriesColors={chartDefaultV4Colors} style={{ height: "500px" }}>
                                            {/* <ChartTitle text="Major Asset Chart" /> */}
                                            <ChartLegend position="bottom" />
                                            <ChartValueAxis>
                                                <ChartValueAxisItem
                                                    // title={{
                                                    //     text: "Percentage",
                                                    // }}
                                                    min={0}
                                                    labels={{
                                                        visible: true,

                                                        // rotation: 85,
                                                        //format: "d",
                                                        content: FormatLongNumber

                                                    }}
                                                />
                                            </ChartValueAxis>
                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem
                                                    labels={{
                                                        visible: true,
                                                        rotation: 30,
                                                        format: "d",
                                                    }}
                                                //  categories={categoryAxis} 
                                                />
                                            </ChartCategoryAxis>
                                            <ChartTooltip render={defaultTooltipRender} />
                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="bar"
                                                    data={updatedMnrDataNew}
                                                    field="mv"
                                                    categoryField="mnrAstType"

                                                    labels={{
                                                        visible: false,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>
                                        :
                                        <Chart onSeriesClick={plotareaclick}
                                            seriesColors={chartDefaultV4Colors} style={{ height: "500px" }}>
                                            {/* <ChartTitle text="Major Asset Chart" /> */}
                                            <ChartLegend position="bottom" />
                                            <ChartValueAxis>
                                                <ChartValueAxisItem
                                                    // title={{
                                                    //     text: "Percentage",
                                                    // }}
                                                    min={0}
                                                    labels={{
                                                        visible: true,

                                                        // rotation: 85,
                                                        //format: "d",
                                                        content: FormatLongNumber

                                                    }}
                                                />
                                            </ChartValueAxis>
                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem
                                                    labels={{
                                                        visible: true,
                                                        rotation: 85,
                                                        format: "d",
                                                    }}
                                                //  categories={categoryAxis} 
                                                />
                                            </ChartCategoryAxis>
                                            <ChartTooltip render={defaultTooltipRender} />
                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="line"
                                                    data={updatedMnrDataNew}
                                                    field="mv"
                                                    categoryField="mnrAstType"

                                                    labels={{
                                                        visible: false,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* minorEnd */}

            {/* asset start */}
            <div className="row my-2">
                <div className="col col-md-12 col-sm-12 py-2 mb-3">
                    <div className="card-header tableheader mb-2">Asset(s)
                        {selMnrAsset !== '' ? <> for {selMnrAsset}</> : <></>}
                    </div>
                    <div className="rounded">

                        <div className="w-100 mx-1 px-1">
                            <Grid style={{ height: "300px" }}
                                //data={astData}
                                data={orderBy(updatedAssetData, sortAsset)}
                                //filterable={true}
                                resizable={true}
                                reorderable={true}
                                sortable={true}
                                sort={sortAsset}
                                onSortChange={(e) => {
                                    setAssetSort(e.sort);
                                }}
                            // onDataStateChange={onDataStateChange}
                            //{...dataState}
                            // sortable={true}
                            // sort={sort}
                            // onSortChange={(e) => {
                            //   setSort(e.sort);
                            // }}
                            // groupable={{
                            //   footer: "visible",
                            // }}
                            // sortable={true}
                            // pageSize={pageSize}
                            //  total={total}
                            //filterable={true}
                            //  onDataStateChange={onDataStateChange}
                            // {...dataState}

                            //  cellRender={cellRender}
                            >

                                <Column field="symbol" title="Symbol" width="auto"  />

                                <Column field="astShrtNm" title="Short Name" width="auto" 

                                // footerCell={TotalPaymentCell}
                                />
                                <Column field="mv" title="Market Value($)" cell={NumberCell}
                                    //headerCell={RightNameHeader} 
                                    width="auto" format="{0:n2}" filter="numeric" headerClassName='rightHeader'
                                     />
                                <Column field="mvPercent" title="Market Value(%)" headerClassName='rightHeader' cell={NumberCell}
                                    //headerCell={RightNameHeaer} 
                                    format="{0:n2}" width="auto" filter={"numeric"}  />

                                {/* <Column cell={MyEditCommandCell} /> */}

                            </Grid>
                        </div>

                    </div>
                </div>
            </div>

            {/* asset end */}
        </div>
    )
}

export default MjrMnrAssetDetails


