import React from 'react'
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
  } from "@progress/kendo-react-charts";
  import { useState, useEffect, useRef } from 'react';
  import Enumerable from 'linq';
  import {ResponsiveContainer} from 'recharts';
  import {astVsModelData} from './cstmRptMain';
const PortfoloiAllocatiionWidget = () => {

  const assetVsColorModel = astVsModelData.ocAssetColorModel;
 
  const assetVsModelFinalData = Enumerable.from(astVsModelData.t1)
    .join(
      assetVsColorModel,
      pk => pk.groupId,
      fk => fk.mjrAstTypeId,
      (left, right) => ({ ...left, ...right })
    )
    .toArray();
    //setAlldata(assetVsModelFinalData);
  const [allData, setAlldata] = useState(Enumerable.from(assetVsModelFinalData).where(w => w.prtfolio >= 0)
    .toArray());



    const labelContent1 = (props) => {

   
        let formatedNumber = Number(props.dataItem.prtfolioWeigh).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.prtfolioWeigh.toFixed(2)}%`;
    };
  return (<><h5 className='text-fitek p-1'>Portfolio Allocation



  </h5>      
 
    <ResponsiveContainer className='page-break'>
      {allData.length===0?<div>No Data Found.</div>:
      <Chart style={{ }}>
        {/* <ChartTitle text="Major Asset Chart" /> */}
        <ChartLegend position="bottom" />

        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            data={ Enumerable.from(allData).where(w => w.prtfolio > 0)
              .toArray()}
            colorField='chartColorCOde'
            field="prtfolio"
            categoryField="descption"
            autoFit={true}
            labels={{
              visible: true,
              content: labelContent1,
            }}
          />
        </ChartSeries>
      </Chart>

          }
    </ResponsiveContainer> </>
  )
}

export default PortfoloiAllocatiionWidget
