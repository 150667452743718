import React from 'react'
import { useState, useEffect } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from 'react-bootstrap/Modal';
import Loading from './loading';
import Enumerable from 'linq';
import { orderBy } from "@progress/kendo-data-query";
import BankLogoPage from './bankLogoPage';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
const AccountProfileDetails = ({ data, acct, allmodelData, selModelId, dispAssetClass }) => {
    debugger;
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [sortOrder, setSortOrder] = React.useState([
        {
            field: "groupId",
            dir: "asc",
        },
    ]);

    const [topHoldingsData, setTopHoldingsData] = useState(data[0].topHold.ocTopHolding);
    const [sort, setSort] = React.useState([]);
    const [loadingChild, setLoadingChild] = useState(true);
    const [invMixVal, setInvMixVal] = useState(selModelId === -1 ? true : false);
    debugger;
    const initialModelDropdown = {
        modelId: selModelId,
        modelNm: allmodelData.length > 0 ? (allmodelData.find((ele) => { return ele.modelId === selModelId })).modelNm : ""
    };
    const [selChangeModel, setSelChangeModel] = React.useState([]);

    const assetVsColorModel = data[0].accountProf.ocAcctProfColorModel;

    const assetVsModelFinalData = orderBy(Enumerable.from(data[0].accountProf.ocAcctProfile)
        .join(
            assetVsColorModel,
            pk => pk.groupName,
            fk => fk.mjrAstName,
            (left, right) => ({ ...left, ...right })
        )
        .toArray(), sortOrder);



    const [allData, setAlldata] = useState(Enumerable.from(assetVsModelFinalData).where(w => w.market > 0)
        .toArray());


    const accountVsColorModel = data[0].assetAllocVsMod.ocAssetColorModel;


    const accountVsModelFinalData = orderBy(Enumerable.from(data[0].assetAllocVsMod.ocAssetAllocVsModel)
        .join(
            accountVsColorModel,
            pk => pk.groupId,
            fk => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
        )
        .toArray(), sortOrder);


    const [assetAllocModelRptData, setAssetAllocModelRptData] = useState(Enumerable.from(accountVsModelFinalData).where(w => w.mdl > 0)
        .toArray());

    const initialDataState = {};
    const initialDataStatePortfolio = {};
    const initialDataStateTopHolding = {};

    let _pdfExport;
    const [dataState, setDataState] = React.useState();
    const [resultState, setResultState] = React.useState(
        process(allData, initialDataState)
    );
    const [dataStatePortfolio, setDataStatePortfolio] = React.useState();
    const [resultStatePortfolio, setResultStatePortfolio] = React.useState(
        process(assetAllocModelRptData, initialDataStatePortfolio)
    );

    const [dataStateTopHolding, setDataStateTopHolding] = React.useState();
    const [resultStateTopHolding, setResultStateTopHolding] = React.useState(
        process(topHoldingsData, initialDataStateTopHolding)
    );
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const _export = React.useRef(null);
    const showpreview = async () => {
        setShow(true);
    }
    const excelExport = () => {


        if (_export.current !== null) {
            _export.current.save(allData);
        }
    };

    let allPDFExport;
    const printPDF = async () => {

        if (allPDFExport !== null) {
            allPDFExport.save();
        }
    };

    const PageTemplate = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "1px",

                        width: "98%",
                        borderBottom: "1px solid #bce8f1"

                    }}
                >
                    <div className='row d-flex mx-3'>
                        <div className='col text-start'>
                            <a className='px-2'  ><BankLogoPage /></a>

                        </div>
                        <div className='col text-end px-5 py-2'>
                            <h2 className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'  >Account Profile Report </h2>

                        </div>


                    </div>




                    {/* <div className='fw-bold  text-center'>
                        Account Profile</div> */}
                </div>

                <div
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        //  borderTop: "1px solid #bce8f1"
                    }}
                >
                    Page {props.pageNum} of {props.totalPages}
                </div>
            </div>
        );
    };
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }

    //const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent = (e) => `${formatNumber(e.value, "##,#.00")}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${formatNumber(props.dataItem.marketPercent, "##,#.00")}%`;
    };

    const labelContentAccVMdl = (props) => {

        let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${formatNumber(props.dataItem.mdlWegh, "##,#.00")}%`;
    };

    const defaultTooltipRender = ({ point }) => `${formatNumber(point.value, "##,#.00")}%`;

    const totalSum = (props) => {
        const field = props.field || "";
        const total = allData.reduce((acc, current) => acc + current[field], 0);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, "##,#.00")}
            </td>
        );
    };
    const totalYield = (props) => {
        debugger;
        const field = props.field || "";
        const totalIncome = allData.reduce((acc, current) => acc + current["income"], 0);
        const totalMarket = allData.reduce((acc, current) => acc + current["market"], 0);
        const totalYeild = (totalIncome / totalMarket) * 100;
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(totalYeild, "##,#.00")}
            </td>
        );
    };
    const totalAcVMdl = (props) => {
        const field = props.field || "";
        const total = assetAllocModelRptData.reduce((acc, current) => acc + current[field], 0);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, "##,#.00")}
            </td>
        );
    };


    useEffect(() => {
        // Good!
        setSelChangeModel(initialModelDropdown);
        localStorage.setItem('ModelNm', initialModelDropdown.modelNm);
        setLoadingChild(false);
        localStorage.setItem('StatusInvMix', 'false');
        if (selModelId === -1) {
            localStorage.setItem('StatusInvMix', 'true');
            getChangeInfo(selChangeModel.modelId);
        }

        // setChartTypeLabel(labelContent1);
        // Side-effect!
    }, []);

    const handleChangeAllocModel = (e) => {
        setSelChangeModel(e.target.value);
        // e.preventDefault();
        localStorage.setItem('StatusInvMixProf', 'false');

        localStorage.setItem('ModelNm', e.target.value.modelNm);

        getChangeInfo(e.target.value.modelId)

    }

    const handleInvMix = (e) => {

        setInvMixVal(e.target.checked)
        localStorage.setItem('StatusInvMixProf', e.target.checked);
        getChangeInfo(selChangeModel.modelId);
    }

    const getChangeInfo = async (pModelId) => {

        setLoadingChild(true);
        let token = tempToken;
        let UserId = JSON.parse(localStorage.getItem('userId'));

        let Accounts = "<root> <Account AcctId='" + acct.acctId + "'/> </root>"
        let lsSelectedAcct = acct;
        let AcctId = 0;
        let NumOfRows = 10;
        let modelId = pModelId;//JSON.parse(localStorage.getItem("modelId"));
        let statInvMix = JSON.parse(localStorage.getItem('StatusInvMixProf'));

        let invMix = 0;
        if (statInvMix)
            invMix = 1;

        if (lsSelectedAcct !== null) {
            AcctId = lsSelectedAcct.acctId;
        }
        const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/AccountProfile',
            postData,
            config
        )
            .then(response => {
                debugger;
                //  console.log(response);

                const rowData = response.data[0];

                const assetVsColorModel = rowData.accountProf.ocAcctProfColorModel;

                const assetVsModelFinalData = orderBy(Enumerable.from(rowData.accountProf.ocAcctProfile)
                    .join(
                        assetVsColorModel,
                        pk => pk.groupName,
                        fk => fk.mjrAstName,
                        (left, right) => ({ ...left, ...right })
                    )
                    .toArray(), sortOrder);

                const accountVsColorModel = rowData.assetAllocVsMod.ocAssetColorModel;


                const accountVsModelFinalData = orderBy(Enumerable.from(rowData.assetAllocVsMod.ocAssetAllocVsModel)
                    .join(
                        accountVsColorModel,
                        pk => pk.groupId,
                        fk => fk.mjrAstTypeId,
                        (left, right) => ({ ...left, ...right })
                    )
                    .toArray(), sortOrder);


                // setAssetAllocModelRptData(rowData);

                if (lsSelectedAcct !== null) {
                    debugger;
                    if (JSON.parse(localStorage.getItem('StatusInvMixProf'))) {
                        setAlldata(orderBy(Enumerable.from(rowData.accountProf.ocAcctProfile).where(w => w.market > 0)
                            .toArray(), sortOrder));
                        setAssetAllocModelRptData(orderBy(Enumerable.from(rowData.assetAllocVsMod.ocAssetAllocVsModel).where(w => w.mdl > 0)
                            .toArray(), sortOrder));
                    }

                    else {
                        setAlldata(orderBy(Enumerable.from(assetVsModelFinalData).where(w => w.market > 0)
                            .toArray(), sortOrder));
                        setAssetAllocModelRptData(orderBy(Enumerable.from(accountVsModelFinalData).where(w => w.mdl > 0)
                            .toArray(), sortOrder));

                    }

                    setResultState(rowData.accountProf.ocAcctProfile);






                    setTopHoldingsData(rowData.topHold.ocTopHolding);

                }
                else {
                    //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
                }
                setLoadingChild(false);
                // setFlag(1);
            })
            .catch((error) => {

                if (error.response.status === 401) {
                    refreshToken();


                }
                return error;
            });

    }

    const onDataStateChange = React.useCallback((e) => {

        setDataState(e.dataState);
        // let gridData = JSON.parse(localStorage.getItem('gridData'));
        // const groups = e.dataState.group;

        // if (groups) {
        //   groups.map((group) => (group.aggregates = aggregates));
        // }
        // e.dataState.group = groups;
        setResultState(process(allData, e.dataState));
    }, []);

    const onDataStateChangePortfolio = React.useCallback((e) => {

        setDataStatePortfolio(e.dataState);

        setResultStatePortfolio(process(assetAllocModelRptData, e.dataState));
    }, []);

    const onDataStateChangeTopHolding = React.useCallback((e) => {

        setDataStateTopHolding(e.dataState);

        setResultStateTopHolding(process(topHoldingsData, e.dataState));
    }, []);

    const menuWithExcelCheck = (props) => {
        debugger;
        return (
            <div>

                <ColumnMenuCheckboxFilter
                    {...props}

                    data={allData}

                />
            </div>)
    };

    const menuWithExcelCheckPortSum = (props) => {
        debugger;
        return (
            <div>

                <ColumnMenuCheckboxFilter
                    {...props}

                    data={assetAllocModelRptData}

                />
            </div>)
    };

    const menuWithExcelCheckTopHolding = (props) => {
        debugger;
        return (
            <div>

                <ColumnMenuCheckboxFilter
                    {...props}

                    data={topHoldingsData}

                />
            </div>)
    };

    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {
                debugger;
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));

                getChangeInfo(selChangeModel.modelId);


            })
            .catch((error) => {
                // debugger;
                console.log("my error is " + error);
            })

    }


    const grid = (
        <Grid style={{ height: "auto" }}
            data={resultState}
            //resizable={true}
            //reorderable={true}
            sortable={true}

            onDataStateChange={onDataStateChange}
            {...dataState}

        >

            <Column field="groupName" menu={true} title="Asset Class" width="400px" columnMenu={menuWithExcelCheck} />
            <Column field="txCstAmt" menu={true} title="Total Cost($)" width="auto" footerCell={totalSum} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
            <Column field="market" menu={true} title="Market Value($)" width="auto" footerCell={totalSum} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

            <Column field="income" menu={true} title="Income($)" width="auto" footerCell={totalSum} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

            <Column field="yield" menu={true} title="Yield(%)" width="auto" footerCell={totalYield} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
            <Column field="marketPercent" title="Market Value(%)" width="auto" footerCell={totalSum} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

        </Grid>
    );
    if (loadingChild)
        return <Loading />
    else
        return (
            <div>
                <div className="row">

                    <div className="col col-md-12 col-sm-12 py-1">
                        <div className="card-header row d-flex justify-content-between my-2 tableheader">
                            <div className="col">
                                <div className="mx-1">Account Summary</div>

                            </div>

                            <div className="col text-end">
                                Due to rounding, percentage may not equal 100.


                            </div>
                            <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                                    <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                                </DropdownButton>


                            </div>


                        </div>

                        <ExcelExport data={allData} ref={_export}>

                            <ExcelExportColumnGroup
                                title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
                                headerCellOptions={{
                                    textAlign: "left",
                                }}
                            >
                                <ExcelExportColumn field="groupName" title="Asset Class" width={170} />
                                <ExcelExportColumn field="txCstAmt" title="Total Cost($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="market" title="Market Value($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="income" title="Income($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="yield" title="Yield(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="marketPercent" title="Market Value(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />

                            </ExcelExportColumnGroup>
                        </ExcelExport>
                        {grid}


                        <div className="row mx-1 my-2 pb-3 mb-5">

                            <div className="col col-md-6 col-sm-10 py-2">
                                <div className="col-md-12 card-header tableheader">Account Holdings  </div>
                                <div className="card rounded h-100">

                                    <div className="w-100">
                                        <Chart style={{ height: "440px" }}>
                                            {/* <ChartTitle text="Major Asset Chart" /> */}
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={allData}
                                                    colorField='chartColorCOde'
                                                    field="marketPercent"
                                                    categoryField="groupName"
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>

                                    </div>
                                </div>
                            </div>

                            <div className="col col-md-6 col-sm-10 py-2">
                                <div className="col-md-12 card-header tableheader">Model Allocation

                                </div>

                                <div className="card rounded h-100">

                                    <Chart style={{ height: "440px" }}>
                                        {/* <ChartTitle text="Major Asset Chart" /> */}
                                        <ChartLegend position="bottom" />

                                        <ChartSeries>
                                            <ChartSeriesItem
                                                type="pie"
                                                data={assetAllocModelRptData}
                                                field="mdlWegh"
                                                categoryField="descption"
                                                colorField='chartColorCOde'
                                                autoFit={true}
                                                labels={{
                                                    visible: true,
                                                    content: labelContentAccVMdl,
                                                }}
                                            />
                                        </ChartSeries>
                                    </Chart>
                                    <hr></hr>

                                    <div className='row my-1'>
                                        <div className='col text-left mx-2 my-1'>
                                            <DropDownList
                                                style={{
                                                    width: "320px",
                                                }}
                                                data={allmodelData}
                                                textField="modelNm"
                                                valueField="modelId"
                                                dataItemKey="modelId"
                                                filterable={false}
                                                disabled={invMixVal}
                                                //defaultItem={initialModelDropdown}
                                                value={selChangeModel}
                                                onChange={handleChangeAllocModel}

                                            />
                                        </div>
                                    </div>
                                    <div className='row my-1 mx-2'>
                                        <div className='form-check' >


                                            <input className='form-check-input' type='checkbox' name='chkInvTrgMix' checked={invMixVal} onChange={handleInvMix}  ></input>
                                            <label className='form-check-label'>Compare against Investment Target Mix </label>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="row mx-1 my-2">
                            <div className="col-md-12 card-header tableheader">Portfolio vs Model </div>
                            <div className="col col-md-12 col-sm-12 py-2">
                                <div className="card rounded">

                                    <div className="w-100">
                                        <Chart
                                            // seriesColors={chartDefaultV4Colors}
                                            zoomable={false}
                                        >
                                            <ChartLegend position="bottom" />
                                            <ChartValueAxis>
                                                <ChartValueAxisItem
                                                    // title={{
                                                    //     text: "Percentage",
                                                    // }}
                                                    min={0}
                                                    max={120}

                                                />
                                            </ChartValueAxis>
                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem
                                                    labels={{
                                                        visible: true,
                                                        rotation: "auto",
                                                        format: "d",

                                                    }}

                                                //  categories={categoryAxis} 
                                                />
                                            </ChartCategoryAxis>
                                            <ChartSeriesDefaults
                                                type="column"
                                                labels={{
                                                    visible: true,
                                                    format: "n2",
                                                }}
                                            />

                                            {/* <ChartValueAxis>
                    <ChartValueAxisItem crosshair={crosshair} />
                </ChartValueAxis> */}
                                            <ChartTooltip render={defaultTooltipRender} />

                                            <ChartSeries>

                                                <ChartSeriesItem
                                                    data={assetAllocModelRptData}
                                                    // colorField='chartColorCOde'
                                                    type='column'
                                                    field='prtfolioWeigh'
                                                    categoryField='descption'
                                                    name='Portfolio'
                                                    // aggregate='sum'
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent,
                                                    }}

                                                />

                                                <ChartSeriesItem
                                                    data={assetAllocModelRptData}
                                                    //colorField='chartColorCOde'
                                                    type='column'
                                                    field='mdlWegh'
                                                    name='Model'
                                                    categoryField='descption'
                                                    // aggregate='sum'
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent,
                                                    }}

                                                />

                                            </ChartSeries>

                                        </Chart>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mx-1 my-2">
                            <div className="col-md-12 card-header tableheader">Portfolio vs Model Summary </div>
                            <div className="col col-md-12 col-sm-12 py-2">
                                <div className="card rounded">

                                    <div className="w-100">

                                        <Grid style={{ height: "auto" }}
                                            data={resultStatePortfolio}
                                            // resizable={true}
                                            // reorderable={true}
                                            sortable={true}
                                            onDataStateChange={onDataStateChangePortfolio}
                                            {...dataStatePortfolio}
                                        //sortable={true}
                                        // sort={sort}
                                        // onSortChange={(e) => {
                                        //     setSort(e.sort);
                                        // }}
                                        >

                                            <Column field="descption" menu={true} title="Description" columnMenu={menuWithExcelCheckPortSum} width="250px" />
                                            <Column field="prtfolio" menu={true} title="Portfolio($)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                            <Column field="prtfolioWeigh" menu={true} title="Portfolio(%)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

                                            <Column field="mdl" menu={true} title="Model($)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

                                            <Column field="mdlWegh" menu={true} title="Model(%)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                            <Column field="varitoMdl_Amt" menu={true} title="Variance($)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                            <Column field="varitoMdl" menu={true} title="Variance(%)" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                        </Grid>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mx-1 my-2 pb-3 mb-3">
                            <div className="col-md-12 card-header tableheader">Top Holdings </div>
                            <div className="col col-md-12 col-sm-12 py-2">
                                <div className="card rounded">

                                    <div className="w-100">

                                        <Grid style={{ height: "auto" }}
                                            data={resultStateTopHolding}
                                            // resizable={true}
                                            // reorderable={true}
                                            sortable={true}

                                            onDataStateChange={onDataStateChangeTopHolding}
                                            {...dataStateTopHolding}
                                        >

                                            <Column field="tickerCusipConcate" menu={true} title="Ticker/Cusip : Asset" width="450px" columnMenu={menuWithExcelCheckTopHolding} />
                                            <Column field="shares" menu={true} title="Shares" width="auto" columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                            <Column field="txcstAmt" menu={true} title="Total Cost($)" width="auto" columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

                                            <Column field="market" menu={true} title="Market Value($)" width="auto" columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

                                            <Column field="marketPercent" menu={true} title="Percentage(%)" width="auto" columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />

                                        </Grid>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <GridPDFExport
              ref={(element) => {
                _pdfExport = element;
              }}
              margin={{ top: 40, left: 30, right: 30, bottom: 40 }}
              pageTemplate={PageTemplate}
              forcePageBreak=".page-break"
              paperSize="A4"
              scale={0.5}
              allPages={true}
              //margin="1cm"
              landscape={true}
              repeatHeaders={true}
            >
              {grid}
            </GridPDFExport> */}
                    </div>
                </div>


                <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
                    <Modal.Header><div className="row w-100 tableheader">
                        <div className='col'>
                            <Modal.Title>Account Profile Report - Pdf Preview</Modal.Title>
                        </div>
                        <div className='col text-end'>
                            <button
                                className="btn btn-outline-primary btn-sm mx-2"
                                onClick={printPDF}
                            >
                                Print
                            </button>
                            <button className="btn btn-outline-danger  btn-sm" onClick={handleClose}>
                                Close


                            </button>
                        </div>


                    </div>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFExport
                            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
                            pageTemplate={PageTemplate}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            scale={0.5}
                            allPages={true}

                            landscape={true}
                            repeatHeaders={true}

                            ref={(pdfExport) => (allPDFExport = pdfExport)}>

                            Account Name : {acct.extrnlAcctId}
                            <hr></hr>
                            {grid}

                            <div className="row mx-1 my-2 pb-3 mb-5">

                                <div className="col col-md-6 col-sm-10 py-2">
                                    <div className="col-md-12 card-header tableheader">Account Holdings  </div>
                                    <div className="card rounded h-100">

                                        <div className="w-100">
                                            <Chart style={{ height: "440px" }}>
                                                {/* <ChartTitle text="Major Asset Chart" /> */}
                                                <ChartLegend position="bottom" />

                                                <ChartSeries>
                                                    <ChartSeriesItem
                                                        type="pie"
                                                        data={allData}
                                                        colorField='chartColorCOde'
                                                        field="marketPercent"
                                                        categoryField="groupName"
                                                        autoFit={true}
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent1,
                                                        }}
                                                    />
                                                </ChartSeries>
                                            </Chart>

                                        </div>
                                    </div>
                                </div>

                                <div className="col col-md-6 col-sm-10 py-2">
                                    <div className="col-md-12 card-header tableheader"> Model Allocation : {JSON.parse(localStorage.getItem('StatusInvMix')) ? "" : initialModelDropdown.modelNm}

                                    </div>

                                    <div className="card rounded h-100">

                                        <Chart style={{ height: "440px" }}>
                                            {/* <ChartTitle text="Major Asset Chart" /> */}
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={assetAllocModelRptData}
                                                    field="mdlWegh"
                                                    categoryField="descption"
                                                    colorField='chartColorCOde'
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContentAccVMdl,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>




                                    </div>

                                </div>

                            </div>

                            <span className='page-break'></span>
                            <div className="row mx-1 my-2">
                                <div className="col-md-12 card-header tableheader">Portfolio vs Model </div>
                                <div className="col col-md-12 col-sm-12 py-2">
                                    <div className="card rounded">

                                        <div className="w-100">
                                            <Chart
                                                // seriesColors={chartDefaultV4Colors}
                                                zoomable={false}
                                            >
                                                <ChartLegend position="bottom" />
                                                <ChartValueAxis>
                                                    <ChartValueAxisItem
                                                        // title={{
                                                        //     text: "Percentage",
                                                        // }}
                                                        min={0}
                                                        max={100}
                                                    />
                                                </ChartValueAxis>
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem
                                                        labels={{
                                                            visible: true,
                                                            rotation: "auto",
                                                            format: "d",

                                                        }}

                                                    //  categories={categoryAxis} 
                                                    />
                                                </ChartCategoryAxis>
                                                <ChartSeriesDefaults
                                                    type="column"
                                                    labels={{
                                                        visible: true,
                                                        format: "n2",
                                                    }}
                                                />

                                                {/* <ChartValueAxis>
                    <ChartValueAxisItem crosshair={crosshair} />
                </ChartValueAxis> */}
                                                <ChartTooltip render={defaultTooltipRender} />

                                                <ChartSeries>

                                                    <ChartSeriesItem
                                                        data={assetAllocModelRptData}
                                                        // colorField='chartColorCOde'
                                                        type='column'
                                                        field='prtfolioWeigh'
                                                        categoryField='descption'
                                                        name='Portfolio'
                                                        // aggregate='sum'
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}

                                                    />

                                                    <ChartSeriesItem
                                                        data={assetAllocModelRptData}
                                                        //colorField='chartColorCOde'
                                                        type='column'
                                                        field='mdlWegh'
                                                        name='Model'
                                                        categoryField='descption'
                                                        // aggregate='sum'
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}

                                                    />

                                                </ChartSeries>

                                            </Chart>


                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row mx-1 my-2">
                                <div className="col-md-12 card-header tableheader">Portfolio vs Model Summary </div>
                                <div className="col col-md-12 col-sm-12 py-2">
                                    <div className="card rounded">

                                        <div className="w-100">

                                            <Grid style={{ height: "auto" }}
                                                data={resultStatePortfolio}
                                                // resizable={true}
                                                // reorderable={true}
                                                sortable={true}
                                                onDataStateChange={onDataStateChangePortfolio}
                                                {...dataStatePortfolio}
                                            //sortable={true}
                                            // sort={sort}
                                            // onSortChange={(e) => {
                                            //     setSort(e.sort);
                                            // }}
                                            >

                                                <Column field="descption" menu={true} title="Description" width="250px" />
                                                <Column field="prtfolio" menu={true} title="Portfolio($)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />
                                                <Column field="prtfolioWeigh" menu={true} title="Portfolio(%)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />

                                                <Column field="mdl" menu={true} title="Model($)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />

                                                <Column field="mdlWegh" menu={true} title="Model(%)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />
                                                <Column field="varitoMdl_Amt" menu={true} title="Variance($)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />
                                                <Column field="varitoMdl" menu={true} title="Variance(%)" width="auto" footerCell={totalAcVMdl} cell={NumberCell} headerClassName='rightHeader' />
                                            </Grid>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <span className='page-break'></span>
                            <div className="row mx-1 my-2 pb-3 mb-3">
                                <div className="col-md-12 card-header tableheader">Top Holdings </div>
                                <div className="col col-md-12 col-sm-12 py-2">
                                    <div className="card rounded">

                                        <div className="w-100">

                                            <Grid style={{ height: "auto" }}
                                                data={resultStateTopHolding}
                                                //resizable={true}
                                                //reorderable={true}
                                                sortable={true}

                                                onDataStateChange={onDataStateChangeTopHolding}
                                                {...dataStateTopHolding}
                                            >

                                                <Column field="tickerCusipConcate" menu={true} title="Ticker/Cusip : Asset" width="450px" />
                                                <Column field="shares" menu={true} title="Shares" width="auto" cell={NumberCell} headerClassName='rightHeader' />
                                                <Column field="txcstAmt" menu={true} title="Total Cost($)" width="auto" cell={NumberCell} headerClassName='rightHeader' />

                                                <Column field="market" menu={true} title="Market Value($)" width="auto" cell={NumberCell} headerClassName='rightHeader' />

                                                <Column field="marketPercent" menu={true} title="Percentage(%)" width="auto" cell={NumberCell} headerClassName='rightHeader' />

                                            </Grid>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </PDFExport>

                    </Modal.Body>
                    <Modal.Footer>



                        <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                            Close
                        </button>


                    </Modal.Footer>

                </Modal>

            </div>
        )
}

export default AccountProfileDetails
