import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import Header  from './header';

import FixdIncmFndmntlsGrid from './fixdIncmFndmntlsGrid';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
  } from "@progress/kendo-react-dropdowns";
//import "@progress/kendo-theme-material/dist/all.css";
import Enumerable from 'linq';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const FixdIncmFndmntlsRpt = () => {
    const [FixedIncmFundmntlRptData, populateFixedIncmFundmntlRptData] = useState([]);
    const [AcctList, populateAccountListData] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [loading, setLoading] = useState(true);
    const [isColumnSave, setIsColumnSave] = useState(false);
    const[IsChkboxChecked, setChkBoxVal]=useState(JSON.parse(localStorage.getItem('chkChkd')))
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const navigate = useNavigate();
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
            try {
                //let data = location.state;
    
                let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
               
                //setEmail(email);
                GetFixdIncmFndmntlsReportData();
                getColumnStateDb();
                //  console.log(data);
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, [])
    const getColumnStateDb = async () =>{
      //Storing column settings in DB
      debugger;
        let token = JSON.parse(localStorage.getItem('token'));
        let UserId = JSON.parse(localStorage.getItem('userId'));
        if(IsChkboxChecked===null)
              setChkBoxVal(false);

        let GridId = (IsChkboxChecked ? 3 : 2);//let 2 for Fixed Income Fundamental Grid
        
        const postData = {UserId, GridId};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      
        };
        await axios.post('/RTGetGridColumn/Index',
          postData,
          config
        )
          .then(response => {
            debugger;
            console.log('save in db');
            console.log(response);
              const rowData = response.data;
              if(rowData!==""){
                setIsColumnSave(true);
                console.log('true');
                (IsChkboxChecked ?
                  localStorage.setItem('gridColumnsChkd', rowData)            
                :
                localStorage.setItem('gridColumns', rowData))
              }
              else{
                setIsColumnSave(false);
                console.log('false');
              }
          })
          .catch((error) => {
            console.log('error in save db '+error);
              return error;
          });
      }
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          debugger;
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetFixdIncmFndmntlsReportData();
  
  
        })
        .catch((error) => {
          // debugger;
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }
    const GetFixdIncmFndmntlsReportData = async () => {
        //debugger;
        setLoading(true);
      
        // let token = JSON.parse(localStorage.getItem('token'));
        let token=tempToken;
         let userId = JSON.parse(localStorage.getItem('userId'));
         let lsSelectedAcct=JSON.parse(localStorage.getItem("AcctSelected"));
         let acctIds = 0;
         if(lsSelectedAcct!== null)
         {
            acctIds=lsSelectedAcct.acctId;
         }
         
         let pageId = 1;
         const postData = {userId, acctIds, pageId};
         const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          
      };
        await axios.post('/RTFixdIncmFndmntl',
            postData,
           config
        )
            .then(response => {
              
                //  console.log(response);
      //debugger;
                const rowData = response.data;
                // rtFixedIncm = (rtFixedIncm.fIF1).Where(x => x.MtrtyYr != 0).ToList();
                rowData.fIF1=Enumerable.from(rowData.fIF1).where(w => w.mtrtyYr !== 0).toArray();
                // populateFixedIncmFundmntlRptData(rowData.fIF1)
                populateFixedIncmFundmntlRptData(rowData.fIF1);
                populateAccountListData(rowData.fIF2)
                setSelAcctData(rowData.fIF2);
                if(lsSelectedAcct!== null)
                {
                    SetselAcct(lsSelectedAcct);
                }
                else
                {
                    SetselAcct(rowData.fIF2[0]);
                }
                localStorage.setItem("FIInitialData",JSON.stringify(rowData.fIF1));
                setLoading(false);
      
            })
            .catch((error) => {
              if (error.response.status === 401) {
                refreshToken();
      
      
              }
      
               // return error;
            });
      
      }
      
      const handleChange = (event) => {
//debugger;
        if (event.target.value === null) {
          //SetselAcct('');
          SetselAcct(selAcct);
         // GetAcctProfileData();
          // GetUpdatedAccountProfile(0);
        }
        else {
          SetselAcct(event.target.value);
          localStorage.setItem("AcctSelected",JSON.stringify(event.target.value));
          GetFixdIncmFndmntlsReportData();
          //GetUpdatedAccountProfile(event.target.value.acctId);
        }
    
    
    
      };
      const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // debugger;
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
    
    
            // }
    
           
          })
          .catch((error) => {
            // debugger;
            console.log("my error is " + error);
          })
    
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }
    
    //   const filterData = (filter) => {
       
    //    // const dataAcct = selAcctData.slice();
    //     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
    //   };
    
    //   const filterChange = (event) => {
        
    //     setSelAcctData(filterData(event.filter));
    //   };
      if (loading) {
        return(
            <>
            {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
              <Header></Header>
            <Loading />
            </div>}</>
          )
      }
  return (
    <div>
       <Header></Header>
       <div  className='my-1'>
      {/* <div className="rounded"></div> */}
       <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
       <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            // onFilterChange={filterChange}
          />
          </div><div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          </div>
          </div>
        <FixdIncmFndmntlsGrid  data={FixedIncmFundmntlRptData} flag={isColumnSave} chkChkdFlg={IsChkboxChecked}/>
    </div>
  )
}

export default FixdIncmFndmntlsRpt


