import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {portfolioBrkdwn3rdDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';



const PortfolioBreakdown3rdGridWidget = () => {
  debugger;
  const [data, setbrkdwnData] = useState(portfolioBrkdwn3rdDatavar);//change Data!!!!!!!!!!!
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const createDataState = (dataState) => {
    return {
      result: process(data.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
  });
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [sort, setSort] = React.useState([]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}
const NumberCellSixDecimal = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.000000")}
        </td>
    )
}

const totalAcVMdl = (props) => {
  const field = props.field || "";
  const total = data.reduce((acc, current) => acc + current[field], 0);
  return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, "##,#.00")}
      </td>
  );
};
let defaultColumns = [
  {
    title: 'Asset Class',
    field: 'groupHeader',
    minWidth: "auto",
    show: true,
    filter: 'text',
    locked: false,
    //headerClassName:"rightHeader",
  },
  {
    title: '% Portfolio Weighting',
    field: 'porfolioPercentData',
    minWidth: "auto",
    show: true,
    filter: 'numeric',
    locked: false,
    headerClassName:"rightHeader",
  },
  {
    title: '% Model Weighting',
    field: 'modelPercentData',
    minWidth: "auto",
    show: true,
    filter: 'numeric',
    locked: false,
    headerClassName:"rightHeader",
  },
  {
    title: '% Benchmark Weighting',
    field: 'benchMarkPercentData',
    minWidth: "auto",
    show: true,
    filter: 'numeric',
    locked: false,
    headerClassName:"rightHeader",
  },
  {
      title: '% Variance to Model',
      field: 'modelVarience',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:"rightHeader",
    },
    {
      title: '% Variance to Benchmark',
      field: 'benchmarkVarience',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:"rightHeader",
    },
  
];
const getCells = (columns, cellProps) => {
  let cells = [];
  columns.forEach((column) => {
    if (column.aggregate) {
      cells.push(
        <td style={{ textAlign: "right" }}>
          {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
        </td>
      );
    } else {
      cells.push(<td>&nbsp;</td>);
    }
  });
  return cells;
};
const totalSum = (props) => {
  const field = props.field || '';
  const total = data
    .reduce((acc, current) => acc + current[field], 0)
    .toFixed(2);
  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {formatNumber(total, '##,#.00')}
    </td>
  );
};
const GridColumns = defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;
GridColumns[1].footerCell= totalSum;
GridColumns[2].footerCell= totalSum;
GridColumns[3].footerCell= totalSum;
const [stateColumns, setStateColumns] = useState(GridColumns);
const onColumnReorder = (props) => {
  setStateColumns(addHiddenColumns(props.columns));
};

const onColumnResize = (props) => {
  setStateColumns(addHiddenColumns(props.columns));
};

const addHiddenColumns = (columns) => {
  let newColumnsState = defaultColumns.map((col) => {
    let _col = columns.filter((c) => c.field == col.field);
    if (_col.length > 0) {
      return {
        ...col,
        orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
        width: _col[0].width ? _col[0].width : '',
      };
    } else {
      return { ...col, show: false };
    }
  });
  return newColumnsState;
};
const onColumnsSubmit = (columnsState) => {
  setStateColumns(columnsState);
};
const expandChange = (event) => {
  const isExpanded =
    event.dataItem.expanded === undefined
      ? event.dataItem.aggregates
      : event.dataItem.expanded;
  event.dataItem.expanded = !isExpanded;
  setResult({ ...result });
};
const cellRender = (tdElement, cellProps) => {
  if (
    cellProps.rowType === 'groupHeader' &&
    tdElement &&
    tdElement.props.role != 'presentation'
  ) {
    //IMPORTANT - You need to add collection with the columns and their field name
    //you can define the Grid columns outside of the Grid and reuse them here.
    // const columns = [
    //   { field: 'invstmntObj' },
    //   { field: 'accountType' },
    //   { field: 'account'},
    //   { field: 'tckerCusip'},
    //   { field: 'assetShrtNm'},
    //   { field: 'units'},
    //   { field: 'txcstAmt'},
    //   { field: 'price' },
    //   { field: 'totMarket', aggregate: 'sum' },
    //   { field: 'gainLoss' },
    //   { field: 'income' },
    //   { field: 'yield' },
    //   { field: 'p1CashBlncAmt' },
    //   { field: 'p2P3CashBlncAmt' },
    //   { field: 'unExecCashAmt' },
    //   { field: 'tradeCash'},
    //   { field: 'excldCashAmt'},
    //   { field: 'equityPercent'},
    // ];
    
    const columns = GridColumns;
    return (
      <>
        <td
          {...tdElement.props}
          colSpan={tdElement.props.colSpan - columns.length}
        ></td>
        {getCells(columns, cellProps)}
      </>
    );
  }
  if (cellProps.rowType === 'groupFooter') {

    if (cellProps.field === 'totMarket') {
      return (
        <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
          {cellProps.dataItem.aggregates.totMarket.sum}
        </td>
      );
    }

  }
  if (cellProps.rowType === "data") {

    if (cellProps.field === "porfolioPercentData") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "modelPercentData") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "benchMarkPercentData") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "modelVarience") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "benchmarkVarience") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "income") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "yield") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "p1CashBlncAmt") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "p2P3CashBlncAmt") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "unExecCashAmt") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "tradeCash") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "excldCashAmt") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
    if (cellProps.field === "equityPercent") {

      return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
        </td>
      );
    }
  }
  return tdElement;
};



  return (
   
    <div><h5 className='text-fitek p-1'>Equity Vs Model Vs Benchmark</h5>
      <Grid
      id='tileGrid'
              style={{ width: '100%', height: "auto"}}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              //onExpandChange={expandChange}
              cellRender={cellRender}
              //sortable={true}
              //scrollable={true}
              resizable={true}
              className='page-break'
              reorderable={true}
              //pageable={true}
              //pageSize={10}
              // groupable={{
              //   footer: 'visible',
              // }}
              //
              //ref={gridRef.current}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      {...column}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenuNoGrp
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
                                    
     </div>
  )
}





export default PortfolioBreakdown3rdGridWidget