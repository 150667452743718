import React from 'react'
import { useState, useEffect, useMemo, useRef } from 'react';
import { FaCogs, FaFileExcel, FaFilePdf, FaFilePowerpoint, FaPrint, FaSave, FaSyncAlt } from 'react-icons/fa';
import { GrRefresh } from 'react-icons/gr';
import { Switch } from "@progress/kendo-react-inputs";
import Loading from './loading';
import Header from './header';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import BankLogoPage from './bankLogoPage';
import TestAcct from './testAcct';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import AcctHoldingWidget from './acctHoldingWidget';
import { AiOutlineClose } from 'react-icons/ai';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import Enumerable from 'linq';
import axios from 'axios';
import { GridPDFExport, PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { ResponsiveContainer } from 'recharts';
import RGL, { WidthProvider } from "react-grid-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import { Resizable, ResizableBox } from 'react-resizable';
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import "react-grid-layout/css/styles.css";
//import "react-resizable/css/styles.css";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import data from './FifRpt.json'
import Widget from "./Widget";
import GridLayout from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import TopBar from './Topbar';
import { withSize } from "react-sizeme";

import TimeWtdRtnRptChartWidget from './TimeWtdRtnRptChartWidget';
import TimeWtdRtnRptGridWidget from './TimeWtdRtnRptGridWidget';
import SctrReturnPerfrmnceDataWidget from './sctrReturnPerfrmnceDataWidget';
import SctrReturnPerfrmnceChartWidget from './sctrReturnPerfrmnceChartWidget';

import TopHoldingsWidget from './topHoldingsWidget';
import AssetvsModelDataWidget from './assetvsModelDataWidget';
import AllocationmodelWidget from './allocationmodelWidget';
import PortfolioHoldingWidget from './portfolioHoldingWidget';
import AssetVsModelChartWidget from "./assetVsModelChartWidget";
import TextfieldWiget from './textfieldWiget';
import TextBoxConclusion from './textBoxConclusion';
import CoverPageWiget from './coverPageWiget';
import TableContentWiget from './tableContentWiget';
import CashflowWidget from './cashflowWidget';
import AstByMrktCapitalWidget from './astByMrktCapitalWidget';
import AcctHoldingRptWidget from './acctHoldingRptWidget';
import FixdIncmFundmntlsGrid from './fixdIncmFndmntlsWidgetGrid';
import AcctperfRORWidget from './acctperfRORWidget';
import AcctperfSummaryWidget from './acctperfSummaryWidget';
import AcctperfChartWidget from './acctperfChartWidget'
import { elementAcceptingRef } from '@mui/utils';
//import { Card } from 'react-bootstrap';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import AcctTransactionGridWidget from './acctTransactionGridWidget';
import SectorComparisionGridWidget from './sectorComparisionGridWidget';
import SectorComparisionChartWidget from './sectorComparisionChartWidget';
import PortPerfSecurityGridWidget from './portPerfsecurityGridWidget';
import PortfolioBreakdown1stGridWidget from './portfolioBreakdown1stGridWidget';
import PortfolioBreakdown2ndGridWidget from './portfolioBreakdown2ndGridWidget';
import PortfolioBreakdown3rdGridWidget from './portfolioBreakdown3rdGridWidget';
import PortfolioSnpshotGridWidget from './portfolioSnpshotGridWidget';
import PortfolioSnpshotCurrentChartWidget from './portfolioSnpshotCurrentChartWidget';
import PortfolioSnpshot2ndChartWidget from './portfolioSnpshot2ndChartWidget';
import PortfolioSnpshotBarChartWidget from './portfolioSnpshotBarChartWidget';
import AssetAllocVsModelGridWidget from './assetAllocVsModelGridWidget';
import PrchsSaleWidget from './prchsSaleWidget';
import PortfoloiAllocatiionWidget from './portfoloiAllocatiionWidget';
import ModelAllocationWidget from './modelAllocationWidget';
import PortfolioVsModelWidget from './portfolioVsModelWidget';
import AccountProfileSmryWidget from './accountProfileSmryWidget';

import FimMaturityDetails from './fimMtrtyDtlWidget'
import FimCallPutDetails from './fimCallPutDetailsWidget'
import FimMtrDtVsClPutdtl from './fimMtrDtVsClPutdtlChrtWidget';
import FipoBondQultySctr from './fipoBondQultySctrWidget';
import FipoMtrtyLadder from './fipoMtrtyLadderWidget';
import FipoMnrIndusSec from './fipoMnrIndusSecChartWidget';
import FipoMjrIndusSec from './fipoMjrIndusSecChartWidget';


import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

var portfoliholdingsDatavar;
var acctProfFinalDatavar;
var allocmodelDatavar;
var assetVsModelFinalDatavar;
var topHoldingDatavar;
var cashflowDatavar;
var astByMrktCapitalDatavar;
var accountHoldingDatavar;
var fixdincmfndmntlsDatavar;
var perfRORDatavar;
var perfMjrAsstSummaryDatavar;
var perfChartDatavar;
var TimeWtdRtnRptChartDatavar;
var TimeWtdRtnRptGridDatavar;
var sctrReturnPerfrmnceDatavar;
var sctrReturnPerfrmnceChartDatavar;

var transactionDatavar;
var sectorCmprsnDatavar;
var portPerfBySecurityDatavar;
var portfolioBrkdwn1stDatavar;
var portfolioBrkdwn2ndDatavar;
var portfolioBrkdwn3rdDatavar;
var snapshotCmprsnDatavar;
var snapshotCmprsncolorDatavar;
var purchaseAndSaleData;
var astVsModelData;
var acctPrflSmryDatavar;
var fimMaturityDeatils;
var fimMaturityLadrCallDetails;
var fimMatrtyDtVsCallPutDt;
var fipoBondQultySctr;
var fipoMaurityLadder;
var fipoBondByMinorCls;
var fipoBondByMjrIndstry;

const CustomReportMain = (props) => {
  const ResponsiveReactGridLayout = WidthProvider(RGL);
  const pdfExportComponent = React.useRef(null);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  console.log("selacct",selAcct);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [usememocheck, setuseMemocheck] = useState(false);
  const [acctProfileRptData, setAcctProfileRptData] = useState([]);
  const [acctPortVsModelData, setAcctPortVsModelData] = useState([]);
  const [topHoldingData, setTopHoldingData] = useState([]);
  const [portfoliHoldingsData, setPortfoliHoldingsData] = useState([]);
  const [cashflowData, setcashflowData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [session,setSession]=useState("");
  const navigate = useNavigate();
  localStorage.setItem("modelId", localStorage.getItem("modelId") === null ? -1 : localStorage.getItem("modelId"));
  //custom report data fetch
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;

        GetCustomReportData();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, []);
  
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetCustomReportData();

      })
      .catch((error) => {
        // debugger;
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }
  const refreshTokenWidget = async () => {
    debugger;
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;

    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetWidgetSettings();

      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

  }
  const GetCustomReportData = async (roleId, cntctId, acctId) => {
    // setLoading(true);
    debugger;
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    //SetselAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
    let modelId = JSON.parse(localStorage.getItem("modelId"))//-1;
    let invMix = 0;
    let NumOfRows = 10;
    let PageId = 1;
    let TemplateId = JSON.parse(localStorage.getItem('tmpltIdFrmDshBrd'));
    const postData = { UserId, AcctId, TemplateId };//{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/Template',
      postData,
      config
    )
      .then(response => {
        debugger;
        //  console.log(response);

        const rowData = response.data;
        const acctProfData = rowData[0].accountProf === null ? [] : rowData[0].accountProf.ocAcctProfile;
        const acctProfColorData = rowData[0].accountProf === null ? [] : rowData[0].accountProf.ocAcctProfColorModel;
const transactionData=rowData[0].objLstAcctTransaction===null?[]:rowData[0].objLstAcctTransaction.ocAcctTransaction;
for(var i=0;i<transactionData.length;i++){
  transactionData[i].prcsDt=formatDate(new Date(transactionData[i].prcsDt),"d")
}
transactionDatavar=transactionData;
const projectedCashFlowData=rowData[0].objProjectedCashFlow===null?[]:rowData[0].objProjectedCashFlow.t1;
//const assetAllocVsModelData=rowData[0].objAssetsMarketCapital===null?[]:rowData[0].objAssetsMarketCapital.t1;
const portPerfBySecurityData=rowData[0].objRTPortPerfBySecurity===null?[]:rowData[0].objRTPortPerfBySecurity.t1;
portfolioBrkdwn1stDatavar=rowData[0].objRTPortfolioBreakDown===null?[]:rowData[0].objRTPortfolioBreakDown.t4;
portfolioBrkdwn2ndDatavar=rowData[0].objRTPortfolioBreakDown===null?[]:rowData[0].objRTPortfolioBreakDown.t5;
portfolioBrkdwn3rdDatavar=rowData[0].objRTPortfolioBreakDown===null?[]:rowData[0].objRTPortfolioBreakDown.t6;

debugger;
sectorCmprsnDatavar=rowData[0].objAcctSectOutput===null?[]:rowData[0].objAcctSectOutput.ocAcctSectT2;
snapshotCmprsnDatavar=rowData[0].objRTPortfolioSnapShotComparison===null?[]:rowData[0].objRTPortfolioSnapShotComparison.t1;
snapshotCmprsncolorDatavar=rowData[0].objRTPortfolioSnapShotComparison===null?[]:rowData[0].objRTPortfolioSnapShotComparison.t4;
portPerfBySecurityDatavar=rowData[0].objRTPortPerfBySecurity===null?[]:rowData[0].objRTPortPerfBySecurity.t1;

sctrReturnPerfrmnceDatavar = rowData[0].objOcSectorReturnPerformanceOutput === null ? [] : rowData[0].objOcSectorReturnPerformanceOutput.sRP1;
        sctrReturnPerfrmnceChartDatavar = rowData[0].objOcSectorReturnPerformanceOutput === null ? [] : rowData[0].objOcSectorReturnPerformanceOutput.sRP3;

        setcashflowData(rowData[0].objProjectedCashFlow === null ? [] : rowData[0].objProjectedCashFlow.t1);
        cashflowDatavar = rowData[0].objProjectedCashFlow === null ? [] : rowData[0].objProjectedCashFlow.t1;
        astByMrktCapitalDatavar = rowData[0].objAssetsMarketCapital === null ? [] : rowData[0].objAssetsMarketCapital.t1;
        const astmarketCapitalData = rowData[0].objAssetsMarketCapital === null ? [] : rowData[0].objAssetsMarketCapital.t1;

        accountHoldingDatavar = rowData[0].objAcctHolding === null ? [] : rowData[0].objAcctHolding.ocAcctHolding;

        const acctProfFinalData = Enumerable.from(acctProfData)
          .join(
            acctProfColorData,
            pk => pk.groupId,
            fk => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
          )
          .toArray();
        const assetVsModelData = rowData[0].assetAllocVsMod === null ? [] : rowData[0].assetAllocVsMod.ocAssetAllocVsModel;
        const assetVsColorModel = rowData[0].assetAllocVsMod === null ? [] : rowData[0].assetAllocVsMod.ocAssetColorModel;

        const assetVsModelFinalData = Enumerable.from(assetVsModelData)
          .join(
            assetVsColorModel,
            pk => pk.groupId,
            fk => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
          )
          .toArray();
        //find
        rowData[0].finalWidgetPositionSet.pos.sort((a, b) => (a.order > b.order) ? 1 : -1);
        for(var i=0;i<rowData[0].finalWidgetPositionSet.pos.length;i++){
          rowData[0].finalWidgetPositionSet.pos[i].order=i;
        }
        setPositions(rowData[0].finalWidgetPositionSet.pos);
        //setPositions(JSON.parse(localStorage.getItem("dashboard-positions")));
        setWidgets(reviverFunc(rowData[0].finalWidgetPositionSet.widgetlist,rowData[0].finalWidgetPositionSet.pos));
        setuseMemocheck(true);
        const topHoldingsData = rowData[0].topHold === null ? [] : rowData[0].topHold.ocTopHolding;
        const portfoliholdingsData = rowData[0].portFolioHoldings === null ? [] : rowData[0].portFolioHoldings.ocPortFolioHoldingsMainOutPut;
        portfoliholdingsDatavar = portfoliholdingsData;
        debugger;
        setAcctProfileRptData(acctProfFinalData);
        acctProfFinalDatavar = acctProfFinalData
        //localStorage.setItem("acctProfFinalData",JSON.stringify(acctProfFinalData));
        allocmodelDatavar = assetVsModelFinalData;
        //localStorage.setItem("allocmodelData",JSON.stringify(assetVsModelFinalData));
        setAcctPortVsModelData(assetVsModelFinalData);
        
        fixdincmfndmntlsDatavar = rowData[0].objOcFixedIncomeFundamentalsModelOutput === null ? [] : Enumerable.from(rowData[0].objOcFixedIncomeFundamentalsModelOutput.fIF1).where(w => w.mtrtyYr !== 0).toArray();
        perfRORDatavar = rowData[0].objAccountPerformanceSummary === null ? [] : rowData[0].objAccountPerformanceSummary.lstAccountPerformanceSummaryROR;
        perfMjrAsstSummaryDatavar = rowData[0].objAccountPerformanceSummary === null ? [] : rowData[0].objAccountPerformanceSummary.lstAccountPerformanceSummaryActivity;
        perfChartDatavar = rowData[0].objAccountPerformanceSummary === null ? [] : rowData[0].objAccountPerformanceSummary.lstAccountPerformanceSummaryAllocation;
        setTopHoldingData(topHoldingsData);
        setPortfoliHoldingsData(portfoliholdingsData);
        assetVsModelFinalDatavar = assetVsModelFinalData;
        //localStorage.setItem("assetVsModelFinalData", JSON.stringify(assetVsModelFinalData));
        topHoldingDatavar = topHoldingsData;
        purchaseAndSaleData=rowData[0].objPurchaseAndSaleModel===null?[]:rowData[0].objPurchaseAndSaleModel.t1;
        astVsModelData=rowData[0].objRTAssetAllocationVsModel===null?[]:rowData[0].objRTAssetAllocationVsModel;

        TimeWtdRtnRptChartDatavar = rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput === null ? [] : rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput.lstRTTimeWtdReturnT1;
        TimeWtdRtnRptGridDatavar = rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput === null ? [] : rowData[0].objRTTimeWtdReturnOfSelectedPeriodsOutput.lstRTTimeWtdReturnT1;
debugger;
        fimMaturityDeatils = rowData[0].objFixedIncomeMaturityModelOutput === null ? [] : rowData[0].objFixedIncomeMaturityModelOutput.fIM1;
        fimMaturityLadrCallDetails = rowData[0].objFixedIncomeMaturityModelOutput === null ? [] : rowData[0].objFixedIncomeMaturityModelOutput.fIM2;
        fimMatrtyDtVsCallPutDt = rowData[0].objFixedIncomeMaturityModelOutput === null ? [] : rowData[0].objFixedIncomeMaturityModelOutput.fIM3;

        fipoBondQultySctr = rowData[0].objFixedIncomePortfolioOverviewOutput === null ? [] : rowData[0].objFixedIncomePortfolioOverviewOutput.lstFixedIncomePortfolioOverviewT2;
        fipoMaurityLadder = rowData[0].objFixedIncomePortfolioOverviewOutput === null ? [] : rowData[0].objFixedIncomePortfolioOverviewOutput.lstFixedIncomePortfolioOverviewT3;
        fipoBondByMinorCls = rowData[0].objFixedIncomePortfolioOverviewOutput === null ? [] : rowData[0].objFixedIncomePortfolioOverviewOutput.lstFixedIncomePortfolioOverviewT4;
        fipoBondByMjrIndstry = rowData[0].objFixedIncomePortfolioOverviewOutput === null ? [] : rowData[0].objFixedIncomePortfolioOverviewOutput.lstFixedIncomePortfolioOverviewT5;

        //localStorage.setItem("topHoldingData", JSON.stringify(topHoldingsData));
        //localStorage.setItem("portfoliHoldingData", JSON.stringify(portfoliholdingsData));
        localStorage.setItem("txtTableOfContent", rowData[0].finalWidgetPositionSet.templateTextBoxList[1].textBoxVal);
        localStorage.setItem("txtIntro", rowData[0].finalWidgetPositionSet.templateTextBoxList[2].textBoxVal);
        localStorage.setItem("txtConclusion", rowData[0].finalWidgetPositionSet.templateTextBoxList[3].textBoxVal);
        localStorage.setItem("txtCoverPage", rowData[0].finalWidgetPositionSet.templateTextBoxList[0].textBoxVal);
        
        acctPrflSmryDatavar = rowData[0].accountProf === null ? [] : rowData[0].accountProf.ocAcctProfile;
        
        setFlag(true);
        setLoading(false);

      })
      .catch((error) => {
        debugger;
        console.log(error);
        if (error.response.status === 401) {
          refreshToken();

        }
      });

  }
  
  
  const showpreview = async () => {
    reviverFunc(widgets,positions,1);
      setShow(true);
      for(var i=0;i<widgets.length;i++){
        widgets[i].reorderable=false;
        widgets[i].resizable=false;
        
      }
  }

  const handleClose = () => {
    reviverFunc(widgets,positions,0);
    setShow(false);
    for(var i=0;i<widgets.length;i++){
      widgets[i].reorderable=true;
      widgets[i].resizable=true;
    }
  }
  const handleShow = () => setShow(true);
  const container = useRef(null);

//   const exportPDFWithMethod = () => {
// debugger;
//     // document.getElementById("exitbutton").style.display = "none";
//     let element = container.current || document.body;

//     savePDF(element, {
//       paperSize: "auto",
//       margin: 40,
//       fileName: `Report for ${new Date().getFullYear()}`,
//     });

//     //   document.getElementById("exitbutton").style.display = "inline";
//   };
  const handleChange = (event) => {
    //debugger;
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));

      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const [savingCheck, setSavingCheck] = useState(0);
  const initialPositions = [
    {
      widgetId: 1,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 0
    },
    {
      widgetId: 2,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 1
    },
    {
      widgetId: 3,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 2
    },
    {
      widgetId: 4,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 3
    },
    {
      widgetId: 5,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 4
    },
    {
      widgetId: 6,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 5
    },
    {
      widgetId: 7,
      col: 1,
      colSpan: 3,
      rowSpan: 1,
      order: 6
    },
    {
      widgetId: 8,
      col: 4,
      colSpan: 3,
      rowSpan: 1,
      order: 7
    },
    {
      widgetId: 9,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 8
    },
    {
      widgetId: 10,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 9
    },
    {
      widgetId: 11,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 10
    },
    {
      widgetId: 12,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 11
    },
    {
      widgetId: 13,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 12
    },
    {
      widgetId: 14,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 13
    },
    {
      widgetId: 15,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 15
    },
    {
      widgetId: 16,
      col: 1,
      colSpan: 6,
      rowSpan: 36,
      order: 14
    },
    {
      widgetId: 17,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 16
    },
    {
      widgetId: 18,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 17
    },
    {
      widgetId: 19,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 18
    },
    {
      widgetId: 20,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 19
    },
    {
      widgetId: 21,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 22
    },
    {
      widgetId: 22,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 20
    },
    {
      widgetId: 23,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 21
    },
    {
      widgetId: 24,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 23
    },
    {
      widgetId: 25,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 25
    },
    {
      widgetId: 26,
      col: 4,
      colSpan: 3,
      rowSpan: 1,
      order: 24
    },
    {
      widgetId: 27,
      col: 4,
      colSpan: 3,
      rowSpan: 9,
      order: 27
    },
    {
      widgetId: 28,
      col: 1,
      colSpan: 3,
      rowSpan: 7,
      order: 26
    },
    {
      widgetId: 29,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 28
    },
    {
      widgetId: 30,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 29
    },
    {
      widgetId: 31,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 32
    },
    {
      widgetId: 32,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 30
    },
    {
      widgetId: 33,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 31
    },
    {
      widgetId: 34,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 32
    },
    {
      widgetId: 35,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 34
    },
    {
      widgetId: 36,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 36
    },
    {
      widgetId: 37,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 35
    },
    {
      widgetId: 38,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 37
    },
    {
      widgetId: 39,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 38
    },
    {
      widgetId: 40,
      col: 1,
      colSpan: 3,
      rowSpan: 2,
      order: 39
    },
    {
      widgetId: 41,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 40
    },
    {
      widgetId: 42,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      order: 41
    },
    {
      widgetId: 43,
      col: 1,
      colSpan: 3,
      rowSpan: 4,
      order: 42
    },
    {
      widgetId: 44,
      col: 4,
      colSpan: 3,
      rowSpan: 2,
      order: 44
    },
    {
      widgetId: 45,
      col: 1,
      colSpan: 6,
      rowSpan: 1,
      order: 43
    }
    
  ];

  const getPositions = (initialPositions) => {
    // Try to get positions from local storage
    // If we have none in the storage then default to initial positions
    return (
      JSON.parse(localStorage.getItem("dashboard-positions")) || initialPositions
    );
  };
  // const getWidgets = (widgetsConfig) => {
  //   var temp=JSON.parse(localStorage.getItem("dashboard-widget"));
  //   var temp1=reviverFunc(temp);
  //   // Try to get positions from local storage
  //   // If we have none in the storage then default to initial positions
  //   return (
  //      temp1 || widgetsConfig
  //   );
  // };

  const widgetsConfig = [

    {
      id: 1,
      //header: "Cover Page",
      body: <CoverPageWiget />,
      active: true

    },

    {
      id: 2,
      //header: "Table Of Content",
      body: <TableContentWiget />,
      active: true

    },
    {
      id: 3,
      //header: "Introduction",
      body: <TextfieldWiget />,
      active: true
    },

    {
      id: 4,
      //header: "Account Holding",
      body: <AcctHoldingWidget />,
      active: true
    },
    {
      id: 5,
      //header: "Allocation Model",
      body: <AllocationmodelWidget />,
      active: true
    },
    {
      id: 6,
      //header: "Asset vs Model chart",
      body: <AssetVsModelChartWidget />,
      active: true
    },
    {
      id: 7,
      //header: "Asset vs Model data",
      body: <AssetvsModelDataWidget />,
      active: true
    },
    {
      id: 8,
      //header: "Portfolio Holdings",
      body: <PortfolioHoldingWidget />,
      active: true
    },

    {
      id: 9,
      //header: "Top Holdings",
      body: <TopHoldingsWidget />,
      active: true
    },

    {
      id: 10,
      //header: "Conclusion",
      body: <TextBoxConclusion />,
      active: true
    },

  ];
  const replacerFunc = (widgetsvar) => {
    debugger;
    if (widgetsvar) {
      var i = 0;
      for (i = 0; i < widgetsvar.length; i++) {

        widgetsvar[i].body = "";
      }
      return widgetsvar;
    }
    else { return null; }
  };
  const handleLock=()=>{
    for(var i=0;i<widgets.length;i++){
      widgets[i].reorderable=false;
      widgets[i].resizable=false;
    }
  }
  const PageTemplate = (props) => {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: "1px",
                    width:"98%",
                    borderBottom: "1px solid #bce8f1"
                   
                }}
            >
               
               <div className='row d-flex'>
                  <div className='col text-start'>
                  <a className='mx-1'  ><BankLogoPage /></a>

                  </div>
                  <div className='col text-end mx-3 py-1'>
                  {/* <h4 className='fw-bold text-fitek'  >{localStorage.getItem("tmpltNm")}</h4> */}

                  </div>
                  </div>

                  <div className='row d-flex bg-fitek w-100 justify-content-around px-2 align-items-center py-1'>

                    
                    <div className='col px-2'><h6 className='fw-bold text-white'  >Account : {selAcct.extrnlAcctId}</h6></div>
                    <div className='col px-2'><h6 className='fw-bold text-white'  >Date Created : 01/10//2023</h6></div>

                    <div className='col px-2'>
                    <h6 className='fw-bold text-white'  >Owner : {localStorage.getItem('owner')}</h6></div>
                    

                   </div>
                
               
            </div>
                   
            <div
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  //  borderTop: "1px solid #bce8f1"
                }}
            >
                Page {props.pageNum} of {props.totalPages}
            </div>
        </div>
    );
};

  const reviverFunc = (widgets,positions, param=0) => {

      if (widgets) {
        var i = 0;
        var j = 0;

        for (i = 0; i < widgets.length; i++) {
          if (widgets[i].id === 1) {
            debugger;
            widgets[i].body = <CoverPageWiget param={param} />
          }
           if (widgets[i].id === 2) { 
            //widgets[i].header=<><Button onClick={handleLock}>lock</Button>  Table Of Content</>; 
          widgets[i].body = <p><TableContentWiget param={param} positions={positions} widgets={widgets}/><span className='page-break'></span></p> }
          if (widgets[i].id === 3) { widgets[i].body = <p><TextfieldWiget param={param}/><span className='page-break'></span></p> }
          if (widgets[i].id === 4) { 
            debugger;
            widgets[i].body = <p><AccountProfileSmryWidget /> <span className='page-break'></span></p>
          }
          if (widgets[i].id === 5) { widgets[i].body = <p><AcctHoldingWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 6) { widgets[i].body = <p><AllocationmodelWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 7) { widgets[i].body = <p><AssetVsModelChartWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 8) { widgets[i].body = <p><AssetvsModelDataWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 9) {
            widgets[i].body = <p><TopHoldingsWidget /><span className='page-break'></span></p>
          }
          if (widgets[i].id === 10) { widgets[i].body = <p><PortfolioHoldingWidget /><span className='page-break'></span></p>  }
          if (widgets[i].id === 45) { 
            widgets[i].body = <TextBoxConclusion param={param}/>
          
         }
          if (widgets[i].id === 12) { widgets[i].body = <p><AcctHoldingRptWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 13) { widgets[i].body = <p><CashflowWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 14) { widgets[i].body = <p><AcctperfRORWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 15) { widgets[i].body = <p><AcctperfSummaryWidget /><span className='page-break'></span></p> }
          if (widgets[i].id === 16) { widgets[i].body = <p><AcctperfChartWidget /><span className='page-break'></span></p> }

          if (widgets[i].id === 17) { widgets[i].body = <p><AstByMrktCapitalWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 11) { widgets[i].body = <p><FixdIncmFundmntlsGrid/><span className='page-break'></span></p>}
          if (widgets[i].id === 18) { widgets[i].body = <p><PrchsSaleWidget/><span className='page-break'></span></p>}
          if (widgets[i].id === 19) { widgets[i].body = <p><AssetAllocVsModelGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 20 ) { widgets[i].body = <p><PortfoloiAllocatiionWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 21 ) { widgets[i].body = <p><ModelAllocationWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 22 ) { widgets[i].body = <p><PortfolioVsModelWidget/><span className='page-break'></span></p> }

          if (widgets[i].id === 23) { widgets[i].body = <p><AcctTransactionGridWidget preview={show}/><span className='page-break'></span></p> }
          if (widgets[i].id === 24) { widgets[i].body = <p><SectorComparisionGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 25) { widgets[i].body = <p><SectorComparisionChartWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 26) { widgets[i].body = <p><PortPerfSecurityGridWidget/><span className='page-break'></span></p> }

          if (widgets[i].id === 27) { widgets[i].body = <p><PortfolioBreakdown1stGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 28) { widgets[i].body = <p><PortfolioBreakdown2ndGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 29) { widgets[i].body = <p><PortfolioBreakdown3rdGridWidget/><span className='page-break'></span></p> }

          if (widgets[i].id === 30) { widgets[i].body = <p><PortfolioSnpshotGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 31) { widgets[i].body = <p><PortfolioSnpshotCurrentChartWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 32) { widgets[i].body = <p><PortfolioSnpshot2ndChartWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 33) { widgets[i].body = <p><PortfolioSnpshotBarChartWidget/><span className='page-break'></span></p> }

          if (widgets[i].id === 34) { widgets[i].body = <p><FimMaturityDetails/><span className='page-break'></span></p> }
          if (widgets[i].id === 35) { widgets[i].body = <p><FimCallPutDetails /><span className='page-break'></span></p> }
          if (widgets[i].id === 36) { widgets[i].body = <p>< FimMtrDtVsClPutdtl/><span className='page-break'></span></p> }

          if (widgets[i].id === 41) { widgets[i].body = <p><FipoBondQultySctr/><span className='page-break'></span></p> }
          if (widgets[i].id === 42) { widgets[i].body = <p><FipoMtrtyLadder/><span className='page-break'></span></p> }
          if (widgets[i].id === 43) { widgets[i].body = <p><FipoMnrIndusSec/><span className='page-break'></span></p> }
          if (widgets[i].id === 44) { widgets[i].body = <p><FipoMjrIndusSec/><span className='page-break'></span></p> }

          if (widgets[i].id === 37) { widgets[i].body = <p><SctrReturnPerfrmnceDataWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 38) { widgets[i].body = <p><SctrReturnPerfrmnceChartWidget/><span className='page-break'></span></p> }
  
          if (widgets[i].id === 39) { widgets[i].body = <p><TimeWtdRtnRptGridWidget/><span className='page-break'></span></p> }
          if (widgets[i].id === 40) { widgets[i].body = <p><TimeWtdRtnRptChartWidget/><span className='page-break'></span></p> }

        }

        debugger;
        return widgets;
      }
      else { return null; }
    

  }

  //const [positions, setPositions] = useState(getPositions(initialPositions));
  const [widgets, setWidgets] = useState();
  const [positions, setPositions] = useState();
  //const [widgets, setWidgets] = useState(widgetsConfig);
  const GetWidgetSettings = async () => {
    debugger;
   // setLoading(true);
    let Widgetlist = replacerFunc(widgets);
    let Pos = positions;
    let token = tempToken;
    let modUserId = JSON.parse(localStorage.getItem('userId'));
    let templateId = JSON.parse(localStorage.getItem('tmpltIdFrmDshBrd'));
    let coverPage = (localStorage.getItem('txtCoverPage')) === null ? "" : (localStorage.getItem('txtCoverPage'));
    let tableOfContent = (localStorage.getItem('txtTableOfContent')) === null ? "" : (localStorage.getItem('txtTableOfContent'));
    let introduction = (localStorage.getItem('txtIntro')) === null ? "" : (localStorage.getItem('txtIntro'));
    let conclusion = (localStorage.getItem('txtConclusion')) === null ? "" : (localStorage.getItem('txtConclusion'));
    const data = { templateId, modUserId, Widgetlist, Pos, coverPage, tableOfContent, introduction, conclusion };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    debugger;
    await axios.post('/WidgetSettings', data, config)
      .then((response) => {
        debugger;
        console.log(response);
        //widgets=reviverFunc();
        reviverFunc(widgets,positions)
        //setLoading(false);

      })
      .catch((error) => {
        debugger;
        console.log("my error is " + error);
        if (error.response.status === 401) {
          debugger;
          refreshTokenWidget();

        }

      })
  }
  
  const handlesave = () => {

    debugger;
    localStorage.setItem("dashboard-positions", JSON.stringify(positions));

    let temp = widgets;
    localStorage.setItem("dashboard-widget", JSON.stringify(replacerFunc(temp)));

    GetWidgetSettings();
    //reviverFunc(widgets,positions);

    // localStorage.setItem("dashboard-widget", JSON.stringify(widgets));
    //console.log(JSON.parse(localStorage.getItem("dashboard-widget")));
  }

  const activeWidgets = useMemo(() => {
    
    if (usememocheck) {
      return widgets.reduce((acc, widget) => {
        // Bail out if widget is not active
        if (!widget.active) return acc;
        // Widget is active, so add it
        acc.push(widget);
        return acc;
      }, []);
    }
  }, [widgets]);

  // const filteredPositions = useMemo(() => {
  //   return positions.filter((position) => {
  //     // Find a matching widget using the id in the position id and return its active value
  //     return activeWidgets.find((widget) => widget.id === position.widgetId)
  //       ?.active;
  //   });
  // }, [activeWidgets, positions]);

  const handleReposition = (e) => {
    debugger;
    setPositions(e.value);
    console.log(positions);
    //reviverFunc(widgets,e.value)
    //positions[9].order=20;
    for (var i = 0; i < widgets.length; i++) {
      if (widgets[i].id === 2) { 
        //widgets[i].header=<><Button onClick={handleLock}>lock</Button>  Table Of Content</>; 
      widgets[i].body = <p><TableContentWiget param={0} positions={e.value} widgets={widgets}/><span className='page-break'></span></p> ;
    break;
    }
     }


  };

  const onResetLayout = () => {
    setWidgets((widgets) =>
      widgets.map((widget) => ({ ...widget, active: true }))
    );
    setPositions(initialPositions);

  };

  const onToggleWidget = (e) => {
    debugger;
   
   
    const { id } = e.target.props;
    const { value } = e.target;
    const updatedWidgets = widgets.map((widget) => {
      if (widget.id === id) {
        return {
          ...widget,
          active: value
        };
      }
      return widget;
    });
    debugger;
    //localStorage.setItem("widgetList",updatedWidgets);
    
    setWidgets(updatedWidgets);
    reviverFunc(updatedWidgets,positions);
    
  };
const [dispOwner,setdispOwner]=useState("block");
const [dispOwnerSwitch,setdispOwnerSwitch]=useState(true);
const [dispInvofficer,setdispInvofficer]=useState("block");
const [dispInvofficerSwitch,setdispInvofficerSwitch]=useState(true);
const [dispAdmin,setdispAdmin]=useState("block");
const [dispAdminSwitch,setdispAdminSwitch]=useState(true);
const toggleDispOwner=()=>{
  debugger;
  console.log(dispOwner);
  if(dispOwner==="block"){
    setdispOwnerSwitch(false);
    setdispOwner("none");
  }
  else{
    setdispOwnerSwitch(true);
    setdispOwner("block");
  }
  
}
const toggleDispInvofficer=()=>{
  debugger;
  if(dispInvofficer==="block"){
    setdispInvofficerSwitch(false);
    setdispInvofficer("none");
  }
  else{
    setdispInvofficerSwitch(true);
    setdispInvofficer("block");
  }
  
}
const toggleDispAdmin=()=>{
  debugger;
  if(dispAdmin==="block"){
    setdispAdminSwitch(false);
    setdispAdmin("none");
  }
  else{
    setdispAdminSwitch(true);
    setdispAdmin("block");
  }
  
}
const exportPDFWithMethod = () => {
  let gridElement = document.getElementById("tile");
  drawDOM(gridElement, {
    paperSize: "A4",
  })
    .then((group) => {
      return exportPDF(group);
    })
    .then((dataUri) => {
      console.log(dataUri.split(";base64,")[1]);
    });
};

const signOut = () => {
    
  navigate("/");
  let token = JSON.parse(localStorage.getItem('token'));
  const postData = {};
  const config = {
    headers: {
      'authorization': `Bearer ${token.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

  };
  axios.post('/token/revoke',
  postData,
  config
  )
    .then((response) => {
    // debugger;
    // localStorage.setItem('token', '');
     //console.log(response);
    // navigate("/");
        
      // if (response.statusText === '') {
       


      // }

     
    })
    .catch((error) => {
      // debugger;
      console.log("my error is " + error);
    })

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem('token');
    localStorage.clear();
  //firebaseApp.auth.signOut();

}
  if (loading) {
    // setLoading(false);
    return (
      <>
      {session==="Session Expired"?
     
     <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
   :
<><Header></Header>
      <Loading /></>
  }</>
      
    )
  }
  return (
    <div>
      <div className='sticky-top shadow-sm'><Header></Header></div>
      

      <div className='row my-1 bg-light shadow-sm'>
        {/* <div className="rounded"></div> */}
        <div className='d-flex justify-content-start align-items-center flex-wrap flex-md-nowrap pt-3 pb-2 mb-1'>
          <div className='col my-2'>
          <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
            
            {/* <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            // onFilterChange={filterChange}
            /> */}
            <div className='subheader h5'>{localStorage.getItem("tmpltNm")}</div>
           <div className='col-md-3 text-start fw-bold '>Account(s): {selAcct.extrnlAcctId}</div>
            <div className='col-md-2 text-start'><Card className='px-1 py-1 bg-white'  style={{position:'relative', display:dispOwner}}><b>Owner: {localStorage.getItem('owner')}</b></Card></div>
            <div className='col-md-2 text-start'><Card className='px-1 py-1 bg-white'  style={{display:dispInvofficer}}><b> Inv.Officer: {localStorage.getItem('invOfficer')}</b></Card></div>
            <div className='col-md-3 text-start'><Card className='px-1 py-1 bg-white' style={{ display:dispAdmin}}><b>Admin.: {localStorage.getItem('administrator')}</b></Card></div>
          
          <div className='col text-start'>
            
          <div className='btn-toolbar mb-md-0 mx-2'>

            <div className='btn-group me-2'>

              <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <FaCogs size={20}></FaCogs>
              </button>

              <button onClick={handlesave} className='btn btn-sm btn-outline-secondary'><FaSave size={20}></FaSave> </button>
              
            </div>
            <div className='col-md-2 text-start'>
              <div className="dropdown">
                <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  Export
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><a className="dropdown-item" onClick={showpreview} ><FaFilePdf></FaFilePdf> Pdf</a></li>
                  {/* <li><a className="dropdown-item" href="#"><FaFileExcel></FaFileExcel> Excel</a></li>
                  <li><a className="dropdown-item" href="#"><FaFilePowerpoint></FaFilePowerpoint> PowerPoint</a></li> */}

                </ul>
              </div>
            </div></div></div>
</div>
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="widgetmodal" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="widgetmodal">Widget Settings</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    
                    <div className="my-2">
                      <button className="btn btn-sm btn-outline-secondary" onClick={onResetLayout}>
                        <FaSyncAlt></FaSyncAlt> Reset layout
                      </button>
                    </div>
                    <div className='m-2 p-1'>

                      <h6 className="border-1 px-1 py-1">Toggle Widgets</h6>
                      <div style={{ overflowY: 'scroll', height: '550px' }}>
                      {widgets.map((widget) => {
                          if(widget.id!==2){
                          return (
                            <div className="my-2"  key={widget.id}>
                              <Switch
                                checked={widget.active}
                                onChange={onToggleWidget}
                                id={widget.id}
                                
                              />
                              <label className="mx-2">{widget.header}</label>
                            </div>
                          );}
                        })}

                         {/* <div className="my-2" >
                          <Switch checked={dispOwnerSwitch} onChange={toggleDispOwner}>Owner</Switch> <label className="mx-2">Owner</label>  </div>
                          <div className="my-2" >
                        <Switch checked={dispInvofficerSwitch} onChange={toggleDispInvofficer}></Switch><label className="mx-2">Investment Officer</label></div>
                        <div className="my-2" >
                        <Switch checked={dispAdminSwitch} onChange={toggleDispAdmin}>Owner</Switch><label className="mx-2">Admin</label></div> */}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div >

        <TileLayout
        autoFlow="unset"
          style={{ position: "absolute", minWidth: "100%" }}
          columns={6}
          rowHeight={255}
          positions={positions}
          gap={{ rows: 10, columns: 10 }}
          items={activeWidgets}
          onReposition={handleReposition}
          className="tileLayout"
        />

      </div>
      {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
          <Modal.Header closeButton><div className="row w-100 tableheader">
            <div className='col'>
              <Modal.Title>Asset Allocation Vs Model Report - Pdf Preview</Modal.Title>
            </div>
            <div className='col text-end'>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
              >
                Print
              </button>
            </div>

          </div>
          </Modal.Header>
          <Modal.Body>
          <PDFExport
        //forcePageBreak=".page-break"
        paperSize={'A4'}
        
        
        //keepTogether="div"
        landscape={true}
        ref={pdfExportComponent}
      >
      <div >

        <TileLayout
          reorderable={false}
          columns={3}
          rowHeight={4}
          positions={positions}
          gap={{ rows: 10, columns: 10 }}
          items={activeWidgets}
          
          className="tileLayout"
        />

      </div>
      </PDFExport>

          </Modal.Body>
          <Modal.Footer>

            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>

          </Modal.Footer>

        </Modal> */}

<Dialog

fullWidth={true}
//maxWidth={'sm'}
 open={show}
 fullScreen={true}
 onClose={handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"

>
<DialogTitle id="alert-dialog-title" className='lead ms-2 mt-1'>
<div className='col'>
              <Modal.Title> Report - Pdf Preview</Modal.Title>
            </div>
            <div className='col text-end'>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  debugger;
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
              >
                Print
              </button>
              {/* <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  debugger;
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
              >
                Print
              </button> */}
              <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>
            </div>

     
</DialogTitle>
<DialogContent>
    <div style={{ height: "100%", width: "100%" }}>
        <Box sx={{
            height: "100%",
            width: "100%",
            '&:hover': {
                opacity: [0.9, 0.8, 0.7],
            },
        }}
       
        >

<div className=''>
<PDFExport

        //forcePageBreak=".page-break"
        paperSize={'A4'}
        pageTemplate={PageTemplate}
        scale={0.4}
        //keepTogether="div"
        landscape={true}
        ref={pdfExportComponent}
        keepTogether="p"
        margin={{ top: 70, left: 10, right: 20, bottom:30 }}
      >
      <div >

        <TileLayout
        id="tile"
        autoFlow="unset"
       // style={{ position: "absolute" }}
        columns={6}
        rowHeight={255}
        positions={positions}
        gap={{ rows: 10, columns: 10 }}
        //forcePageBreak=".page-break"
        items={activeWidgets}
        
        //onReposition={handleReposition}
        className="tileLayout"
        />

      </div>
      </PDFExport>

</div>

           

            <DialogActions>
            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>
              
                 
             
            </DialogActions>
        </Box>
    </div>
</DialogContent>

</Dialog>
    </div>
  )

}
//export default withSize({ refreshMode: "debounce", refreshRate: 60 })(CustomReportMain);

export default CustomReportMain
export {snapshotCmprsncolorDatavar,sctrReturnPerfrmnceDatavar, sctrReturnPerfrmnceChartDatavar,TimeWtdRtnRptChartDatavar, TimeWtdRtnRptGridDatavar, portfoliholdingsDatavar,transactionDatavar,snapshotCmprsnDatavar,sectorCmprsnDatavar,portPerfBySecurityDatavar,portfolioBrkdwn1stDatavar,portfolioBrkdwn2ndDatavar,portfolioBrkdwn3rdDatavar, acctProfFinalDatavar, allocmodelDatavar, assetVsModelFinalDatavar, topHoldingDatavar, cashflowDatavar, astByMrktCapitalDatavar, accountHoldingDatavar, fixdincmfndmntlsDatavar, perfRORDatavar, perfMjrAsstSummaryDatavar, perfChartDatavar,purchaseAndSaleData,astVsModelData, acctPrflSmryDatavar, fimMaturityDeatils, fimMaturityLadrCallDetails, fimMatrtyDtVsCallPutDt, fipoBondQultySctr, fipoMaurityLadder, fipoBondByMinorCls, fipoBondByMjrIndstry }
