import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {portfolioBrkdwn2ndDatavar} from './cstmRptMain';




const PortfolioBreakdown2ndGridWidget = () => {
  const [brkdwnData, setbrkdwnData] = useState(portfolioBrkdwn2ndDatavar);//change Data!!!!!!!!!!!
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const createDataState = (dataState) => {
    return {
      result: process(brkdwnData.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [sort, setSort] = React.useState([]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}
const NumberCellSixDecimal = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.000000")}
        </td>
    )
}

const totalAcVMdl = (props) => {
  const field = props.field || "";
  const total = brkdwnData.reduce((acc, current) => acc + current[field], 0);
  return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, "##,#.00")}
      </td>
  );
};
  
  return (
    <div><h5 className='text-fitek p-1'>Portfolio Breakdown Grid2</h5>
    <Grid style={{width:"100%" ,height: "100%" }}
    id='tileGrid'
                                    data={orderBy(brkdwnData,sort)}
                                    onDataStateChange={dataStateChange}
                                    {...dataState}
                                //sortable={true}
                                sort={sort}
                                //onSortChange={(e) => {
                                // setSort(e.sort);
                                //}}
                                className='page-break'
                                >

                                    <Column field="descption" menu={true} title="Asset Class" width="auto" />
                                    <Column field="prtfolioWeigh" menu={true} title="% Portfolio Allocation" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName='rightHeader' />
                                    <Column field="invObj" menu={true} title="% Investment Objective Allocation" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName='rightHeader'  />

                                    <Column field="invObjvariance" menu={true} title="% Variance To Investment Objective" width="auto" footerCell={totalAcVMdl}  cell={NumberCell}  headerClassName='rightHeader' />
                                    
                                    </Grid> </div>

     
  )
}





export default PortfolioBreakdown2ndGridWidget