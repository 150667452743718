import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';
import {allocmodelDatavar} from './cstmRptMain';
const AllocationmodelWidget=()=>{
    const [data, setData] = useState(allocmodelDatavar);
    const labelContentAccVMdl = (props) => {

        let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.mdlWegh.toFixed(2)}%`;
    };
    debugger;
    //find
    return(
        <div>
        <h5 className='text-fitek p-1'>Allocation Model</h5>
                        <ResponsiveContainer >
                        {data.length===0?<div>No Data Found.</div>:
                            <Chart >
                                {/* <ChartTitle text="Major Asset Chart" /> */}
                                <ChartLegend position="bottom" />

                                <ChartSeries>
                                    <ChartSeriesItem
                                        type="pie"
                                        data={data}
                                        field="mdlWegh"
                                        categoryField="descption"
                                        colorField='chartColorCOde'
                                        autoFit={true}
                                        labels={{
                                            visible: true,
                                            content: labelContentAccVMdl,
                                        }}
                                    />
                                </ChartSeries>
                            </Chart>
                           }
                            </ResponsiveContainer> </div>

                                                        
                      
    );
};
export default AllocationmodelWidget