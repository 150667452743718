import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import Loading from './loading';
import Header from './header';
import PrtflioSnpshtComprsnGrd from './prtfolioSnpshtCmprsnGrd'
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { data } from 'jquery';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const PrtflioSnpshtComprsnRpt = () => {
  // debugger;
  // alert(localStorage.getItem('psStrtDt'));

  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [dispAssetClass,setdispAssetClass]=useState("");
  const [PrtflioSnpshtComprsnRptData, populatePrtflioSnpshtComprsnRptData] = useState([]);
  const [AstClsColorCodeData, setAstClsColorCodeData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [astcls, setAstClass] = useState("0");
  //const [CurrDt, setCurrDt] = useState(JSON.parse(localStorage.getItem('psStrtDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psStrtDt'))));
  //const [SnpDate, setSnpDate] = useState(JSON.parse(localStorage.getItem('psEndDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psEndDt'))));
  //const [BusnsDt, setBusinessDate] = useState(JSON.parse(localStorage.getItem('psBusDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psBusDt'))));
  const [CdtErrMsgHide, setCdtErrMsgHide] = useState(true);
  const [SDtErrMsgHide, setSDtErrMsgHide] = useState(true);
  const [CurrDt, setCurrDt] = useState((localStorage.getItem('processingDate')) === null ? null : new Date((localStorage.getItem('processingDate'))));
  const [SnpDate, setSnpDate] = useState((localStorage.getItem('processingDate')) === null ? null : new Date((localStorage.getItem('processingDate'))));
  const [BusnsDt, setBusinessDate] = useState((localStorage.getItem('processingDate')) === null ? null : new Date((localStorage.getItem('processingDate'))));
  const [session,setSession]=useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // alert(localStorage.getItem('psStrtDt'));
    // alert(localStorage.getItem('psEndDt'));
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;

        //setEmail(email);
        GetPrtflioSnpshtComprsnRptData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, [])

  const getColumnStateDb = async () => {
    //Storing column settings in DB
    // debugger;
    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 5;//let 5 for Portfolio Snapshot Comparison Report

    const postData = { UserId, GridId };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTGetGridColumn/Index',
      postData,
      config
    )
      .then(response => {
        // debugger;
        console.log('save in db');
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem('gridColumns', rowData);
        }
        else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log('error in save db ' + error);
        return error;
      });
  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        // debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetPrtflioSnpshtComprsnRptData();


      })
      .catch((error) => {
        // debugger;
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }
  const GetPrtflioSnpshtComprsnRptData = async () => {
    setLoading(true);
    debugger;
    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem('userId'));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = 0;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }

    let pageId = 1;

    let GrpByMnrAst = astcls;
    let CurrentDt = null, SnapshotDt = null;
    if (CurrDt !== null) {
      CurrentDt = `${(CurrDt.getMonth() + 1).toString().padStart(2, '0')}/${CurrDt.getDate().toString().padStart(2, '0')}/${CurrDt.getFullYear()}`;
      localStorage.setItem('psStrtDt', JSON.stringify(CurrDt));
    }
    if (SnpDate !== null) {
      SnapshotDt = `${(SnpDate.getMonth() + 1).toString().padStart(2, '0')}/${SnpDate.getDate().toString().padStart(2, '0')}/${SnpDate.getFullYear()}`;
      localStorage.setItem('psEndDt', JSON.stringify(SnpDate));
    }
    const postData = { AsOfId, AcctId, pageId, GrpByMnrAst, CurrentDt, SnapshotDt };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTPortfolioSnapShotComparison',
      postData,
      config
    )
      .then(response => {
        // debugger;
        const rowData = response.data;
        var i=0;
 
    for(i=0;i<rowData.t1.length;i++){
        debugger;
        if(rowData.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1"){
          rowData.t1[i].groupName="^"+rowData.t1[i].groupName;
        }
       if(rowData.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1")
       {setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");}
        
      }
        populatePrtflioSnpshtComprsnRptData(rowData.t1)
        populateAccountListData(rowData.t2)
        setSelAcctData(rowData.t2);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        else {
          SetselAcct(rowData.t2[0]);
        }
        let sDt = new Date(rowData.t3[0].strtDt);
        if (CurrDt === null) {
          setCurrDt(new Date(sDt));
          localStorage.setItem('psStrtDt', JSON.stringify(rowData.t3[0].strtDt));
        }
        // alert(sDt);
        let eDt = new Date(rowData.t3[0].asOfDt);
        if (SnpDate === null) {
          setSnpDate(new Date(eDt));
          localStorage.setItem('psEndDt', JSON.stringify(rowData.t3[0].asOfDt));
          setBusinessDate(new Date(eDt));
          localStorage.setItem('psBusDt', JSON.stringify(rowData.t3[0].asOfDt));
        }
        debugger;
        setAstClsColorCodeData(rowData.t4);
        setLoading(false);

      })
      .catch((error) => {

        if (error.response.status === 401) {
          refreshToken();


        }
      });

  }
  const formSubmit=(event)=>{
    GetPrtflioSnpshtComprsnRptDataRdo();
  }
  const GetPrtflioSnpshtComprsnRptDataRdo = async (GrpByMAst) => {
    setLoading(true);
    // debugger;
    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem('userId'));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = 0;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }

    let pageId = 1;
    let GrpByMnrAst = GrpByMAst;
    let CurrentDt = null, SnapshotDt = null;
    if (CurrDt !== null) {
      CurrentDt = `${(CurrDt.getMonth() + 1).toString().padStart(2, '0')}/${CurrDt.getDate().toString().padStart(2, '0')}/${CurrDt.getFullYear()}`;
    }
    if (SnpDate !== null) {
      SnapshotDt = `${(SnpDate.getMonth() + 1).toString().padStart(2, '0')}/${SnpDate.getDate().toString().padStart(2, '0')}/${SnpDate.getFullYear()}`;
    }
    const postData = { AsOfId, AcctId, pageId, GrpByMnrAst, CurrentDt, SnapshotDt };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTPortfolioSnapShotComparison',
      postData,
      config
    )
      .then(response => {
        debugger;
        const rowData = response.data;
        var i=0;
 
    for(i=0;i<rowData.t1.length;i++){
        debugger;
        if(rowData.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1"){
          rowData.t1[i].groupName="^"+rowData.t1[i].groupName;
        }
       if(rowData.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1")
       {setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");}
        
      }
        populatePrtflioSnpshtComprsnRptData(rowData.t1)
        populateAccountListData(rowData.t2)
        setSelAcctData(rowData.t2);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        else {
          SetselAcct(rowData.t2[0]);
        }
        let sDt = new Date(rowData.t3[0].strtDt);
        if (CurrDt === null) {
          setCurrDt(new Date(sDt));
          localStorage.setItem('psStrtDt', JSON.stringify(rowData.t3[0].strtDt));
        }
        // alert(sDt);
        let eDt = new Date(rowData.t3[0].asOfDt);
        if (SnpDate === null) {
          setSnpDate(new Date(eDt));
          localStorage.setItem('psEndDt', JSON.stringify(rowData.t3[0].asOfDt));
          setBusinessDate(new Date(eDt));
          localStorage.setItem('psBusDt', JSON.stringify(rowData.t3[0].asOfDt));
        }
        setAstClsColorCodeData(rowData.t4);
        setLoading(false);

      })
      .catch((error) => {

        if (error.response.status === 401) {
          refreshToken();
        }
      });

  }
  const filterData = (filter) => {

    // const dataAcct = selAcctData.slice();
    return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
  };

  const filterChange = (event) => {

    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    // debugger;
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem('IsAcctSelected', false);
      localStorage.setItem('AcctSelected', JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem('IsAcctSelected', true);
      localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPrtflioSnpshtComprsnRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const assetClassChange = (e) => {
     debugger;
    if (astcls !== e.target.value) {
      setAstClass(e.target.value);
      GetPrtflioSnpshtComprsnRptDataRdo(e.target.value);
    }
  }
  const searchClick = () => {
    if (CdtErrMsgHide && SDtErrMsgHide) {
      setCdtErrMsgHide(true);
      setSDtErrMsgHide(true);
      GetPrtflioSnpshtComprsnRptData();
    }
  };
  function setFromDate(val) {
    debugger;
    const cDt = `${val.getDate()}-${val.getMonth()}-${val.getFullYear()}`;
    const sDt = `${SnpDate.getDate()}-${SnpDate.getMonth()}-${SnpDate.getFullYear()}`
    if (val <= SnpDate) {
      setCurrDt(val);
      setCdtErrMsgHide(true);
      localStorage.setItem('psStrtDt', JSON.stringify(val));
    }
    else {
      setCdtErrMsgHide(false);
    }
  }
  function setAsOfDate(val) {
    debugger;
    const sDt = `${val.getDate()}-${val.getMonth()}-${val.getFullYear()}`;
    const cDt = `${CurrDt.getDate()}-${CurrDt.getMonth()}-${CurrDt.getFullYear()}`
    if (CurrDt <= val) {
      setSnpDate(val);
      setSDtErrMsgHide(true);
      localStorage.setItem('psEndDt', JSON.stringify(val));
    }
    else {
      setSDtErrMsgHide(false);
    }
  }

  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // debugger;
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         


        // }

       
      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();

  }

  if (loading) {
    return (
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-3 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
        </div>
        <Loading />
        </div>}</>
    )
  }
  return (
    <div>
      <Header></Header>
      <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            onFilterChange={filterChange}
          />
        </div>
      </div>
      <div>
      <form onSubmit={formSubmit}  >
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 rounded'>
          <div className='col'>
            <div className="form-check mt-1 k-text-center py-2 mb-2">
              <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" defaultChecked={astcls === "0"} value="0" className="btn-check form-check-input" name="astcls" id="mjrAstTyp" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="mjrAstTyp">Major Asset</label>

                <input type="radio" value="1" defaultChecked={astcls === "1"} className="btn-check form-check-input" name="astcls" id="mnrAstTyp" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="mnrAstTyp">Minor Asset</label>
              </div>
            </div>
          </div>
          <div className='col'>
            <span className='py-1'>Current Date</span>
            <div className='text-start'>
              <span style={{ color: 'red' }} hidden={CdtErrMsgHide}>&nbsp;should be less than snapshot date</span>
              <DatePicker id="dpFrm"
                defaultValue={CurrDt}
                format="MM/dd/yyyy"
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={new Date(`${(SnpDate === null ? BusnsDt : SnpDate).getFullYear()},${((SnpDate === null ? BusnsDt : SnpDate).getMonth() + 1).toString().padStart(2, '0')},${(SnpDate === null ? BusnsDt : SnpDate).getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setFromDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col'>
            <div className='text-start'>
              <span className='py-1'>Snapshot Date</span><span style={{ color: 'red' }} hidden={SDtErrMsgHide}>&nbsp;should be greater than current date</span>
              <DatePicker
                defaultValue={SnpDate}
                format="MM/dd/yyyy"
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={new Date(`${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1).toString().padStart(2, '0')},${BusnsDt.getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setAsOfDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col'>
            <div className='text-start'>
            <input type="submit"  className='btn btn-primary' value="Submit"/> 
            </div>
          </div>
        </div>
        </form>
      </div>
      <PrtflioSnpshtComprsnGrd data={PrtflioSnpshtComprsnRptData} astColorModel={AstClsColorCodeData} flag={isColumnSave} dispAssetClass={dispAssetClass}/>
    </div>
  )
}

export default PrtflioSnpshtComprsnRpt