import React from 'react'
import { useState,useEffect } from 'react'
import "../index.css";





import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation
  
  
  } from 'react-router-dom';
  import {signInWithGooglePopup,signInWithMicrosoftPopup,createUserDocumentFromAuth, firebaseApp,auth} from '../utils/firebase/firebase.utils';

  import RTCustomDashboardRpt from './cstmDashBoardRpt'
import Home  from './home';     
import Dashboard  from './dashboard'; 
import AcctHoldingRpt from './acctHoldingRpt';
import AcctTransactionRpt from './acctTransactionRpt';
import PageNotFound  from './pageNotFound'; 
import FixdIncmFndmntlsRpt from './fixdIncmFndmntlsRpt'
import AccountProfileReport from './accountProfileReport';
import PortfolioHoldingsRpt from './portfoliHoldingsRpt';
import FixdIncmMaturityLadrRpt from './fixdIncmMtrtyLadrRpt';
import AcctSectBenchRpt from './AcctSectBenchRpt';
import AcctPerfRpt from './AcctPerfRpt';
import FixedIncomePortfolioOverviewRpt from './fixedIncomePortfolioOverviewRpt';
import SctrReturnPerformanceRpt from './sctrReturnPerfrmnceRpt';
import TimeWtdRtnRpt from './TimeWtdRtnRpt';
import CustomReportMain from './cstmRptMain'
import ProjectedCashFlowRpt from './projectedCashFlowRpt';
import RTPortfolioBreakDownRpt from './portfolioBreakDownRpt';
import RTPerformanceWidgetRpt from './performanceWidgetRpt';
import AssetAllocModelRptMain from './assetAllocModelRptMain';
import TopHoldingsReport from './topHoldingsReport';
import AstByMrktCapitalRpt from './astByMrktCapitalRpt';
import PortPerfSecurityRpt from './portPerfSecurityRpt';
import PrchsSaleRpt from './prchsSaleRpt';
import PrtflioSnpshtComprsnRpt from './prtfolioSnpshtCmprsnRpt';
import RTPortfolioOverviewRpt from './portfolioOverviewRpt';
import RTFxdIncmPrtfloOvrvw from './fixedIncomePortfolioOverviewRpt';
import UserToken from './userToken';

function Login() {
      return (
        <Router>      
           <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/dashboard' element={<Dashboard />}/>
              <Route path='/acctHoldingRpt' element={<AcctHoldingRpt />}/>
              <Route path='/acctTransactionRpt' element={<AcctTransactionRpt />}/>
              <Route path='/fixdIncmFndmntlsRpt' element={<FixdIncmFndmntlsRpt />}/>
              <Route path='/accountProfileReport' element={<AccountProfileReport />}/>
              <Route path='/portfoliHoldingsRpt' element={<PortfolioHoldingsRpt />}/>
              <Route path='/fixdIncmMtrtyLadrRpt' element={<FixdIncmMaturityLadrRpt />}/>
              <Route path='/AcctSectBenchRpt' element={<AcctSectBenchRpt />}/>
              <Route path='/AcctPerfRpt' element={<AcctPerfRpt />}/>
              <Route path='/fixedIncomePortfolioOverviewRpt' element={<FixedIncomePortfolioOverviewRpt />}/> 
              <Route path='/sctrReturnPerfrmnceRpt' element={<SctrReturnPerformanceRpt/>}/> 
              <Route path='/TimeWtdRtnRpt' element={<TimeWtdRtnRpt/>}/> 
              <Route path='/cstmRptMain' element={<CustomReportMain/>}/>
              <Route path='/projectedCashFlowRpt' element={<ProjectedCashFlowRpt/>}/>
              <Route path='/portfolioBreakDownRpt' element={<RTPortfolioBreakDownRpt/>}/>
              <Route path='/PerformanceWidgetRpt' element={<RTPerformanceWidgetRpt/>}/>
              <Route path='/assetAllocModelRptMain' element={<AssetAllocModelRptMain/>}/> 
              <Route path='/topHoldingsReport' element={<TopHoldingsReport/>}/> 
              <Route path='/astByMrktCapitalRpt' element={<AstByMrktCapitalRpt/>}/> 
              <Route path='/portPerfSecurityRpt' element={<PortPerfSecurityRpt/>}/> 
              <Route path='/prchsSaleRpt' element={<PrchsSaleRpt/>}/>
              <Route path='/prtfolioSnpshtCmprsnRpt' element={<PrtflioSnpshtComprsnRpt/>}/>
              <Route path='/portfolioOverviewRpt' element={<RTPortfolioOverviewRpt/>}/>
              <Route path='/cstmDashBoardRpt' element={<RTCustomDashboardRpt/>}/>
              <Route path='/fixedIncomePortfolioOverviewRpt' element={<RTFxdIncmPrtfloOvrvw/>}/>

              <Route path='*' element={<PageNotFound />}/>
              <Route path='/userToken' element={<UserToken/>}/>
              
            </Routes>    
        </Router>     
      );
  
   
  }

export default Login
