import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
//import { ColumnMenu } from "./columnMenu";
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {ResponsiveContainer} from 'recharts';
import {astByMrktCapitalDatavar} from './cstmRptMain';
const AstByMrktCapitalWidget=()=>{
    const [data, setData] = useState(astByMrktCapitalDatavar);
    const [astByMrktCapitaldata, setastByMrktCapitaldata] = useState(astByMrktCapitalDatavar);
    const totalSum = (props) => {
        const field = props.field || '';
        const total = data
          .reduce((acc, current) => acc + current[field], 0)
          .toFixed(2);
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };
  
      let columns = [
          {
            title: 'Ticker',
            field: 'tckrSymbl',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
          },
          {
            title: 'Cusip',
            field: 'cusip',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
          },
          {
            title: 'Asset Short name',
            field: 'astShrtNm',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
          },
          {
            title: 'Volume',
            field: 'nbrOutstndngShrs',
            minWidth: "auto",
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
          },
          {
            title: 'Current Price($)',
            field: 'currPrc',
            minWidth: "auto",
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
          },
          {
            title: 'Market Capital($)',
            field: 'marketCap',
            minWidth: "auto",
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
          },
          {
            title: 'Capital Type',
            field: 'mktCapNm',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
          }
        ];
  
    const createDataState = (dataState) => {
      return {
        result: process(data, dataState),
        dataState: dataState,
      };
    };
    let initialState = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
    });
  
    const [result, setResult] = useState(initialState.result);
    const [dataState, setDataState] = useState(initialState.dataState);
    const [stateColumns, setStateColumns] = useState(columns);
  
    const dataStateChange = (event) => {
      let updatedState = createDataState(event.dataState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
      };
      const expandChange = (event) => {
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
      };
      const getCells = (columns, cellProps) => {
        let cells = [];
        columns.forEach((column) => {
          if (column.aggregate) {
            cells.push(
              <td style={{ textAlign: "right" }}>
                {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
              </td>
            );
          } else {
            cells.push(<td>&nbsp;</td>);
          }
        });
        return cells;
      };
    
      const cellRender = (tdElement, cellProps) => {
        if (
          cellProps.rowType === 'groupHeader' &&
          tdElement &&
          tdElement.props.role != 'presentation'
        ) {
          
          const columns = columns;
          return (
            <>
              <td
                {...tdElement.props}
                colSpan={tdElement.props.colSpan - columns.length}
              ></td>
              {getCells(columns, cellProps)}
            </>
          );
        }
        // if (cellProps.rowType === 'groupFooter') {
    
        //   if (cellProps.field === 'totMarket') {
        //     return (
        //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
        //         {cellProps.dataItem.aggregates.totMarket.sum}
        //       </td>
        //     );
        //   }
    
        // }
        if (cellProps.rowType === "data") {
    
          if (cellProps.field === "nbrOutstndngShrs") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "currPrc") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "marketCap") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          
          
        }
        return tdElement;
      };
      const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
      };
    
    return(
           
      <ResponsiveContainer>  
        <div><h5 className='text-fitek p-1'>Asset By Market Capital</h5>
      <Grid
      id='tileGrid'
      style={{ height: "auto" }}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      
      expandField="expanded"
      onExpandChange={expandChange}
      cellRender={cellRender}
      sortable={true}
      resizable={true}
      //pageable={true}
      reorderable={true}
     
       //pageSize={10}
       
      
    >
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
            //  locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              footerCell={column.footerCell}
              headerClassName={column.headerClassName}
              {...column}
              //cell={NumberCell}
              // columnMenu={(props) => (
              //   <CustomColumnMenuNoGrp
              //     {...props}
              //     columns={stateColumns}
              //     onColumnsSubmit={onColumnsSubmit}
              //   />
              // )}
            />
          )
      )}
    </Grid>
    </div>       
    </ResponsiveContainer>                      
                            
                        
    
                    
    )
    
}

export default AstByMrktCapitalWidget

