import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartSeriesLabels,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';

import {snapshotCmprsnDatavar,snapshotCmprsncolorDatavar} from './cstmRptMain';//change Data!!!!!!!!!!!

const PortfolioSnpshotCurrentChartWidget = () => {
debugger;
    const [chartType, setChartType] = useState("column");
    const [astModelChartdata, setastModelChartdata] = useState(snapshotCmprsnDatavar);
    const chartDefaultV4Colors = [
        "#235ee7",
        "#dce4ea",
        "#4ac9c9",
        "#d3dffb",
        "#7c9ff2",
        "#f3b021",
        "#f8d0c0",
    ];
    const psCmprsnFinalData =snapshotCmprsnDatavar;
    const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.marketPercent.toFixed(2)}%`;
    };
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
    return (
        <><h5 className='text-fitek p-1'>Current Portfolio Chart



        </h5>      
            <ResponsiveContainer >
                {psCmprsnFinalData.length===0?<div>No Data Found.</div>:
            <Chart style={{  }}>
                            <ChartLegend position="right" />
                            <ChartSeries>
                                <ChartSeriesItem
                                    type={"pie"}
                                    data={psCmprsnFinalData.filter(x=>x.marketPercentCur!=0)}
                                    field="marketPercentCur"
                                    categoryField="groupName"
                                    autoFit={true}
                                    colorField="chartColorCOde"
                                    labels={{
                                        visible: true,
                                        content: labelContent,
                                    }}
                                >
                                    { (
        <ChartSeriesLabels
          position="outsideEnd"
          background="none"
          content={labelContent}
        />
      )}
                                </ChartSeriesItem>
                            </ChartSeries>
                        </Chart>}
            </ResponsiveContainer>
            </>
     
  )
}

export default PortfolioSnpshotCurrentChartWidget

