import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ResponsiveContainer } from "recharts";
import {portfoliholdingsDatavar} from './cstmRptMain';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];

  const PortfolioHoldingWidget= () => {
    debugger;
    //const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(JSON.parse(global.localStorage.getItem("portfoliHoldingData")));
    console.log({portfoliholdingsDatavar});
    const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(portfoliholdingsDatavar);
    let defaultColumns = [
      // {
      //   title: 'Inv. Objective',
      //   field: 'invstmntObj',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Acct Type',
      //   field: 'accountType',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Account#',
      //   field: 'account',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Major Asset',
      //   field: 'majorAssetType',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      {
        title: 'Ticker/Cusip',
        field: 'tckerCusip',
        minWidth: "150",
        show: true,
        filter: 'text',
        locked: true,
      },
      {
        title: 'Description',
        field: 'assetShrtNm',
        minWidth: 'auto',
        show: true,
        filter: 'text',
        locked: true,
      },
      {
        title: 'Units',
        field: 'units',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Cost($)',
        field: 'txcstAmt',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Price($)',
        field: 'price',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Market($)',
        field: 'totMarket',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      {
        title: 'GainLoss($)',
        field: 'gainLoss',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Income($)',
        field: 'income',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Yield(%)',
        field: 'yield',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      // {
      //   title: 'Principal($)',
      //   field: 'p1CashBlncAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Inv. Income($)',
      //   field: 'p2P3CashBlncAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'UnEx. Cash($)',
      //   field: 'unExecCashAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Trade Cash($)',
      //   field: 'tradeCash',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Excld Cash($)',
      //   field: 'excldCashAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Equity %',
      //   field: 'equityPercent',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
    ];
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    const createDataState = (dataState) => {
        return {
          result: process(portfolioHoldingsdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
        group: [{field: 'majorAssetType'}]
      });
      let initialStateExport = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
        group: [{field: 'majorAssetType'}]
      });
      const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };
      const expandChange = (event) => {
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
      };
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const totalSum = (props) => {
        const field = props.field || "";
        const total = portfolioHoldingsdata.reduce((acc, current) => acc + current[field], 0).toFixed(2);;
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };
      const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
      };
    return(
        
          <ResponsiveContainer >   
            <div>
            <h5 className='text-fitek p-1'>Portfolio Holdings</h5>
            <div >
            <p>Investment Objective : {portfoliholdingsDatavar[0].invstmntObj}</p>
            </div>
            <div className="mx-1 my-1 py-1">
            <Grid style={{ width: "100%", height: "100%" }}
            id='tileGrid'
                data={result}
                onDataStateChange={dataStateChange}
                expandField="expanded"
              onExpandChange={expandChange}
                {...dataState}
                scrollable={"scrollable"}
              //pageable={true}
              //pageSize={10}
              resizable={true}
              //reorderable={true}
              sortable={true}
          >
            {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      //footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      //cell={NumberCell}
                    
                    />
                  )
              )}
            {/* <Column field="branchName" menu={true} title="Branch" width="auto" /> */}
                {/* <Column field="invstmntObj" title="Inv. Objective" width="auto" /> */}
                {/* <Column field="accountType" menu={true} title="Acct. Type" width="auto" />
                <Column field="accountName" menu={true} title="Account#" width="auto" /> */}
                {/* <Column field="tckerCusip" title="Ticker/Cusip" width="auto" />
                <Column field="assetShrtNm" title="Description" width="auto" />
                <Column field="units" title="Units" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="txcstAmt" title="Cost($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="price" title="Price($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="totMarket" title="Market($)" width="auto" headerClassName='rightHeader' footerCell={totalSum}   cell={NumberCell}/>
                <Column field="gainLoss" title="Gain Loss($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="income" title="Income($)" width="auto" headerClassName='rightHeader' cell={NumberCell}/>    
                <Column field="yield" title="Yield(%)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/> */}
                {/* <Column field="p1CashBlncAmt" title="Principal($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="p2P3CashBlncAmt" title="Inv. Income($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="unExecCashAmt" title="UnEx. Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="tradeCash" title="Trade Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="excldCashAmt" title="Excld Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/> */}
                {/* <Column field="equityPercent" title="Equity %" headerClassName='rightHeader'  width="auto"  cell={NumberCell} /> */}

          </Grid>
          </div>
          <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className="subheader text-end col-md-2">
          <p>Principal Cash : {formatNumber(portfoliholdingsDatavar[0].p1CashBlncAmt, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Income Cash : {formatNumber(portfoliholdingsDatavar[0].p2P3CashBlncAmt, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Trade Cash : {formatNumber(portfoliholdingsDatavar[0].tradeCash, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Exclude Cash Cash : {formatNumber(portfoliholdingsDatavar[0].excldCashAmt, '##,#.00')}</p>
          </div>
        </div>
          </div>   
          </ResponsiveContainer>          
      
                    

                
    )

}
  export default PortfolioHoldingWidget

