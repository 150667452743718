import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Header from './header';
import TimeWtdRtnRptGrid from './TimeWtdRtnRptGrid'
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import refreshFunction from './refreshFunc';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
const TimeWtdRtnRpt = () => {
  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const minFrmDt = new Date(2021,8,14);
      const maxFrmDt = new Date(2022,8,14);
      const[isDisabled,setisDisabled]= React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [session,setSession]=useState("");
  const navigate = useNavigate();

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;

        //setEmail(email);
        GetTimeWtdRtnRpteData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, []);
  function setDate (val){
    debugger;
  
    // if(val.language === '1'){
      //  const fDate = new Date("2021-04-22");
        
    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
      if (val == null)
      {
       // const fDate = new Date("2021-01-22");
        setToDate(toDate);
        
      }
       else{ 
        setToDate(val);
       }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  const searchClick = () => {
          
    GetTimeWtdRtnRpteData();
  };
  const formSubmit=(event)=>{
    GetTimeWtdRtnRpteData();
  }
  const GetTimeWtdRtnRpteData = async () => {
    //debugger;
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem('userId'));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = 0;
    let PageId = 1;
    let netFeeId = 1;
    let ToDate=toDate;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    const postData = { AsOfId, AcctId, netFeeId, PageId,ToDate };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTTimeWtdRtnOfSlctdPrds',
      postData,
      config
    )
      .then(response => {

        //  console.log(response);
        //debugger;
        const rowData = response.data;
        populateTimeWtdRtnRptRptData(rowData.lstRTTimeWtdReturnT1);
        populateAccountListData(rowData.lstAcctTmWtdRtnList)
        setSelAcctData(rowData.lstAcctTmWtdRtnList);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        debugger;
        if (error.response.status === 401) {
          refreshToken();


        }
        // return error;
      });

  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();


      })
      .catch((error) => {
        // debugger;
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }

  const getColumnStateDb = async () =>{
    //Storing column settings in DB
    debugger;
      let token = JSON.parse(localStorage.getItem('token'));
      let UserId = JSON.parse(localStorage.getItem('userId'));
      let GridId = 33;
      
      const postData = {UserId, GridId};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
      };
      await axios.post('/RTGetGridColumn/Index',
        postData,
        config
      )
        .then(response => {
          debugger;
          console.log('save in db');
          console.log(response);
            const rowData = response.data;
            if(rowData!==""){
              setIsColumnSave(true);
              localStorage.setItem('gridColumns', rowData);
            }
            else{
              setIsColumnSave(false);
            }
        })
        .catch((error) => {
          console.log('error in save db '+error);
            return error;
        });
    }


  const handleChange = (event) => {
    //debugger;
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }



  };

  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  
    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // debugger;
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         
  
  
        // }
  
       
      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })
  
      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();
  
  }



  if (loading) {
    return (
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <Loading />
        </div>}</>
    )
  }
  return (
    <form onSubmit={formSubmit}  >
    <div>
      <Header></Header>
      
      <div className='my-1'>
        {/* <div className="rounded"></div> */}
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       
          <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
            <span className='px-2'>Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            // onFilterChange={filterChange}
            />
          </div>
         
          <div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          <div className='col-md-3 col-lg-3 col-sm-10'>
            <span className='py-1'>To</span>
          <DatePicker id="dpFrm"
                defaultValue={toDate}
                format="MM/dd/yyyy"
                min={minFrmDt}
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e)=>{
                  setDate(e.value);
                  
                }}
              
              />
  
          </div>
  
          {/* <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>To</span>
          <DatePicker
                disabled={true}
                defaultValue={toDate}
                format="dd/MMM/yyyy"
              />
  
          </div> */}
   
          <div className='col-md-2 col-lg-2 col-sm-10'>
  
          <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 
  
          </div>
       
        </div>
      </div>
      <div className='row card-header d-flex align-items-center py-2 mx-1 border-0 shadow-none'>
          
         
     
        </div>
      {flag === 1 ?
        <TimeWtdRtnRptGrid data={TimeWtdRtnRptRptData} flag={isColumnSave} />
        : <></>
      }
      
    </div>
    </form>
  )
}

export default TimeWtdRtnRpt


