import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import Loading from './loading';
import Header  from './header';
import PrchsSaleGrid from './prchsSaleGrid';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from 'linq';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const PrchsSaleRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [PurchaseSaleRptData, populatePurchaseSaleRptData] = useState([]);
    const [AcctList, populateAccountListData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [isColumnSave, setIsColumnSave] = useState(false);
    const [StartDt, setPrchsSaleStrtDt] = useState(JSON.parse(localStorage.getItem('prchsSlStrtDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('prchsSlStrtDt'))));
    const [EndDt, setPrchsSaleEndDate] = useState(JSON.parse(localStorage.getItem('prchsSlEndDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('prchsSlEndDt'))));
    const [BusnsDt, setBusinessDate] = useState(JSON.parse(localStorage.getItem('prchsSlBusDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('prchsSlBusDt'))));
    const [SdtErrMsgHide, setSdtErrMsgHide] = useState(true);
    const [EDtErrMsgHide, setEDtErrMsgHide] = useState(true);
    const [session,setSession]=useState("");
    const navigate = useNavigate();
    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetPrchsSaleRptData();
              getColumnStateDb();
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])
  
  const getColumnStateDb = async () =>{
    //Storing column settings in DB
    debugger;
      let token = JSON.parse(localStorage.getItem('token'));
      let UserId = JSON.parse(localStorage.getItem('userId'));
      let GridId = 4;//let 4 for Purchase Sale Grid
      
      const postData = {UserId, GridId};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
      };
      await axios.post('/RTGetGridColumn/Index',
        postData,
        config
      )
        .then(response => {
          debugger;
          console.log('save in db');
          console.log(response);
            const rowData = response.data;
            if(rowData!==""){
              setIsColumnSave(true);
              localStorage.setItem('gridColumns', rowData);
            }
            else{
              setIsColumnSave(false);
            }
        })
        .catch((error) => {
          console.log('error in save db '+error);
            return error;
        });
    }
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          debugger;
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetPrchsSaleRptData();
  
  
        })
        .catch((error) => {
          // debugger;
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }
    const formSubmit=(event)=>{
      GetPrchsSaleRptData();
    }
  const GetPrchsSaleRptData = async () => {
    debugger;
    setLoading(true);
  debugger;
  let token=tempToken;
     //let token = JSON.parse(localStorage.getItem('token'));
     let AsOfId = JSON.parse(localStorage.getItem('userId'));

     let lsSelectedAcct=JSON.parse(localStorage.getItem("AcctSelected"));
     let AcctId = 0;
     if(lsSelectedAcct!== null)
     {
      AcctId=lsSelectedAcct.acctId;
     }

     let pageId = 1;
     let pStrtDate = null, pEndDate = null;
    if (StartDt !== null) {
      pStrtDate = `${(StartDt.getMonth() + 1).toString().padStart(2, '0')}/${StartDt.getDate().toString().padStart(2, '0')}/${StartDt.getFullYear()}`;
    }
    if (EndDt !== null) {
      pEndDate = `${(EndDt.getMonth() + 1).toString().padStart(2, '0')}/${EndDt.getDate().toString().padStart(2, '0')}/${EndDt.getFullYear()}`;
    }
     const postData = {AsOfId,AcctId, pageId, pStrtDate, pEndDate};
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
    await axios.post('/RTPurchaseAndSaleReport',
        postData,
       config
    )
        .then(response => {
          debugger;
          const rowData = response.data;
          populatePurchaseSaleRptData(rowData.t1)
          populateAccountListData(rowData.t2)
          setSelAcctData(rowData.t2);
          if(lsSelectedAcct!== null)
          {
              SetselAcct(lsSelectedAcct);
          }
          else
          {
              SetselAcct(rowData.t2[0]);
          }
          let sDt = new Date(rowData.t3[0].strtDt);
          if (StartDt === null) {
            setPrchsSaleStrtDt(new Date(sDt));
            localStorage.setItem('prchsSlStrtDt', JSON.stringify(rowData.t3[0].strtDt));
          }
          // alert(sDt);
          let eDt = new Date(rowData.t3[0].asOfDt);
          if (EndDt === null) {
            setPrchsSaleEndDate(new Date(eDt));
            localStorage.setItem('prchsSlEndDt', JSON.stringify(rowData.t3[0].asOfDt));
            setBusinessDate(new Date(eDt));
            localStorage.setItem('prchsSlBusDt', JSON.stringify(rowData.t3[0].asOfDt));
          }
          setLoading(false);
  
        })
        .catch((error) => {
  
            if (error.response.status === 401) {
          refreshToken();


        }
        });
  
  }
  const filterData = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
  
   const filterChange = (event) => {
     
     setSelAcctData(filterData(event.filter));
   };
  
  const handleChange = (event) => {
        debugger;
    if (event.target.value === null) {        
        SetselAcct(selAcct);
    }
    else {
        debugger;
        SetselAcct(event.target.value);
        localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
        GetPrchsSaleRptData();
    }
  };
  
  const searchClick = () => {
    if (SdtErrMsgHide && EDtErrMsgHide) {
      setSdtErrMsgHide(true);
      setEDtErrMsgHide(true);
      GetPrchsSaleRptData();
    }
  };
  function setStartDate(val) {
    debugger;
    if (val <= EndDt) {
      setPrchsSaleStrtDt(val);
      setSdtErrMsgHide(true);
      localStorage.setItem('prchsSlStrtDt', JSON.stringify(val));
    }
    else {
      setSdtErrMsgHide(false);
    }
  }
  function setEndDate(val) {
    debugger;
    if (StartDt <= val) {
      setPrchsSaleEndDate(val);
      setEDtErrMsgHide(true);
      localStorage.setItem('prchsSlEndDt', JSON.stringify(val));
    }
    else {
      setEDtErrMsgHide(false);
    }
  }
  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // debugger;
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         


        // }

       
      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();

  }
  
  
  if (loading) {
  
    return(
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-4 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
          </div>
      <Loading />
      </div>}</>
    )
   
 

}
    return (
      <div>
        <Header></Header>
        <form onSubmit={formSubmit}  >
        <div className='row d-flex justify-content-end align-items-center py-2 px-2 mx-2 bg-light shadow-sm rounded'>
       
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-3 text-start'>
            <ComboBox
              style={{
                width: "300px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
          <div className='col'>
            <span className='py-1'>Start Date</span>
            <div className='text-start'>
              <span style={{ color: 'red' }} hidden={SdtErrMsgHide}>&nbsp;should be less than snapshot date</span>
              <DatePicker id="dpFrm"
                defaultValue={StartDt}
                format="MM/dd/yyyy"
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={new Date(`${(EndDt === null ? BusnsDt : EndDt).getFullYear()},${((EndDt === null ? BusnsDt : EndDt).getMonth() + 1).toString().padStart(2, '0')},${(EndDt === null ? BusnsDt : EndDt).getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setStartDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col'>
            <div className='text-start'>
              <span className='py-1'>End Date</span><span style={{ color: 'red' }} hidden={EDtErrMsgHide}>&nbsp;should be greater than current date</span>
              <DatePicker
                defaultValue={EndDt}
                format="MM/dd/yyyy"
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={new Date(`${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1).toString().padStart(2, '0')},${BusnsDt.getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setEndDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col py-1'>
            <div className='text-start'>
            <input type="submit" className='btn btn-primary btn-sm'value="Submit"/>
            </div>
          </div>
         
          </div>
          </form>
          <div>
        {/* <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='col'>
            <span className='py-1'>Start Date</span>
            <div className='text-start'>
              <span style={{ color: 'red' }} hidden={SdtErrMsgHide}>&nbsp;should be less than snapshot date</span>
              <DatePicker id="dpFrm"
                defaultValue={StartDt}
                format="MM/dd/yyyy"
                max={new Date(`${(EndDt === null ? BusnsDt : EndDt).getFullYear()},${((EndDt === null ? BusnsDt : EndDt).getMonth() + 1).toString().padStart(2, '0')},${(EndDt === null ? BusnsDt : EndDt).getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setStartDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col'>
            <div className='text-start'>
              <span className='py-1'>End Date</span><span style={{ color: 'red' }} hidden={EDtErrMsgHide}>&nbsp;should be greater than current date</span>
              <DatePicker
                defaultValue={EndDt}
                format="MM/dd/yyyy"
                max={new Date(`${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1).toString().padStart(2, '0')},${BusnsDt.getDate().toString().padStart(2, '0')}`)}
                onChange={(e) => {
                  setEndDate(e.value);
                }}
              />
            </div>
          </div>
          <div className='col'>
            <div className='text-start'>
              <button className='btn btn-primary btn-sm' onClick={searchClick}>Submit</button>
            </div>
          </div>
        </div> */}
      </div>
  
         
         <PrchsSaleGrid data={PurchaseSaleRptData} flag={isColumnSave} accntSelected={selAcct} />
         
         
      </div>
    )
  }
  
  export default PrchsSaleRpt