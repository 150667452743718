
import React from 'react'
import { useState, useEffect } from 'react';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
  } from "@progress/kendo-react-dropdowns";
const TestAcct=({data})=>{
  debugger;
    const [selAcctData, setSelAcctData] = useState(data);
    //const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    
    const handleChange = (event) => {
        //debugger;
                if (event.target.value === null) {
                  //SetselAcct('');
                  SetselAcct(selAcct);
                 // GetAcctProfileData();
                  // GetUpdatedAccountProfile(0);
                }
                else {
                  SetselAcct(event.target.value);
                  localStorage.setItem("AcctSelected",JSON.stringify(event.target.value));

                  //GetUpdatedAccountProfile(event.target.value.acctId);
                }
              };
    return(
    <div  className='my-1'>
      {/* <div className="rounded"></div> */}
       <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
       <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
           // value={selAcct}
            onChange={handleChange}
            // onFilterChange={filterChange}
          />
          </div>
          </div>
          </div>
    );
};export default TestAcct