import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from './loading';
import Header  from './header';
import GridMjrAsset from './gridMjrAsset';
import BerryDash from './berryDash';
import Enumerable from 'linq';
import refreshFunction from './refreshFunc';
import { data } from 'jquery';
import DashboardDetails from './dashboardDetails'
 //import "@progress/kendo-theme-default/dist/all.css";
 //import "@progress/kendo-theme-bootstrap/dist/all.css";
 //import "@progress/kendo-theme-material/dist/all.css";

 const Dashboard = () => {
  debugger;
  const [email, setEmail] = useState("");
  const [accountGet, setAccountGet] = useState([]);
  const [roleGet, setroleGet] = useState("");
  const [cntctId, setcntctId] = useState("");
  const [mjrAcctDtls, setMjrAcctDtls] = useState([]);
  const [mnrAcctDtls, setMnrAcctDtls] = useState([]);
  const [assetDtls, setAssetDtls] = useState([]);
  const [loading, setLoading] = useState(true);
  const[initMV,setInitMV]=useState(0);
  const[initCash,setInitCash]=useState(0);
  const[initExCash,setInitExCash]=useState(0);
  const [session,setSession]=useState("");
  const navigate = useNavigate();
  const [performDtls, setPerformDtls] = useState([]);
  var tempToken = JSON.parse(localStorage.getItem('token'));
 
  useEffect(() => {
    const fetchData = async () => {
         setLoading(true);
        try {
            //let data = location.state;

            let email = JSON.parse(localStorage.getItem('email'));// data.Email;
           
            setEmail(email);
            GetDefaultData();
          
            //  console.log(data);
        } catch (error) {
            console.error(error.message);
        }

    }
    fetchData();
}, [])
const refreshToken = async () => {
  let token = JSON.parse(localStorage.getItem('token'));
  let AccessToken = token.token;
  let RefreshToken = token.refreshToken;
  const postData = { AccessToken, RefreshToken };
  const config = {
    headers: {
      'authorization': `Bearer ${token.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

  };
  await axios.post('/token/Refresh',
    postData

  )
    .then((response) => {

      tempToken = response.data;
      localStorage.setItem('token', JSON.stringify(response.data));

      GetDefaultData();

    })
    .catch((error) => {
      // debugger;
      if(error.code==="ERR_BAD_REQUEST"){
        setSession("Session Expired");
      }
      console.log("my error is " + error);
    })

}
const GetDefaultData = async () => {
  setLoading(true);
 
   //let token = JSON.parse(localStorage.getItem('token'));
   let token = tempToken;
   let check=false;
   debugger;
   let contactId;
   let roleId;
   let selAcctId
  //let token.token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoic2Fpa2F0Z0BmaS10ZWsuY28uaW4iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJNYW5hZ2VyIiwiZXhwIjoxNjY4NDM0MTAyLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo3MDc1LyIsImF1ZCI6Imh0dHBzOi8vYmlkZW1vLmZpLXRlay5jby5pbi8ifQ.8lOFw9Vqy6F3KeicSNxp2CmSRfy2KhLs2s6DPgC0eaA";
  let EmailAdrs=JSON.parse(localStorage.getItem('email'));
   const postData = {EmailAdrs};
   
   const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
      //  'authorization': `Bearer ${token.refreshToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    
};
  await axios.post('/RTGetDefault',
      postData,
     config
  )
      .then(response => {

          //  console.log(Getdefault);
          debugger;
          const rowData = response.data;
           contactId= rowData.contact[0].cntctId;
           roleId= rowData.role[0].roleTypId;
          let acctId=rowData.accountList[0].acctId;

          let owner = rowData.accountList[0].owner;
          let invOfficer = rowData.accountList[0].invOfficer;
          let administrator = rowData.accountList[0].administrator;
localStorage.setItem('modelList',JSON.stringify(rowData.modelList))
          localStorage.setItem('owner', owner);
          localStorage.setItem('invOfficer', invOfficer);
          localStorage.setItem('administrator', administrator);
debugger;
          let procDt = new Date(rowData.procDate);
          let procDtOp = new Date(rowData.procDateOperation)

          let pdt=(procDt.getMonth()+1).toString().padStart(2, '0') + "/" + (procDt.getDate().toString().padStart(2, '0'))+"/"+(procDt.getFullYear());
          let pdt2=(procDtOp.getMonth()+1).toString().padStart(2, '0') + "/" + (procDtOp.getDate().toString().padStart(2, '0'))+"/"+(procDtOp.getFullYear());
          
         
          //localStorage.setItem('processingDate', procDt);
          localStorage.setItem('processingDate', pdt2);
          //localStorage.setItem('processingDateOperation', procDtOp);
          localStorage.setItem('processingDateOperation', pdt);
          localStorage.setItem('ShwHdeExcldAstVerbag', rowData.hideExcldAstVerbiage);
          const initialDropdown = {
            acctId: acctId,
            extrnlAcctId: rowData.accountList[0].extrnlAcctId
        };
        //debugger;
       
        if (localStorage.getItem("AcctSelected") === null || localStorage.getItem("AcctSelected") === 'null') {
          localStorage.setItem("AcctSelected",JSON.stringify(initialDropdown))
        }
      
         selAcctId=JSON.parse(localStorage.getItem('AcctSelected')).acctId;
        
          setroleGet(roleId);
          setcntctId(contactId);
        
           setAccountGet(rowData.accountList);
         localStorage.setItem('acctData', JSON.stringify(rowData.accountList));
          localStorage.setItem('userId', contactId);
          localStorage.setItem('roleId', roleId);
          check=true;
          

         // setRowGet(rowData);

        //  setLoading(false);

      })
      .catch((error) => {
        debugger;
        if (error.response.status === 401)
        {
          debugger;
          refreshToken();
         // GetDefaultData();
          

        }

          return error;
      });
if(check){
  GetADVAccountProfile(roleId,contactId,selAcctId);
}
}

const GetADVAccountProfile = async (roleId,cntctId,acctId) => {
 // setLoading(true);
 debugger;
   let token = JSON.parse(localStorage.getItem('token'));
  let RoleTypId=roleId;
  let UserId=cntctId;
  let AcctId=acctId;
  let EmailAdrs=JSON.parse(localStorage.getItem('email'));
   const postData = {UserId,RoleTypId,AcctId};
   const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    
};
  await axios.post('/AdvAccountProfile',
      postData,
     config
  )
      .then(response => {
     
          //  console.log(response);

          const rowData = response.data;
      
        
         // setAccountGet(rowData.t1);
        //  localStorage.setItem('acctData', JSON.stringify(rowData.t1));
        //debugger;
          let mjrData=Enumerable.from(rowData.t2).where(w => w.mvPercent !== 0)
          .toArray();
          let mnrData=Enumerable.from(rowData.t3).where(w => w.mvPercent !== 0)
          .toArray();
          let assetData=Enumerable.from(rowData.t4).where(w => w.mvPercent !== 0)
          .toArray();
          debugger;
          //let templateData=Enumerable.from(rowData.t8).toArray();
          // let rptList=Enumerable.from(rowData.t9).toArray();

        //debugger;
         let cash=rowData.t1[0]===undefined?0:rowData.t1[0].availableCash;
         let mv=rowData.t1[0]===undefined?0:rowData.t1[0].mrktVlAmt;
         let exCash=rowData.t1[0]===undefined?0:rowData.t1[0].excludedCash;
          setMjrAcctDtls(mjrData);
          setMnrAcctDtls(mnrData);
          setAssetDtls(assetData);          
         setInitCash(cash);
         setInitMV(mv);
         setInitExCash(exCash);
       
        setPerformDtls(rowData.t7)
     
          setLoading(false);

      })
      .catch((error) => {

          return error;
      });

}
const signOut = () => {
    
  navigate("/");
  let token = JSON.parse(localStorage.getItem('token'));
  const postData = {};
  const config = {
    headers: {
      'authorization': `Bearer ${token.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

  };
  axios.post('/token/revoke',
  postData,
  config
  )
    .then((response) => {
    // debugger;
    // localStorage.setItem('token', '');
     //console.log(response);
    // navigate("/");
        
      // if (response.statusText === '') {
       


      // }

     
    })
    .catch((error) => {
      // debugger;
      console.log("my error is " + error);
    })

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem('token');
    localStorage.clear();
  //firebaseApp.auth.signOut();

}
if (loading) {
  return (<>
    {session==="Session Expired"?
    
    <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
  :
  <Loading />}</>)
}

  return (
    <div>
      <Header></Header>
      <DashboardDetails data={accountGet} mjrData={mjrAcctDtls} mnrData={mnrAcctDtls} astData={assetDtls} performDtls={performDtls}/>
     {/* <BerryDash data={accountGet} mjrData={mjrAcctDtls} mnrData={mnrAcctDtls} astData={assetDtls} initMV={initMV} initCash={initCash} initExCash={initExCash} performDtls={performDtls}/> */}

     
    </div>
  )
}

export default Dashboard
