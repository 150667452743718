import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {sectorCmprsnDatavar} from './cstmRptMain';
import { ResponsiveContainer } from 'recharts'



const SectorComparisionGridWidget = () => {
  debugger;
  const [sectCompData, setsectCompData] = useState(sectorCmprsnDatavar);//change Data!!!!!!!!!!!
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const createDataState = (dataState) => {
    return {
      result: process(sectCompData.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [sort, setSort] = React.useState([]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}
const NumberCellSixDecimal = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.000000")}
        </td>
    )
}

const totalAcVMdl = (props) => {
  const field = props.field || "";
  const total = sectCompData.reduce((acc, current) => acc + current[field], 0);
  return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, "##,#.00")}
      </td>
  );
};
  
  return (<><h5 className='text-fitek p-1'>Sectors Comparison


  </h5>      
    <ResponsiveContainer>
    <Grid style={{width:"100%" ,height: "100%" }}
    id='tileGrid'
                                    data={orderBy(sectCompData,sort)}
                                    onDataStateChange={dataStateChange}
                                    {...dataState}
                                //sortable={true}
                                sort={sort}
                                //onSortChange={(e) => {
                                // setSort(e.sort);
                                //}}
                                className='page-break'
                                >

                                    <Column field="groupHeader" menu={true} title="Description" width="auto" />
                                    <Column field="marketPercent" menu={true} title="Portfolio Holdings (%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} />
                                    <Column field="bmPcnt" menu={true} title="Model Weighting (%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell}  />

                                    <Column field="varBMPcnt" menu={true} title="Variance to Model (%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} />

                                    <Column field="pcntOfBMPcnt" menu={true} title="% of Model Weightings" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} />
                                    </Grid>
                                    </ResponsiveContainer></>
     
  )
}





export default SectorComparisionGridWidget