import React, { useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Enumerable from 'linq';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBookOpen, FaEye, FaPlus,FaBars } from 'react-icons/fa';
import {FiEdit} from 'react-icons/fi';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import TextField from '@mui/material/TextField';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import EditCstmRprtForm from "./editCstmDshBrdRecrd";
import { height } from '@mui/system';
//import {HiViewColumns} from 'react-icons/Hi';
import {AiOutlineFundView} from 'react-icons/ai';
const EditCommandCell = (props) => {
  return (
    <td>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" title='Edit'
        onClick={() => props.enterEdit(props.dataItem)}
      >
        <FiEdit></FiEdit>
      </button>
    </td>
  );
};

const CustomDashboardGrd = ({ tmplRecords, rptListQual }) => {

  const TmpltNmRegex = new RegExp(/[~`!#$%\^&*+=\-\[\]\\;,/{}|\\":<>\?]/g);
  const navigate = useNavigate();
  const [valdMsg, setvaldMsg] = React.useState("");
  const [valdMsg2, setvaldMsg2] = React.useState("");
  const [rptCountMsg, setrptCountMsg] = React.useState("");
  const [tmpltDetails, SetTmpltDetails] = React.useState(tmplRecords);
  const [show, setShow] = React.useState(false);
  const [rptIds, setRptIds] = React.useState([]);
  const [tnm, SetTemplateNm] = React.useState("");
  const [tdsc, SetTemplateDesc] = React.useState("");
  const [btnStatus, setBtnStatus] = React.useState(true);
  const [btnStatus2, setBtnStatus2] = React.useState(true);
  const [btnStatus3, setBtnStatus3] = React.useState(true);
  const [rptList, setRptList] = React.useState(rptListQual);
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    templateId: 0,
  });
  var tempToken = JSON.parse(localStorage.getItem('token'));

  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(tmpltDetails, initialDataState)
  );

  const OpenCstmRptPage = (tid, tnm) => {
    localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(tid));
    console.log(tnm);
    localStorage.setItem("tmpltNm", tnm);
    navigate("/cstmRptMain");
  }

  const onDataStateChangeCustom = React.useCallback((e) => {
    debugger;
    setDataState(e.dataState);
    setResult(process(tmplRecords, e.dataState));
  }, []);
  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "") {
        let tID = cellProps.dataItem["templateId"];
        let tNm = cellProps.dataItem["templateNm"];
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <button className="btn btn-sm btn-outline-primary" title='View' onClick={() => OpenCstmRptPage(tID, tNm)}><FaBars/> </button>
          </td>
        );
      }
    }
    return tdElement;
  };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getRptIDs = (e) => {
    debugger;
    const { value, checked } = e.target;
    if (e.target.checked) {
      setRptIds([...rptIds, value])
    }
    else {
      setRptIds(rptIds.filter((e) => e !== value));
    }
    console.log(`${rptIds} is ${checked}`);

    const elements = document.getElementsByName('checkbox');
    let checkedCount = 0;
    elements.forEach((element) => {
      if (element.checked) {
        checkedCount++;
      }
    })
    if (checkedCount >= 1 && checkedCount<=5) {
      setrptCountMsg("");
      setBtnStatus(false)
    } else {
      setrptCountMsg(checkedCount<1?"Please select atleast one report.":"Please select maximum five reports at a time.")
      setBtnStatus(true)
    }

  }
  const handleChangeTnm = (e) => {
    debugger;
    SetTemplateNm(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);
    debugger;
    if (!isValid) {
      setBtnStatus2(true);
      setvaldMsg("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus2(true);
      setvaldMsg("Please Enter Template Name.");
    }

    if (isValid && e.target.value !== "") {
      setBtnStatus2(false);
      setvaldMsg("");
    }
  }
  const handleChangeTdesc = (e) => {
    SetTemplateDesc(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);

    if (!isValid) {
      setBtnStatus3(true);
      setvaldMsg2("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus3(true);
      setvaldMsg2("Please Enter Template Description.");
    }
    if (isValid && e.target.value !== "") {
      setBtnStatus3(false);
      setvaldMsg2("");
    }

  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData
    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        saveNewTemplate();

      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

  }
  const saveNewTemplate = async () => {
    debugger;
    let idXml = '<Root>';
    idXml = idXml + rptIds.map(function (val) {
      return val !== ',' ? '<Records><ReportId>' + val + '</ReportId></Records>' : '';
    })
    idXml = idXml + '</Root>'
    let RptIds = idXml;


    let TemplateNm = tnm;
    let TemplateDesc = tdsc;
    localStorage.setItem("tmpltNm", tnm);
    let UserId = JSON.parse(localStorage.getItem('userId'));
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    const data = { UserId, TemplateNm, TemplateDesc, RptIds };

    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };

    await axios.post('TemplateProfile/TemplateProfile', data, config)
      .then((response) => {

        console.log(response);

        if (response.statusText === 'OK' || response.statusText === '') {
          localStorage.setItem("tmpltNm", tnm);
          setRptIds([]);
          SetTmpltDetails(response.data.ocTemplateProfile);
          debugger;
          if (response.data.saveTemplateId !== 0) {
            localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(response.data.saveTemplateId));
            navigate("/cstmRptMain");
          }
        }

      })
      .catch((error) => {
        debugger;
        if (error.response.status === 401) {
          refreshToken();


        }
        console.log("my error is " + error);
      })

    handleClose();
  }
  const UpdateTmplt = async () => {
    debugger;
    let token = tempToken;
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    let TemplateId = localStorage.getItem('tIdForUpdt');
    let TemplateNm = localStorage.getItem('tNmForUpdt');
    let TemplateDesc = localStorage.getItem('tDescForUpdt');
    let InsrtUpdtInd = 1;
    let UserId = JSON.parse(localStorage.getItem('userId'));
    const postData = { TemplateId, TemplateNm, TemplateDesc, UserId, InsrtUpdtInd };

    await axios.post('TemplateProfile/UpdateTemplate', postData, config)
      .then(response => {
        debugger;
        const rowData = response.data;
        const templateData = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        SetTmpltDetails(rowData.ocTmpltDetailsT);
        setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // setRptList(rowData.oCQualRptLstT);                
      })
      .catch((error) => {
        debugger;
        if (error.response.status === 401) {
          refreshTokenForUpdt();


        }
        console.log("my error is " + error);
      })
  }
  const refreshTokenForUpdt = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData
    )
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        UpdateTmplt();

      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

  }
  const DateCell = (props) => {

    if (props.field === "crtDt") {
      let cdt = new Date(props.dataItem["crtDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
    if (props.field === "modDt") {
      let cdt = new Date(props.dataItem["modDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
  }
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const enterEdit = (item) => {
    debugger;
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    debugger;
    localStorage.setItem('tIdForUpdt', event.templateId);
    localStorage.setItem('tNmForUpdt', event.templateNm);
    localStorage.setItem('tDescForUpdt', event.templateDesc);
    UpdateTmplt();
    // window.location.reload(false)
    console.log("template updated");
    setOpenForm(false);
  };
  const handleCancelEdit = () => {
    console.log("template update cancelled");
    setOpenForm(false);
  };
  return (

    <div>
      <div className='row d-flex justify-content-start align-items-center card-header tableheader py-2 mb-2 h6'>
<div className='text-start'>Custom Report</div>
      <div className="text-end">
              <button
                className="btn btn-primary  btn-sm" onClick={handleShow}>
                <FaPlus></FaPlus> Add New
              </button>
            </div></div>
      <div className='row my-2'>
        <div className="col col-md-12 col-sm-12 py-2">
          {/* <div className="card-header tableheader py-1 mb-2">Custom Report</div> */}
          <div className='mx-1 px-1'>
            <Grid style={{ height: "450px" }}

              //data={resultState.slice(page.skip, page.skip + page.take)}
              // groupable={{
              //   footer: "visible",
              // }}
              data={result}
              resizable={true}
              reorderable={true}
              sortable={true}
              onDataStateChange={onDataStateChangeCustom}
              {...dataState}
              cellRender={cellRender}
            >
              <Column field='templateNm' menu={true} title="Template Name" width="auto" columnMenu={ColumnMenu} />
              <Column field='templateDesc' menu={true} title="Description" width="auto" columnMenu={ColumnMenu} />
              <Column field='crtdUserNm' menu={true} title="Created By" width="auto" columnMenu={ColumnMenu} />
              <Column field='crtDt' menu={true} title="Created Date" cell={DateCell} width="auto" columnMenu={ColumnMenu} />
              <Column field='modUserNm' menu={true} title="Modified By" width="auto" columnMenu={ColumnMenu} />
              <Column field='modDt' menu={true} title="Modified Date" cell={DateCell} width="auto" columnMenu={ColumnMenu} />
              <Column field='' menu={true}  width="85px" />
              <Column cell={MyEditCommandCell}  width="100px" />
            </Grid>
            {openForm && (
              <EditCstmRprtForm
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
              />
            )}
          </div>
          <div className="card-footer mx-1 my-2">
            
            <div className="col col-md-4 col-sm-4 py-1"></div>
          </div>
          <div className="row mx-1 my-2">
            <div className="form">
              <div className="input-container">
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title> <div className="card-header tableheader">New Template</div></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <div className='input-group pb-2 pt-2'>

                      <TextField
                        required className='form-control' name="tname"
                        variant="outlined" label='Template Name' size="small" onChange={handleChangeTnm}></TextField>
                    </div>
                    <div style={{ color: "red" }}>
                      {valdMsg}
                    </div>
                    <div className='input-group pb-2 pt-2'>
                      <TextField
                        required className='form-control' name="tdesc"
                        variant="outlined" label='Template Desc' size="small" onChange={handleChangeTdesc}></TextField>
                    </div>
                    <div style={{ color: "red" }}>
                      {valdMsg2}
                    </div>
                    <div className="card-header tableheader">Select Reports</div>
                    <ul className="toppings-list" style={{ overflowY: 'scroll', height: '350px' }}>
                      {rptList.map(({ reportId, reportNm }, index) => {

                        return (

                          <li key={index}>
                            
                            <div className="toppings-list-item">
                               
                               <div className="left-section">
                                 <input
                                   type="checkbox"
                                   id={`custom-checkbox-${index}`}
                                   //name={reportNm}
                                   name="checkbox"
                                   value={reportId}
                                   onClick={getRptIDs}
                                 />
                                 <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                               </div>
                             </div>
                            {/* {reportId <= 10 ?  
                              <div>
                                {reportId === 1 ?
                                    <div style={{ marginTop: ".5rem", paddingTop: ".5rem" }}>Accounts<hr/></div>
                                    : ""}

                                <div className="toppings-list-item">
                               
                                  <div className="left-section">
                                    <input
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      //name={reportNm}
                                      name="checkbox"
                                      value={reportId}
                                      onClick={getRptIDs}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                  </div>
                                </div>

                              </div>

                              : reportId > 10 && reportId <= 15 ?
                                <div>
                                  {reportId === 11 ?
                                    <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>Performence <hr/></div>
                                    : ""}

                                  <div className="toppings-list-item ">
                                    <div className="left-section" >

                                      <input
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        //name={reportNm}
                                        name="checkbox"
                                        value={reportId}
                                        onClick={getRptIDs}
                                      />
                                      <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                    </div>
                                  </div>
                                </div>

                                : reportId > 15 && reportId <=20 ?
                                  <div>
                                    {reportId === 16 ?
                                      <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>Fixed income<hr/></div>
                                      : ""}

                                    <div className="toppings-list-item ">
                                      <div className="left-section" >

                                        <input
                                          type="checkbox"
                                          id={`custom-checkbox-${index}`}
                                          //name={reportNm}
                                          name="checkbox"
                                          value={reportId}
                                          onClick={getRptIDs}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                      </div>
                                    </div>
                                  </div>

                                  : ""
                            } */}

                          </li>
                        );
                      })}
                    </ul>

                  </Modal.Body>
                  <Modal.Footer>
                    <p>{btnStatus ? <span style={{ color: '#ff1744' }}>{rptCountMsg}</span> : <></>}</p>&nbsp;
                    {/* <p>{btnStatus2? <span style={{ color: '#ff1744' }}>{valdMsg}</span> : <></>}</p>
                    <p>{btnStatus3? <span style={{ color: '#ff1744' }}>{valdMsg2}</span> : <></>}</p> */}
                    <button className="btn btn-primary  btn-sm" disabled={btnStatus || btnStatus2 || btnStatus3} onClick={saveNewTemplate}>
                      Save Changes
                    </button>

                    <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                      Close
                    </button>


                  </Modal.Footer>

                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )


}
export default CustomDashboardGrd