import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import Header  from './header';
import FixdIncmMaturityLadrGrid from './fixdIncmMtrtyLadrGrd';
import "@progress/kendo-theme-material/dist/all.css";
import FixdIncmMaturityLadrGrd from './fixdIncmMtrtyLadrGrd';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const FixdIncmMaturityLadrRpt = () => {
    const [fimlRptMainDataSet, populateFimlRptMainDataSet] = useState([]);
    const [fimlRptCalDtls, populateFimlRptSecondDataSet] = useState([]);
    const [fimlRptmatVsCallPut, populateFimlRptThirdDataSet] = useState([]);
    const [fimAcctList, populateFimAcctList] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected'))); 
    const [loading, setLoading] = useState(true);
    const [flag, setFlag]=useState(0);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const navigate = useNavigate();
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
            try {
                //let data = location.state;
    
                let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
               
                //setEmail(email);
                GetFixdIncmMaturityLadrRptData();
              
                //  console.log(data);
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, []);
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          debugger;
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetFixdIncmMaturityLadrRptData();
  
  
        })
        .catch((error) => {
          // debugger;
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }

    const GetFixdIncmMaturityLadrRptData = async () => {
        debugger;
        setLoading(true);
      
       //  let token = JSON.parse(localStorage.getItem('token'));
         let token=tempToken;
         let UserId = JSON.parse(localStorage.getItem('userId'));
        
         let lsSelectedAcct=JSON.parse(localStorage.getItem("AcctSelected"));
         let acctIds = 0;
         if(lsSelectedAcct!== null)
         {
            acctIds=lsSelectedAcct.acctId;
         }
         let PrstInd=0;
         let StrtDt="";
         let EndDt="";
         let AsOfDt=localStorage.getItem('processingDate');
         let PriceDt=localStorage.getItem('processingDate');
         let PriceFlag=1;
         let IsConsolidation=false;
         let ShowExcldAst=true;
         let PageId = 1;
         
         const postData = {UserId, acctIds, PrstInd, StrtDt, EndDt, AsOfDt, PriceDt, PriceFlag, IsConsolidation, ShowExcldAst, PageId};
         const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          
      };
        await axios.post('/RTFixdIncmMaturity',
            postData,
           config
        )
            .then(response => {
              
                //  console.log(response);
      debugger;
                const rowData = response.data;
                populateFimlRptMainDataSet(rowData.fIM1);
                populateFimlRptSecondDataSet(rowData.fIM2);
                populateFimlRptThirdDataSet(rowData.fIM3);
                populateFimAcctList(rowData.fIM4);
                setSelAcctData(rowData.fIM4);
                if(lsSelectedAcct!== null)
                {
                    SetselAcct(lsSelectedAcct);                 
                }
                else
                {
                    SetselAcct(rowData.fIM4[0]);
                }
                setLoading(false);
                setFlag(1);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                refreshToken();
      
      
              }
                //return error;
            });
      
      }

      const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // debugger;
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
    
    
            // }
    
           
          })
          .catch((error) => {
            // debugger;
            console.log("my error is " + error);
          })
    
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }
      const handleChange = (event) => {
        debugger;
                if (event.target.value === null) {
                  //SetselAcct('');
                  SetselAcct(selAcct);
                 // GetAcctProfileData();
                  // GetUpdatedAccountProfile(0);
                }
                else {
                  SetselAcct(event.target.value);
                  localStorage.setItem("AcctSelected",JSON.stringify(event.target.value));
                  GetFixdIncmMaturityLadrRptData(event.target.value);
                  //GetUpdatedAccountProfile(event.target.value.acctId);
                }
            
            
            
              };
      if (loading) {
        return(
            <>
             {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
              <Header></Header>
            <Loading />
            </div>}</>
          )
      }
  return (
    <div>
       <Header></Header>
       <div  className='my-1'>
      {/* <div className="rounded"></div> */}
       <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
       <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            // onFilterChange={filterChange}
          />
          </div><div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          </div>
          </div>
       {flag===1?
        <FixdIncmMaturityLadrGrd data={fimlRptMainDataSet} callDetails={fimlRptCalDtls} matVsCallPut={fimlRptmatVsCallPut}  />
        :<></>

       }
    </div>
  )
}

export default FixdIncmMaturityLadrRpt


