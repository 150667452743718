import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {portPerfBySecurityDatavar} from './cstmRptMain';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {ResponsiveContainer} from 'recharts';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
const aggregates = [
  {
    field: "endMkt",
    aggregate: "sum",
  },
  {
    field: "endMktPct",
    aggregate: "sum",
  },
  {
    field: "changedMktVal",
    aggregate: "sum",
  },
  {
    field: "percentageReturn",
    aggregate: "sum",
  },
  {
    field: "annualRtrn",
    aggregate: "sum",
  },
  {
    field: "totalreturn",
    aggregate: "sum",
  },
  {
    field: "percentageContribution",
    aggregate: "sum",
  }

];
const processWithGroups = (data, dataState) => {
  // debugger;
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PortPerfSecurityGridWidget = () => {
  debugger;
  const [data, setdata] = useState(portPerfBySecurityDatavar);//change Data!!!!!!!!!!!
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const totalSum = (props) => {
    const field = props.field || '';
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, '##,#.00')}
      </td>
    );
  };
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    
  };
  
  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };
  
  const addHiddenColumns = (columns) => {
    let newColumnsState = GridColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : '',
        };
      } else {
        return { ...col, show: false };
      }
    });
    newColumnsState[5].footerCell=totalSum ;
    newColumnsState[3].footerCell= totalSum;
    newColumnsState[4].footerCell= totalSum;
    
    newColumnsState[6].footerCell= totalSum;
    newColumnsState[8].footerCell= totalSum;
    return newColumnsState;
  };
  
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };
    let GridColumns = [
      // {
      //   title: 'Securities',
      //   field: 'mjrAstNm',
      //   minWidth: "auto",
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Minor Asset',
      //   field: 'mnrAstNm',
      //   minWidth: "auto",
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      {
        title: 'Asset',
        field: 'astShrtNm',
        minWidth: "auto",
        show: true,
        filter: 'text',
        locked: false,
      },
      {
        title: 'Dividend Or Yield',
        field: 'yield',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
    },
      {
        title: 'Ending Market Value',
        field: 'endMkt',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Percentage Portfolio',
        field: 'endMktPct',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Change In Market Value',
        field: 'changedMktVal',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Percentage Return',
        field: 'percentageReturn',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      
      {
          title: 'Annualized % Return',
          field: 'annualRtrn',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          //footerCell: totalSum,
          headerClassName: 'rightHeader',
        },
        {
          title: 'Total Return',
          field: 'totalreturn',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          //footerCell: totalSum,
          headerClassName: 'rightHeader',
        },
        {
          title: 'Percentage Contribution',
          field: 'percentageContribution',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          //footerCell: totalSum,
          headerClassName: 'rightHeader',
        }
        
        

    ];
    
   
    
    const [row, setRow] = useState(data);
    const createDataState = (dataState) => {
      return {
        result: process(data, dataState),
        dataState: dataState,
      };
    };

    let initialStateExport = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      group: [{field: 'mjrAstNm'},{field: 'mnrAstNm'}]
      //group: [{field: 'mjrAstNm'}]
    });
    let initialState = createDataState({
      take: 20,
      skip: 0,
      group: [{field: 'mjrAstNm'},{field: 'mnrAstNm'}]
      //group: [{field: 'mjrAstNm'}]
    });
    const [result, setResult] = React.useState(
      processWithGroups(data, initialState.dataState)
    );
    const [resultExport, setResultExport] = React.useState(
      processWithGroups(data, initialStateExport.dataState)
    );
  
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [stateColumns, setStateColumns] = React.useState(GridColumns);
    const [currentColumns, setCurrentColumns] = React.useState(GridColumns);
    GridColumns[3].footerCell= totalSum;
    GridColumns[4].footerCell= totalSum;
    GridColumns[5].footerCell= totalSum;
    GridColumns[6].footerCell= totalSum;
    GridColumns[8].footerCell= totalSum;

    const dataStateChange = (event) => {
      let updatedState = createDataState(event.dataState);
      let updatedStateExcel = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
        sort: event.dataState.sort,
        group: event.dataState.group,
    
      });
      setResult(processWithGroups(data, updatedState.dataState));
      setResultExport(processWithGroups(data, updatedStateExcel.dataState));
      setDataState(updatedState.dataState);
    };
    
    const setWidth = (minWidth) => {
      let width = minWidth;
      return width;
    };
    const expandChange = (event) => {
      
      const isExpanded =
        event.dataItem.expanded === undefined
          ? event.dataItem.aggregates
          : event.dataItem.expanded;
      event.dataItem.expanded = !isExpanded;
      setResult({ ...result });
    };
    const getCells = (columns, cellProps) => {
      let cells = [];
      columns.forEach((column) => {
        if (column.aggregate) {
          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
            </td>
          );
        } else {
          cells.push(<td>&nbsp;</td>);
        }
      });
      return cells;
    };

    
    const cellRender = (tdElement, cellProps) => {
      
      // if (
      //   cellProps.rowType === 'groupHeader' &&
      //   tdElement &&
      //   tdElement.props.role != 'presentation'
      // ) {
        
      //   //const columns = columns;
      //   const columns = [
      //     { field: 'mjrAstNm'},
      //     { field: 'mnrAstNm'},
      //     { field: 'astShrtNm'},
      //     { field: 'yield'},
      //     { field: 'endMktVal', aggregate: 'sum' },
      //     { field: 'endMktPct', aggregate: 'sum' },
      //     { field: 'changedMktVal', aggregate: 'sum' },
                 
      //     { field: 'percentageReturn', aggregate: 'sum' },
          
      //     { field: 'annualRtrn', aggregate: 'sum'  },
      //     { field: 'totalreturn', aggregate: 'sum'  },
      //     { field: 'percentageContribution', aggregate: 'sum'  },
          
  
      //   ];
      //   return (
      //     <>
      //       <td
      //         {...tdElement.props}
      //         colSpan={tdElement.props.colSpan - columns.length}
      //       ></td>
      //       {getCells(columns, cellProps)}
      //     </>
      //   );
      // }
      if (cellProps.rowType === 'groupFooter') {
  
        if (cellProps.field === 'endMkt') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.endMkt.sum, "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'endMktPct') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.endMktPct.sum, "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'changedMktVal') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.changedMktVal.sum, "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'percentageReturn') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.percentageReturn.sum,"##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'annualRtrn') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.annualRtrn.sum,"##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'totalreturn') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.totalreturn.sum, "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === 'percentageContribution') {
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
              {formatNumber(cellProps.dataItem.aggregates.percentageContribution.sum, "##,#.00")}
            </td>
          );
        }
        
      }
      if (cellProps.rowType === "data") {
  
        if (cellProps.field === "endMkt") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "endMktPct") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "changedMktVal") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "percentageReturn") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "yield") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "annualRtrn") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "totalreturn") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "percentageContribution") {
  
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
      }
      return tdElement;
    };
  
  return (
    <ResponsiveContainer>
      <div><h5 className='text-fitek p-1'>Portfolio Performance By Security Report</h5>
    <Grid className='page-break'
    id='tileGrid'
              style={{ height: "auto" }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              //onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
              expandField="expanded"
              //={expandChange}
              cellRender={cellRender}
              //sortable={true}
              resizable={true}
              //pageable={true}
              //reorderable={true}
             
               //pageSize={10}

              groupable={{
                footer: 'visible',
                enabled: false
              }}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      {...column}
                      //cell={NumberCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenuNoGrp
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
            </div>
            </ResponsiveContainer>
  )
}





export default PortPerfSecurityGridWidget