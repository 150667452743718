import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { filterBy } from '@progress/kendo-data-query';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
  } from "@progress/kendo-react-dropdowns";
import Loading from './loading';
import Header  from './header';
import PortPerfSecurityGrid from './portPerfSecurityGrid';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const PortPerfSecurityRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [portPerfSecurityData, setPortPerfSecurityData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const[flagPortPerfSec, setPortPerfSec] = React.useState(false);
    const [isColumnSave, setIsColumnSave] = useState(false);

    const[isDisabled,setisDisabled]= React.useState(false);
    var date = new Date(localStorage.getItem('processingDate')); 
    date.setMonth(date.getMonth()-12);
    const [frmDate, setfrmDate] = React.useState(date);
    const [toDate, setToDate] = React.useState(new Date(localStorage.getItem('processingDate')));
    const[flag,setFlag]= React.useState(false);
    var minDate = new Date(localStorage.getItem('processingDate'));
    minDate.setMonth(minDate.getMonth()-24);
    var maxDate = new Date(localStorage.getItem('processingDate'));
    //maxDate.setMonth(maxDate.getMonth());
    const[minFrmDt, setminFrmDt]=React.useState(minDate);
    const[maxFrmDt, setmaxFrmDt]=React.useState(maxDate);

    var minToDate = new Date(localStorage.getItem('processingDate'));
    minToDate.setMonth(minToDate.getMonth()-12);
    var maxToDate = new Date(localStorage.getItem('processingDate'));
    const [session,setSession]=useState("");
    const navigate = useNavigate();

    const getColumnStateDb = async () =>{
      //Storing column settings in DB
      debugger;
        let token = JSON.parse(localStorage.getItem('token'));
        let UserId = JSON.parse(localStorage.getItem('userId'));
        let GridId = 28;//let 1 for Portfoliholdings Grid
        
        const postData = {UserId, GridId};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      
        };
        await axios.post('/RTGetGridColumn/Index',
          postData,
          config
        )
          .then(response => {
            debugger;
            console.log('save in db');
            console.log(response);
              const rowData = response.data;
              debugger;
              if(rowData!==""){
                setIsColumnSave(true);
                localStorage.setItem('gridColumns', rowData);
              }
              else{
                setIsColumnSave(false);
              }
          })
          .catch((error) => {
            console.log('error in save db '+error);
            
              return error;
          });
      }
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
            try {

                GetPortPerfSecurityData();
                getColumnStateDb();
                
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, [])

    function setDate (val){
      debugger;
      //setfrmDate(val);
      if (val == null)
      {
        setfrmDate(frmDate);
      }
      else
      {
        setfrmDate(val);
      }
      
  }
  function setTDate (val){
    debugger;
    if (val == null)
    {
      setToDate(toDate);
    }
    else
    {
    setToDate(val);
    }
    
}
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          debugger;
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetPortPerfSecurityData();
  
  
        })
        .catch((error) => {
          // debugger;
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }
    const formSubmit=(event)=>{
      GetPortPerfSecurityData();
    }
    const GetPortPerfSecurityData = async () => {
        debugger;
        setLoading(true);
        let token=tempToken;
         //let token = JSON.parse(localStorage.getItem('token'));
         let AsOfId = JSON.parse(localStorage.getItem('userId'));
         let AcctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
         let PageId = 1;
         let StartDt=frmDate;
         let EndDt=toDate;
         const postData = {AsOfId,AcctId, PageId,StartDt,EndDt};
         const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          
      };
        await axios.post('/RTPortPerfBySecurity',
            postData,
           config
        )
            .then(response => {
              debugger;
            //    const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0)
            //     .toArray();
    
                const rowData = response.data;
                setPortPerfSecurityData(rowData.t1);
                localStorage.setItem("filterData",JSON.stringify(rowData.t1) );
                getColumnStateDb();
                setPortPerfSec(true);
                setLoading(false);
      
            })
            .catch((error) => {
              if (error.response.status === 401) {
                refreshToken();
      
              }
            });
      
      }
      const filterData = (filter) => {
   
        // const dataAcct = selAcctData.slice();
         return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
       };
      const filterChange = (event) => {
     
        setSelAcctData(filterData(event.filter));
      };
      const handleChange = (event) => {
        debugger;
          if (event.target.value === null) {
            SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
            
            localStorage.setItem('IsAcctSelected', false);
            localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
            }
          else {
            SetselAcct(event.target.value);
            localStorage.setItem('IsAcctSelected', true);
            localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
            console.log(event.target.value);
            GetPortPerfSecurityData();
            
          }
            
        };

        const signOut = () => {
    
          navigate("/");
          let token = JSON.parse(localStorage.getItem('token'));
          const postData = {};
          const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
      
          };
          axios.post('/token/revoke',
          postData,
          config
          )
            .then((response) => {
            // debugger;
            // localStorage.setItem('token', '');
             //console.log(response);
            // navigate("/");
                
              // if (response.statusText === '') {
               
      
      
              // }
      
             
            })
            .catch((error) => {
              // debugger;
              console.log("my error is " + error);
            })
      
            // let tokenNew={token:''};
            // localStorage.setItem('token',JSON.stringify(tokenNew));
            // localStorage.setItem("AcctSelected",null);
            localStorage.removeItem('token');
            localStorage.clear();
          //firebaseApp.auth.signOut();
      
        }
        if (loading) {
  
            return(
              <>
               {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
                <Header></Header>
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-3 col-lg-4 col-sm-10 text-start'>
                        <span className='px-2'>Account(s)</span>
                    <ComboBox
                      style={{
                        width: "350px",
                      }}
                      data={selAcctData}
                      textField="extrnlAcctId"
                      dataItemKey="acctId"
                      filterable={true}
                      value={selAcct}
                      onChange={handleChange}
                      onFilterChange={filterChange}
                    />
                  </div>
                  <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              min={minFrmDt}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //disabled={true}
              defaultValue={toDate}
              format="MM/dd/yyyy"
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              min={minToDate}
              max={maxToDate}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <button className='btn btn-primary btn-sm' onClick={GetPortPerfSecurityData}>Submit</button>

        </div>
                  {/* <div className='col-md-2'>
                    <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
                  </div> */}
                  </div>
              <Loading />
              </div>}</>
            )
           
         
        
        }
          return (
              <div>
                <Header></Header>
                <form onSubmit={formSubmit}  >
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-3 col-lg-3 col-sm-10 text-start'>
                        <span className='px-2'>Account(s)</span>
                      <ComboBox
                          style={{
                              width: "350px",
                          }}
                          data={selAcctData}
                          textField="extrnlAcctId"
                          dataItemKey="acctId"
                          filterable={true}
                          value={selAcct}
                          onChange={handleChange}
                          onFilterChange={filterChange}
                      />
                  </div> 
                  {/*Search*/ }
                 
          <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              min={minFrmDt}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //disabled={true}
              defaultValue={toDate}
              format="MM/dd/yyyy"
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              min={minToDate}
              max={maxToDate}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit" className='btn btn-primary btn-sm'value="Submit"/> 

        </div>
        {/*Search end*/ }
                  
                  </div>

                  {/* <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Portfolio Performance By Security Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //disabled={true}
              defaultValue={toDate}
              format="MM/dd/yyyy"
              min={minToDate}
              max={maxToDate}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <button className='btn btn-primary btn-sm' onClick={GetPortPerfSecurityData}>Submit</button>

        </div>

      </div> */}
</form>
                  <div>
                  {flagPortPerfSec?<PortPerfSecurityGrid data={portPerfSecurityData} flag={isColumnSave} accntSelected={selAcct}/>:<></>}
                  </div>
                
              </div>
            )
}
export default PortPerfSecurityRpt;