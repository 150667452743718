import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {fimMaturityLadrCallDetails} from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';

 const initialDataState = {
    skip: 0,
  take: 10,
  };

const FimLadderCallPutDetailsWidget = () => {
  debugger;
  const RightNameHeader = (props) => {
    return (
        <a className="k-link" style={{
            float: "right",
        }} onClick={props.onClick}>
            {/* <span className="k-icon k-i-cart" /> */}
            <span
                style={{
                    // color: "#53d2fa",
                }}
            >
                {props.title}
            </span>
            {props.children}
        </a>
    );
};
  const columns = [
    {
      title: 'Based On First Call',
      field: 'callOrPutYr',
      minWidth: "auto",
      show: true,
      filter: 'text',
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      headerCell: RightNameHeader,
    },
    {
      title: 'Market Value',
      field: 'market',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
      headerCell: RightNameHeader,
    },
    {
      title: 'Income',
      field: 'income',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Yield%',
      field: 'yield',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Percent',
      field: 'marketPercent',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    }
  ];

  const [stateColumns, setStateColumns] = React.useState(columns);
  
  const [row, setRow] = useState(fimMaturityLadrCallDetails);
  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React.useState(initialDataState);
  //setResultState(process({data}, initialDataState))
  
  const [page, setPage] = React.useState(initialDataState);

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  
  const onDataStateChange = React.useCallback((e) => { 
   setResultState(e.resultState);
   setDataState(e.dataState);
  }, []);

  const cellRender = (tdElement, cellProps) => {        
    
    if (cellProps.rowType === "data")
    {

    if(cellProps.field==="shares")
      {
        return (
         
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.000000")}
          </td>
      );
      }
      if(cellProps.field==="market")
      {
        return (
          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>          
      );
      }
      if (cellProps.field === "income") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if(cellProps.field==="marketPercent")
      {
        //debugger;
        return (
          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>          
      );
      }
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    
    if(cellProps.field==="callOrPutYr")
    {
      return (
        <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          Callable In&nbsp;{ formatNumber(cellProps.dataItem[cellProps.field], "###")}
        </td>
    );
    }
  }

    if (cellProps.rowType === "groupFooter") {

      if (cellProps.field === "shares") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.shares.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "market") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.income.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.marketPercent.sum, "##,#.00")}
          </td>
        );
      }
      
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
           { formatNumber(cellProps.dataItem.aggregates.yield.average, "##,#.00")}
          </td>
        );
      }
      if(cellProps.field==="callOrPutYr")
    {
      return (
        <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          Total Fixed Income Portfolio
        </td>
    );
    }
    }

    return tdElement;
  };
  
  const pageChange = (event) => {
    setPage(event.page);
  };

  return (
    <div><h5 className='text-fitek p-1'>Call/Put Details
    </h5>
    <ResponsiveContainer className='page-break'>
      
        {/* <div className="container-fluid">
        <div className="row text-center">  */}
       <Grid style={{ height: 'auto' }}
            data={fimMaturityLadrCallDetails}
            // groupable={{
            //   footer: "visible",
            // }}
           
            //sortable={false}
            // skip={page.skip}
            //pageable={false}
            // pageSize={page.take}
            // total={data.length}
           
           // total={total}
           // filterable={true}
           resizable={false}
           //reorderable={false}
           onDataStateChange={onDataStateChange}
           {...dataState}
          //  onExpandChange={onExpandChange}
          //  expandField="expanded"
            cellRender={cellRender}
          >
           {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={false}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      // filter={column.filter}
                      footerCell={column.footerCell}
                      // cell={NumberCell}
                      headerCell={column.headerCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenu
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}          
          </Grid>
          {/* </div>
          </div> */}
         
          </ResponsiveContainer></div>
  )
}

export default FimLadderCallPutDetailsWidget
