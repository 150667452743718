import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenu } from './customColumnMenu';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ResponsiveContainer } from "recharts";
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {purchaseAndSaleData} from './cstmRptMain';
const aggregates = [
  {
    field: "proceeds",
    aggregate: "sum",
  },
  {
    field: "short",
    aggregate: "sum",
  },
  {
    field: "iLong",
    aggregate: "sum",
  },
  {
    field: "gainLoss",
    aggregate: "sum",
  }
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "10px",
          textAlign: "center",
          backgroundColor: "#f4f4f2",
        }}
      >
        <div className='fw-bold page-header table-header'>Demo Bank</div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};
const PrchsSaleWidget = () => {
  debugger;

  
  let defaultColumns = [
    {
      title: 'Date',
      field: 'trdDt',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: true,
    },
    {
      title: 'Purchase & Sell',
      field: 'buySell',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Security Description',
      field: 'astShrtNm',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares/Par Value',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Avg Price',
      field: 'avgPrice',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Proceeds',
      field: 'proceeds',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Short G/L',
      field: 'short',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Long G/L',
      field: 'iLong',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Gain Loss',
      field: 'gainLoss',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
  ];
 
  const GridColumns =  defaultColumns;
  debugger;
  //const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(purchaseAndSaleData, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 10,
    skip: 0,
    group: [{ field: 'buySell' }]
  });
  
  const [result, setResult] = React.useState(
    processWithGroups(purchaseAndSaleData, initialState.dataState)
  );
  

  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(processWithGroups(purchaseAndSaleData, updatedState.dataState));
   
    setDataState(updatedState.dataState);
  };

  
  const cellRender = (tdElement, cellProps) => {
    
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field === 'trdDt') {
        //debugger;
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
            Total
          </td>
        );
      }
      if (cellProps.field === 'proceeds') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }} >
            {formatNumber(cellProps.dataItem.aggregates.proceeds.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'short') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.short.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'iLong') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.iLong.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'gainLoss') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }} >
            {formatNumber(cellProps.dataItem.aggregates.gainLoss.sum, '##,#.00')}
          </td>
        );
      }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "shares") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "avgPrice") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "proceeds") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "short") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "iLong") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "gainLoss") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  
  return (

   
    <ResponsiveContainer >
      <div><h5 className='text-fitek p-1'>Purchase Sale</h5>
            <Grid
            id='tileGrid'
              style={{ width: '100%', height: "auto" }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
             // onExpandChange={expandChange}
              cellRender={cellRender}
              //sortable={true}
              // scrollable={true}
              resizable={true}
              //reorderable={true}
              //pageable={true}
              //pageSize={10}
              groupable={{
                footer: 'visible',
                enabled: false
              }}
              
              //
              //ref={gridRef.current}
             // onColumnReorder={onColumnReorder}
             // onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      // footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      {...column}
                    //   columnMenu={(props) => (
                    //     <CustomColumnMenuNoGrp
                    //       {...props}
                    //       columns={stateColumns}
                    //      // onColumnsSubmit={onColumnsSubmit}
                    //     />
                    //   )}
                    />
                  )
              )}
            </Grid>
            </div>
         
            </ResponsiveContainer >
  )
}

export default PrchsSaleWidget
