import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {transactionDatavar} from './cstmRptMain';
import { ResponsiveContainer } from 'recharts'
import { isVisible } from '@testing-library/user-event/dist/utils';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';

const aggregates = [
  {
    field: 'pCash',
    aggregate: 'sum',
  },
  {
    field: 'iCash',
    aggregate: 'sum',
  },
  {
    field: 'shares',
    aggregate: 'sum',
  },
];
const initialGroup = [
  {
    field: 'tranTypNm',
  },
];
const CustomGroupHeader = (props) => {
  debugger;
  return `${props.value}`;
};
const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};


const AcctTransactionGridWidget = ({preview}) => {
  const [data, SetData] = useState(transactionDatavar);
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const [locked, setLocked] = React.useState(false);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || '';
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, '##,#.00')}
      </td>
    );
  };
 

  const columns = [
    // {
    //   title: 'Branch',
    //   field: 'branchName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account#',
    //   field: 'accountName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Trans Type',
    //   field: 'tranTypNm',
    //   minWidth: "auto",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: 'Date',
      field: 'prcsDt',
      minWidth: "auto",
      show: true,
      filter: 'date',
      locked: false,
     
    },
    {
      title: 'Description',
      field: 'totalLine',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    {
      title: 'Principal($)',
      field: 'pCash',
      minWidth:"auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:"rightHeader"
      
    },
    {
      title: 'Income($)',
      field: 'iCash',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:"rightHeader"
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      format:"{0:d2}" ,
      locked: false,
      headerClassName:"rightHeader"
    },
    // {
    //   title: 'Created By',
    //   field: 'createdByInit',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Location',
    //   field: 'location',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Registration',
    //   field: 'registration',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Effective Date',
    //   field: formatDate('effectiveDate',"mdy"),
    //   minWidth: 150,
    //   show: true,
    //   locked: false,
    //   filter: 'date',
    // },
    // {
    //   title: 'Entry Date',
    //   field: formatDate('entryDate',"mdy"),
    //   minWidth: 150,
    //   show: true,
    //   locked: false,
    //   filter: 'date',
    // },
    // {
    //   title: 'GL Description',
    //   field: 'glDescription',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Long Name',
    //   field: 'longName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Payment Code',
    //   field: 'paymentCode',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Process Code',
    //   field: 'processCode',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    //   headerClassName:"rightHeader"
    // },
    // {
    //   title: 'RemittanceType',
    //   field: 'remittanceType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Sec Symbol',
    //   field: 'secSymbol',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Sequence Number',
    //   field: 'sequenceNumber',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName:"rightHeader"
    // },
    // {
    //   title: 'Vendor',
    //   field: 'vendorName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    // {
    //   title: 'Security',
    //   field: 'security',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
      
    // },
    {
      title: 'Trade Date',
      field: formatDate('tradeDate',"mdy"),
      minWidth: "auto",
      show: true,
      filter: 'date',
      locked: false,
      
    },
  ];

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [
      {
        field: 'tranTypNm',
      },
    ],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: 'tranTypNm' }]
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);
  const [currentColumns, setCurrentColumns] = React.useState(columns);
  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: Number.MAX_VALUE,//pageSize,
  });
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
  
    });
    setResult(processWithGroups(data, updatedState.dataState));
    //setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const handleColumnLockToggle = (columnField, state) => {
    let newColumns = currentColumns.map((column) => {
      if (column.field === columnField) {
        column.locked = state;
      }

      return column;
    });
    setCurrentColumns(newColumns);
  }; // place all locked columns first

  const expandChange = (event) => {
    
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.000000')}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const NumberCell = (props) => {
    debugger;
    if(props.field==='branchName'){
      return (
          <td style={{ textAlign: 'left' }}>
              {props.dataItem[props.field]}
          </td>
      )
      }
    if(props.field==='accountType'){
      return (
          <td style={{ textAlign: 'left' }}>
              {props.dataItem[props.field]}
          </td>
      )
      }
      if(props.field==='accountName'){
        return (
            <td style={{ textAlign: 'left' }}>
                {props.dataItem[props.field]}
            </td>
        )
        }
      if(props.field==='tranTypNm'){
        return (
            <td style={{ textAlign: 'left' }}>
                {props.dataItem[props.field]}
            </td>
        )
        }
    if(props.field==='totalLine'){
    return (
        <td style={{ textAlign: 'left' }}>
            {props.dataItem[props.field]}
        </td>
    )
    }
    if(props.field==='administrator'){
      return (
          <td style={{ textAlign: 'left' }}>
              {props.dataItem[props.field]}
          </td>
      )
      }
      if(props.field==='investmentOfficer'){
        return (
            <td style={{ textAlign: 'left' }}>
                {props.dataItem[props.field]}
            </td>
        )
        }
        if(props.field==='pCash'){
          return (
              <td style={{ textAlign: 'right' }}>
                  {formatNumber(props.dataItem[props.field], "##,#.00")}
              </td>
          )
          }
          if(props.field==='iCash'){
            return (
                <td style={{ textAlign: 'right' }}>
                    {formatNumber(props.dataItem[props.field], "##,#.00")}
                </td>
            )
            }
            if(props.field==='shares'){
              return (
                  <td style={{ textAlign: 'right' }}>
                      
                      {formatNumber(props.dataItem[props.field], "##,#.00")}
                  </td>
              )
              }
              
  }
  const cellRender = (tdElement, cellProps) => {
  
    if (cellProps.rowType === 'groupFooter') {
  
      if (cellProps.field === "shares") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem.aggregates.shares.sum, "##,#.00")}
          </td>
        );
      }
  
      if (cellProps.field === "iCash") {
        debugger;
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem.aggregates.iCash.average, "##,#.00")}
          </td>
        );
      }
  
      if (cellProps.field === "pCash") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem.aggregates.pCash.average, "##,#.00")}
          </td>
        );
      }
  
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "prcsDt") {
  
        let cdt=new Date(cellProps.dataItem["prcsDt"]);
        return (
          <td style={{ textAlign: 'left' }}>
            {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
          </td>
        )
      }
      if (cellProps.field === "effectiveDate") {
        let cdt=new Date(cellProps.dataItem["effectiveDate"]);
        return (
          <td style={{ textAlign: 'left' }}>
            {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
          </td>
        )
      }
      if (cellProps.field === "entryDate") {
  
        let cdt=new Date(cellProps.dataItem["effectiveDate"]);
        return (
          <td style={{ textAlign: 'left' }}>
            {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
          </td>
        )
      }
      if (cellProps.field === "tradeDate") {
  
        let cdt=new Date(cellProps.dataItem["tradeDate"]);
        return (
          <td style={{ textAlign: 'left' }}>
            {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
          </td>
        )
      }
      if (cellProps.field === "holdingDate") {
  
        let cdt=new Date(cellProps.dataItem["holdingDate"]);
        return (
          <td style={{ textAlign: 'left' }}>
            {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
          </td>
        )
      }
      if (cellProps.field === "pCash") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "iCash") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "shares") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
             {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "processCode") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
             {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "sequenceNumber") {
  
        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
             {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
  
    return tdElement;
  };
  return (
    <ResponsiveContainer>
      <div><h5 className='text-fitek p-1'>Account Transaction</h5>
    <Grid id='tileGrid'
              style={{ height: 'auto' }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              
              expandField="expanded"
              //onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              resizable={true}
              //pageable={true}
              //pageSize={20}
        
              groupable={{
                footer: 'visible',
                enabled:false
              }}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      footerCell={column.footerCell}
                      //cell={NumberCell}
                      title={column.title}
                      headerClassName={column.headerClassName}

                      filter={column.filter}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenuNoGrp
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid></div></ResponsiveContainer>
  )
}





export default AcctTransactionGridWidget