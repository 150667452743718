import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';

import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ColumnMenu } from "./columnMenu";
import { CustomColumnMenuNoGrpChkBox } from './customColumnMenuNoGrpChkBox';
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from 'react-bootstrap/Modal';
import Loading from './loading';
import Enumerable from 'linq';
import BankLogoPage from './bankLogoPage';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
const AssetVsModelGrid = ({ data, acct, allmodelData, selModelId, flag }) => {
  debugger;
  let dispAssetClass="";
  var i=0;
  for(i=0;i<data.t1.length;i++){
    debugger;
    if(data.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1"){
      data.t1[i].descption="^"+data.t1[i].descption;
    }
   if(data.t1[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1")
   {dispAssetClass= "^ Asset Class Contains IM Excluded Asset(s)";}
    
  }
  const unexeccash=JSON.stringify(data.unexeccash) ;
  const tradecash=JSON.parse(data.tradecash) ;
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [sort, setSort] = React.useState([]);
  const [loadingChild, setLoadingChild] = useState(true);
  const [invMixVal, setInvMixVal] = useState(selModelId === -1 ? true : false);
  debugger;
  //var mdlnm=Enumerable.from(allmodelData).where(w => w.modelId === selModelId).toArray()===[]?"":Enumerable.from(allmodelData).where(w => w.modelId === selModelId).toArray();
 // var test=(allmodelData.find((ele) => { return ele.modelId === selModelId })).modelId;
  debugger;
  const initialModelDropdown = {
    
    modelId: selModelId,
    modelNm: allmodelData.length > 0 ? (allmodelData.find((ele) => { return ele.modelId === selModelId })).modelNm : ""
 
  };
  debugger;
  const [selChangeModel, setSelChangeModel] = React.useState([]);

  const assetVsColorModel = data.ocAssetColorModel;
  debugger;
  const assetVsModelFinalData = Enumerable.from(data.t1)
    .join(
      assetVsColorModel,
      pk => pk.groupId,
      fk => fk.mjrAstTypeId,
      (left, right) => ({ ...left, ...right })
    )
    .toArray();



  const [allData, setAlldata] = useState(Enumerable.from(assetVsModelFinalData).where(w => w.prtfolio >= 0)
    .toArray());
  //setAlldata(assetVsModelFinalData);
  const initialDataState = {};
  let _pdfExport;
  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React.useState(
    process(allData, initialDataState)
  );
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const gridRef = useRef();
  const _export = React.useRef(null);
  const showpreview = async () => {
    setShow(true);
  }

  const menuWithExcelCheck = (props) => {
    debugger;
    return (
      <div>
      
    <CustomColumnMenuNoGrpChkBox
                         {...props}
          columns={stateColumns}
          data={data.t1}
         onColumnsSubmit={onColumnsSubmit}
         />
         </div>)
  };
  
  const menuWithoutExcelCheck = (props) => {
    debugger;
    return (
      <div>
      
    <CustomColumnMenuNoGrp
                         {...props}
          columns={stateColumns}
         onColumnsSubmit={onColumnsSubmit}
         />
         </div>)
  };
  const excelExport = () => {


    if (_export.current !== null) {
      _export.current.save(allData);
    }
  };

  let allPDFExport;
  const printPDF = async () => {
    debugger;

    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const PageTemplate = (props) => {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: "1px",
                    width:"98%",
                    borderBottom: "1px solid #bce8f1"
                   
                }}
            >
               
               <div className='row d-flex mx-3'>
                    <div className='col text-start'>
                    <a className='px-2'  ><BankLogoPage /></a>

                    </div>
                    <div className='col text-end px-5 py-2'>
                    <h2 className='fw-bold text-fitek'  >Asset Allocation Vs Model Report</h2>

                    </div>


                   </div>
                
               
            </div>
                   
            <div
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  //  borderTop: "1px solid #bce8f1"
                }}
            >
                Page {props.pageNum} of {props.totalPages}
            </div>
        </div>
    );
};
  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: 'right' }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    )
  }

  const labelContent = (e) => `${e.value.toFixed(2)}%`;

  const labelContent1 = (props) => {


    let formatedNumber = Number(props.dataItem.prtfolioWeigh).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return `${props.category}  ${props.dataItem.prtfolioWeigh.toFixed(2)}%`;
  };

  const labelContentAccVMdl = (props) => {

    let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return `${props.category}  ${props.dataItem.mdlWegh.toFixed(2)}%`;
  };

  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;

  const totalSum = (props) => {
    const field = props.field || "";
    const total = allData.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : '',
        };
      } else {
        return { ...col, show: false };
      }
    });
    debugger;
    newColumnsState[1].footerCell = totalSum;
    newColumnsState[2].footerCell = totalSum;
    newColumnsState[3].footerCell = totalSum;
    newColumnsState[4].footerCell = totalSum;
    newColumnsState[5].footerCell = totalSum;
    newColumnsState[6].footerCell = totalSum;
    newColumnsState[0].columnMenu = menuWithExcelCheck;
    newColumnsState[1].columnMenu = menuWithoutExcelCheck;
    newColumnsState[2].columnMenu = menuWithoutExcelCheck;
    newColumnsState[3].columnMenu = menuWithoutExcelCheck;
    newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  let defaultColumns = [
    {
      title: 'Descption',
      field: 'descption',
      minWidth: 230,
      show: true,
      filter: 'text'
    },
    {
      title: 'Portfolio($)',
      field: 'prtfolio',
      minWidth: 175,
      show: true,
      filter: 'numeric',
      headerClassName: 'rightHeader'
    },
    {
      title: '% Portfolio Weighting',
      field: 'prtfolioWeigh',
      minWidth: 175,
      show: true,
      filter: 'numeric',

      headerClassName: 'rightHeader'
    },
    {
      title: 'Model($)',
      field: 'mdl',
      minWidth: 180,

      className: 'rightHeader',
      show: true,
      filter: 'numeric',
      headerClassName: 'rightHeader'
    },
    {
      title: '% Model Weighting',
      field: 'mdlWegh',
      minWidth: 175,
      show: true,
      filter: 'numeric',
      headerClassName: 'rightHeader'
    },
    {
      title: '% Variance to Model',
      field: 'varitoMdl',
      minWidth: 175,
      show: true,
      filter: 'numeric',
      headerClassName: 'rightHeader'
    },
    {
      title: '$ Variance to Model',
      field: 'varitoMdlVal',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      headerClassName: 'rightHeader'
    },
  ];

  debugger;
  let loadedColumns = localStorage.getItem('gridColumns');
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;

  GridColumns[1].footerCell = totalSum;
  //GridColumns[1].cell=NumberCell;
  GridColumns[2].footerCell = totalSum;
  //GridColumns[2].cell=NumberCell;
  GridColumns[3].footerCell = totalSum;
  //GridColumns[3].cell=NumberCell;
  GridColumns[4].footerCell = totalSum;
  //GridColumns[4].cell=NumberCell;
  GridColumns[5].footerCell = totalSum;
  //GridColumns[5].cell=NumberCell;
  GridColumns[6].footerCell = totalSum;
  GridColumns[0].columnMenu = menuWithExcelCheck;
  GridColumns[1].columnMenu = menuWithoutExcelCheck;
  GridColumns[2].columnMenu = menuWithoutExcelCheck;
  GridColumns[3].columnMenu = menuWithoutExcelCheck;
  GridColumns[4].columnMenu = menuWithoutExcelCheck;
  GridColumns[5].columnMenu = menuWithoutExcelCheck;
  GridColumns[6].columnMenu = menuWithoutExcelCheck;
  //GridColumns[6].cell=NumberCell ;
  const [stateColumns, setStateColumns] = useState(GridColumns);



  const cellRender = (tdElement, cellProps) => {
    debugger;
    // if (
    //   cellProps.rowType === 'groupHeader' &&
    //   tdElement &&
    //   tdElement.props.role !== 'presentation'
    // ) {
    //   //IMPORTANT - You need to add collection with the columns and their field name
    //   //you can define the Grid columns outside of the Grid and reuse them here.

    //   const columns = GridColumns;
    //   return (
    //     <>
    //       <td
    //         {...tdElement.props}
    //         colSpan={tdElement.props.colSpan - columns.length}
    //       ></td>
    //       {getCells(columns, cellProps)}
    //     </>
    //   );
    // }
    // if (cellProps.rowType === 'footer') {
    //     // debugger;
    //     // if (cellProps.field === 'groupName') {              
    //     //   return (
    //     //     <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //     //       Total
    //     //     </td>
    //     //   );
    //     // }
    //     debugger;
    //     if (cellProps.field === 'prtfolio') {
    //       return (
    //         <td aria-colindex={cellProps.columnIndex} role={'footerCell'}>
    //           {cellProps.dataItem.aggregates.proceeds.sum}
    //         </td>
    //       );
    //     }
    // if (cellProps.field === 'short') {
    //   return (
    //     <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //       {cellProps.dataItem.aggregates.short.sum}
    //     </td>
    //   );
    // }
    // if (cellProps.field === 'iLong') {
    //   return (
    //     <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //       {cellProps.dataItem.aggregates.iLong.sum}
    //     </td>
    //   );
    // }
    // if (cellProps.field === 'gainLoss') {
    //   return (
    //     <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //       {cellProps.dataItem.aggregates.gainLoss.sum}
    //     </td>
    //   );
    // }
    //}
    if (cellProps.rowType === "data") {

      if (cellProps.field === "prtfolio") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "prtfolioWeigh") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.000")}
          </td>
        );
      }
      if (cellProps.field === "mdl") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "mdlWegh") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.000")}
          </td>
        );
      }
      if (cellProps.field === "varitoMdl") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.000")}
          </td>
        );
      }
      if (cellProps.field === "varitoMdlVal") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 26;//let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem('gridColumns');
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTSaveGridColumn/Index',
      postData,
      config
    )
      .then(response => {

        console.log(response);
        const rowData = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          debugger;
          refreshToken(2);

        }
        return error;
      });
  }
  const saveColumnsState = (columns) => {
    console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem('gridColumns', currentColumnsState);

    saveColumnStateDb();

  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);



  useEffect(() => {
    // Good!
    setSelChangeModel(initialModelDropdown);
    localStorage.setItem('ModelNm', initialModelDropdown.modelNm);
    setLoadingChild(false);
    localStorage.setItem('StatusInvMix', 'false');
    if (selModelId === -1) {
      localStorage.setItem('StatusInvMix', 'true');
      getChangeInfo(selChangeModel.modelId);
    }

    // setChartTypeLabel(labelContent1);
    // Side-effect!
  }, []);

  const handleChangeAllocModel = (e) => {
    setSelChangeModel(e.target.value);
    // e.preventDefault();
    localStorage.setItem('StatusInvMix', 'false');

    localStorage.setItem('ModelNm', e.target.value.modelNm);

    getChangeInfo(e.target.value.modelId)

  }

  const handleInvMix = (e) => {

    setInvMixVal(e.target.checked)
    localStorage.setItem('StatusInvMix', e.target.checked);
    getChangeInfo(selChangeModel.modelId);
  }

  const getChangeInfo = async (pModelId) => {

    setLoadingChild(true);
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem('userId'));

    let lsSelectedAcct = acct;
    let AcctId = 0;
    let PageId = 1;
    let modelId = pModelId;//JSON.parse(localStorage.getItem("modelId"));
    let statInvMix = JSON.parse(localStorage.getItem('StatusInvMix'));

    let invMix = 0;
    if (statInvMix)
      invMix = 1;

    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    const postData = { AsOfId, AcctId, modelId, PageId, invMix };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTAssetAllocationVsModel',
      postData,
      config
    )
      .then(response => {

        //  console.log(response);

        const rowData = response.data;

        const assetVsColorModel = rowData.ocAssetColorModel;

        const assetVsModelFinalData = Enumerable.from(rowData.t1)
          .join(
            assetVsColorModel,
            pk => pk.groupId,
            fk => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
          )
          .toArray();




        setAssetAllocModelRptData(rowData);
        //  populateTimeWtdRtnRptRptData(rowData.lstRTTimeWtdReturnT1);
        //  populateAccountListData(rowData.lstAcctTmWtdRtnList)
        // setSelAcctData(rowData.lstAcctTmWtdRtnList);
        if (lsSelectedAcct !== null) {

          if (JSON.parse(localStorage.getItem('StatusInvMix')))
            setAlldata(Enumerable.from(rowData.t1).where(w => w.prtfolio >= 0)
              .toArray());
          else
            setAlldata(Enumerable.from(assetVsModelFinalData).where(w => w.prtfolio >= 0)
              .toArray());

          setResultState(Enumerable.from(rowData.t1).where(w => w.prtfolio >= 0)
            .toArray());
          // SetselAcct(lsSelectedAcct);
        }
        else {
          //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        setLoadingChild(false);
        // setFlag(1);
      })
      .catch((error) => {

        if (error.response.status === 401) {
          refreshToken(1);


        }
        return error;
      });

  }

  const onDataStateChange = React.useCallback((e) => {

    setDataState(e.dataState);
    // let gridData = JSON.parse(localStorage.getItem('gridData'));
    // const groups = e.dataState.group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }
    // e.dataState.group = groups;
    setResultState(process(allData, e.dataState));
  }, []);

  const refreshToken = async (x) => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {

        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));
        if (x === 1) {
          getChangeInfo(selChangeModel.modelId);
        }
        else {
          saveColumnStateDb();
        }



      })
      .catch((error) => {
        // debugger;
        console.log("my error is " + error);
      })

  }


  const grid = (
    <Grid style={{ height: "auto" }}


      data={resultState}
      resizable={true}
      reorderable={true}
      sortable={true}
      groupable={{
        enabled: false,
        footer: 'visible',
      }}
      ref={gridRef.current}
      onColumnReorder={onColumnReorder}
      onColumnResize={onColumnResize}
      onDataStateChange={onDataStateChange}
      {...dataState}
      cellRender={cellRender}

    >


      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              key={idx}
              field={column.field}
              title={column.title}
              headerClassName={column.headerClassName}

              {...column}
              columnMenu={column.columnMenu}
              // columnMenu={(props) => (
              //   <CustomColumnMenuNoGrp
              //     {...props}

              //     columns={stateColumns}
              //     onColumnsSubmit={onColumnsSubmit}
              //   />
              // )}
            />
          )
      )}
    </Grid>
  );
 
  if (loadingChild)
    return <Loading />
  else
    return (
      <div>
        <div className="row mx-1 my-2">

          <div className="col col-md-12 col-sm-12 py-2">
            <div className="card-header row d-flex justify-content-between align-items-center my-2">
              <div className="col">
                <p className="tableheader h6">Asset Allocation Vs Model Report</p>
              </div>
              <div className='subheader col-lg text-end'>Due to rounding, percentage may not equal 100.</div>
              <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                                    <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                                </DropdownButton>


                            </div>
              



              {/* <div className="col">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={excelExport}
                >
                  Export to Excel
                </button>
                &nbsp;
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={showpreview}
                >
                  Preview
                </button>

              </div> */}

            </div>

            <ExcelExport data={allData} ref={_export}>

            <ExcelExportColumnGroup
             title={"Account Number: "+JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId+"  Processing Date: " + localStorage.getItem("processingDate") }
             headerCellOptions={{
               textAlign: "left",
             }}
           >
              
               
              <ExcelExportColumn field="descption" title="Descption" width={260}  />
              <ExcelExportColumn field="prtfolio" title="Portfolio($)" headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}  width={170}   />
              <ExcelExportColumn field="prtfolioWeigh" headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.000", }}  title="% Portfolio Weighting" width={170}  />
              <ExcelExportColumn field="mdl" title="Model($)" headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}  width={150}  />
              <ExcelExportColumn field="mdlWegh" width={170} headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.000", }}   title="% Model Weighting"   />
              <ExcelExportColumn field="varitoMdl" width={170} headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.000", }}    title="% Variance to Model"   />
              <ExcelExportColumn field="varitoMdlVal"width={170}  headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}   title="$ Variance to Model"   />
    
              
             
           </ExcelExportColumnGroup>
           </ExcelExport>
              {grid}
       
<p>{unexeccash==="0"?"":"Of the "+tradecash+" Trading cash, "+unexeccash+" is in unexecuted trades"}</p>
{dispAssetClass}
            <div className="row mx-1 my-2 pb-3 mb-5">

              <div className="col col-md-6 col-sm-10 py-2">
                <div className="col-md-12 card-header tableheader">Portfolio Allocation  </div>
                <div className="card rounded h-100">

                  <div className="w-100">
                    <Chart style={{ height: "440px" }}>
                      {/* <ChartTitle text="Major Asset Chart" /> */}
                      <ChartLegend position="bottom" />

                      <ChartSeries>
                        <ChartSeriesItem
                          type="pie"
                          data={Enumerable.from(allData).where(w => w.prtfolio > 0)
                            .toArray()}
                          colorField='chartColorCOde'
                          field="prtfolio"
                          categoryField="descption"
                          autoFit={true}
                          labels={{
                            visible: true,
                            content: labelContent1,
                          }}
                        />
                      </ChartSeries>
                    </Chart>

                  </div>
                </div>
              </div>

              <div className="col col-md-6 col-sm-10 py-2">
                <div className="col-md-12 card-header tableheader">Model Allocation

                </div>

                <div className="card rounded h-100">

                  <Chart style={{ height: "440px" }}>
                    {/* <ChartTitle text="Major Asset Chart" /> */}
                    <ChartLegend position="bottom" />

                    <ChartSeries>
                      <ChartSeriesItem
                        type="pie"
                        data={Enumerable.from(allData).where(w => w.mdl > 0)
                          .toArray()}
                        field="mdlWegh"
                        categoryField="descption"
                        colorField='chartColorCOde'
                        autoFit={true}
                        labels={{
                          visible: true,
                          content: labelContentAccVMdl,
                        }}
                      />
                    </ChartSeries>
                  </Chart>
                  <hr></hr>

                  <div className='row my-1'>
                    <div className='col text-left mx-2 my-1'>
                      <DropDownList
                        style={{
                          width: "320px",
                        }}
                        data={allmodelData}
                        textField="modelNm"
                        valueField="modelId"
                        dataItemKey="modelId"
                        filterable={false}
                        disabled={invMixVal}
                        //defaultItem={initialModelDropdown}
                        value={selChangeModel}
                        onChange={handleChangeAllocModel}

                      />
                    </div>
                  </div>
                  <div className='row my-1 mx-2'>
                    <div className='form-check' >


                      <input className='form-check-input' type='checkbox' name='chkInvTrgMix' checked={invMixVal} onChange={handleInvMix}  ></input>
                      <label className='form-check-label'>Compare against Investment Target Mix </label>

                    </div>
                  </div>
                </div>

              </div>

            </div>


            <div className="row mx-1 my-2">
              <div className="col-md-12 card-header tableheader">Portfolio vs Model </div>
              <div className="col col-md-12 col-sm-12 py-2">
                <div className="card rounded">

                  <div className="w-100">
                    <Chart
                      // seriesColors={chartDefaultV4Colors}
                      zoomable={false}
                    >
                      <ChartLegend position="bottom" />
                      <ChartValueAxis>
                        <ChartValueAxisItem
                          // title={{
                          //     text: "Percentage",
                          // }}
                          min={0}
                        // max={100}
                        />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          //    title={{
                          //     text: "category",
                          // }}
                          labels={{
                            visible: true,
                            rotation: 50,
                            format: "d",

                          }}

                        //  categories={categoryAxis} 
                        />
                      </ChartCategoryAxis>
                      <ChartSeriesDefaults
                        type="column"
                        labels={{
                          visible: true,
                          format: "n2",
                        }}
                      />

                      {/* <ChartValueAxis>
                    <ChartValueAxisItem crosshair={crosshair} />
                </ChartValueAxis> */}
                      <ChartTooltip render={defaultTooltipRender} />

                      <ChartSeries>

                        <ChartSeriesItem
                          data={allData}
                           colorField='#006fba'
                          type='column'
                          field='prtfolioWeigh'
                          categoryField='descption'
                          //name='Portfolio'
                          // aggregate='sum'
                          labels={{
                            visible: true,
                            content: labelContent,
                          }}
                          name='Portfolio'
                        />

                        <ChartSeriesItem
                          data={allData}
                           colorField='#dec4ea'
                          type='column'
                          field='mdlWegh'
                          categoryField='descption'
                          //name='Model'
                          // aggregate='sum'
                          labels={{
                            visible: true,
                            content: labelContent,
                          }}
                          name='Model'
                        />

                      </ChartSeries>

                    </Chart>

                  </div>
                </div>
              </div>

            </div>



            {/* <GridPDFExport
              ref={(element) => {
                _pdfExport = element;
              }}
              margin={{ top: 40, left: 30, right: 30, bottom: 40 }}
              pageTemplate={PageTemplate}
              forcePageBreak=".page-break"
              paperSize="A4"
              scale={0.5}
              allPages={true}
              //margin="1cm"
              landscape={true}
              repeatHeaders={true}
            >
              {grid}
            </GridPDFExport> */}
          </div>
        </div>


        <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
          <Modal.Header ><div className="row w-100 tableheader">
            <div className='col'>
              <Modal.Title>Asset Allocation Vs Model Report - Pdf Preview</Modal.Title>
            </div>
            <div className='col text-end'>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={printPDF}
              >
                Print
              </button>
              <button className="btn btn-outline-danger  btn-sm" onClick={handleClose}>
                                Close
                                

                            </button>
            </div>


          </div>
          </Modal.Header>
          <Modal.Body>
            <PDFExport
              margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
              pageTemplate={PageTemplate}
              forcePageBreak=".page-break"
              paperSize="A4"
              scale={0.5}
              allPages={true}
              //margin="1cm"
              landscape={true}
              repeatHeaders={true}

              ref={(pdfExport) => (allPDFExport = pdfExport)}>
                <div className="card-header row d-flex justify-content-between align-items-center my-2">
                <div className='subheader col-lg text-start'>Account Name : {acct.extrnlAcctId}</div>
              <div className='subheader col-lg text-end'>Due to rounding, percentage may not equal 100.</div>
              <div className='subheader col-lg text-end'>As of Date : {formatDate(new Date(data.processingDate), 'd')}   Price Date : {formatDate(new Date(data.processingDate), 'd')}</div>
              
              
              
              </div>
              <Grid style={{ height: "auto" }}


                data={resultState}
                resizable={false}
                reorderable={false}
                sortable={true}
                groupable={{
                  enabled: false,
                  footer: 'visible',
                }}
                ref={gridRef.current}
                onColumnReorder={onColumnReorder}
                onColumnResize={onColumnResize}
                onDataStateChange={onDataStateChange}
                {...dataState}
                cellRender={cellRender}

              >


                {stateColumns.map(
                  (column, idx) =>
                    column.show && (
                      <Column
                        width={setWidth(column.minWidth)}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        headerClassName={column.headerClassName}

                        {...column}

                      />
                    )
                )}
              </Grid>
              <span class='page-break'></span>
              <div className="row mx-1 my-2 pb-3 mb-5">

                <div className="col col-md-6 col-sm-10 py-2">
                  <div className="col-md-12 card-header tableheader">Portfolio Allocation  </div>
                  <div className="card rounded h-100">

                    <div className="w-100">
                    <Chart style={{ height: "440px" }}>
                      {/* <ChartTitle text="Major Asset Chart" /> */}
                      <ChartLegend position="bottom" />

                      <ChartSeries>
                        <ChartSeriesItem
                          type="pie"
                          data={Enumerable.from(allData).where(w => w.prtfolio > 0)
                            .toArray()}
                          colorField='chartColorCOde'
                          field="prtfolio"
                          categoryField="descption"
                          autoFit={true}
                          labels={{
                            visible: true,
                            content: labelContent1,
                          }}
                        />
                      </ChartSeries>
                    </Chart>

                    </div>
                  </div>
                </div>

                <div className="col col-md-6 col-sm-10 py-2">
                  <div className="col-md-12 card-header tableheader"> Model Allocation : {JSON.parse(localStorage.getItem('StatusInvMix')) ? "" : initialModelDropdown.modelNm}

                  </div>

                  <div className="card rounded h-100">

                    <Chart style={{ height: "440px" }}>
                      {/* <ChartTitle text="Major Asset Chart" /> */}
                      <ChartLegend position="bottom" />

                      <ChartSeries>
                        <ChartSeriesItem
                          type="pie"
                          data={Enumerable.from(allData).where(w => w.mdl > 0)
                            .toArray()}
                          field="mdlWegh"
                          categoryField="descption"
                          colorField='chartColorCOde'
                          autoFit={true}
                          labels={{
                            visible: true,
                            content: labelContentAccVMdl,
                          }}
                        />
                      </ChartSeries>
                    </Chart>
                    <hr></hr>


                  </div>

                </div>

              </div>

              <span class='page-break'></span>
              <div className="row mx-1 my-2">
                <div className="col-md-12 card-header tableheader">Portfolio vs Model </div>
                <div className="col col-md-12 col-sm-12 py-2">
                  <div className="card rounded">

                    <div className="w-100">
                      <Chart
                        // seriesColors={chartDefaultV4Colors}
                        zoomable={false}
                      >
                        <ChartLegend position="bottom" />
                        <ChartValueAxis>
                          <ChartValueAxisItem
                            // title={{
                            //     text: "Percentage",
                            // }}
                            min={0}
                          // max={100}
                          />
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem
                            //    title={{
                            //     text: "category",
                            // }}
                            labels={{
                              visible: true,
                              rotation: 50,
                              format: "d",

                            }}

                          //  categories={categoryAxis} 
                          />
                        </ChartCategoryAxis>
                        <ChartSeriesDefaults
                          type="column"
                          labels={{
                            visible: true,
                            format: "n2",
                          }}
                        />

                        {/* <ChartValueAxis>
                    <ChartValueAxisItem crosshair={crosshair} />
                </ChartValueAxis> */}
                        <ChartTooltip render={defaultTooltipRender} />

                        <ChartSeries>

                          <ChartSeriesItem
                            data={allData}
                            // colorField='chartColorCOde'
                            type='column'
                            field='prtfolioWeigh'
                            categoryField='descption'
                            //name='Portfolio'
                            // aggregate='sum'
                            labels={{
                              visible: true,
                              content: labelContent,
                            }}
                            name='Portfolio'
                          />

                          <ChartSeriesItem
                            data={allData}
                            // colorField='chartColorCOde'
                            type='column'
                            field='mdlWegh'
                            categoryField='descption'
                            //name='Model'
                            // aggregate='sum'
                            labels={{
                              visible: true,
                              content: labelContent,
                            }}
                            name='Model'
                          />

                        </ChartSeries>

                      </Chart>

                    </div>
                  </div>
                </div>

              </div>

            </PDFExport>

          </Modal.Body>
          <Modal.Footer>



            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>


          </Modal.Footer>

        </Modal>

      </div>
    )
}

export default AssetVsModelGrid
