import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';

import {sectorCmprsnDatavar} from './cstmRptMain';//change Data!!!!!!!!!!!

const SectorComparisionChartWidget = () => {
debugger;
    const [chartType, setChartType] = useState("column");
    const [astModelChartdata, setastModelChartdata] = useState(sectorCmprsnDatavar);
    const chartDefaultV4Colors = [
        "#235ee7",
        "#dce4ea",
        "#4ac9c9",
        "#d3dffb",
        "#7c9ff2",
        "#f3b021",
        "#f8d0c0",
    ];
    const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.marketPercent.toFixed(2)}%`;
    };
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
    return (
        <><h5 className='text-fitek p-1'>Sector Comparison By Chart

        </h5>      
            <ResponsiveContainer className='page-break'>
              {astModelChartdata.length===0?<div>No Data Found.</div>:
            <Chart style={{ height: "550px" }}>
                    {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        labels={{
                          visible: true,
                          rotation: 85,
                          format: "d",
                        }}
                      //  categories={categoryAxis} 
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip render={defaultTooltipRender} />
                    <ChartSeries>
                      <ChartSeriesItem
                        name='Portfolio Holdings'
                        type="column"
                        data={astModelChartdata}
                        categoryField="groupHeader"
                        field="marketPercent"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='Model Weight'
                        type="column"
                        data={astModelChartdata}
                        categoryField="groupHeader"
                        field="bmPcnt"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                    </ChartSeries>
                  </Chart>}
            </ResponsiveContainer></>
    
     
  )
}

export default SectorComparisionChartWidget

