import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenu } from './customColumnMenu';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { CustomColumnMenuNoGrpChkBox } from './customColumnMenuNoGrpChkBox';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
const aggregates = [
  {
    field: "proceeds",
    aggregate: "sum",
  },
  {
    field: "short",
    aggregate: "sum",
  },
  {
    field: "iLong",
    aggregate: "sum",
  },
  {
    field: "gainLoss",
    aggregate: "sum",
  }
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "10px",
          textAlign: "center",
          backgroundColor: "#f4f4f2",
        }}
      >
        <div className='fw-bold page-header table-header'>Demo Bank</div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};
const PrchsSaleGrid = ({ data, flag, accntSelected }) => {
  debugger;
  const menuWithExcelCheck = (props) => {
    debugger;
    return (
      <div>
      
    <CustomColumnMenuNoGrpChkBox
                         {...props}
          columns={stateColumns}
          data={data}
         onColumnsSubmit={onColumnsSubmit}
         />
         </div>)
  };

  const menuWithoutExcelCheck = (props) => {
    debugger;
    return (
      <div>
      
    <CustomColumnMenuNoGrp
                         {...props}
          columns={stateColumns}
          data={data}
         onColumnsSubmit={onColumnsSubmit}
         />
         </div>)
  };

  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || '';
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, '##,#.00')}
      </td>
    );
  };
  const RightNameHeader = (props) => {
    return (
      <a className="k-link" style={{
        float: "right",
      }} onClick={props.onClick}>
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={{
            // color: "#53d2fa",
          }}
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };
  let defaultColumns = [
    {
      title: 'Date',
      field: 'trdDt',
      minWidth: 150,
      show: true,
      filter: 'date',
      locked: true,
    },
    {
      title: 'Purchase & Sell',
      field: 'buySell',
      minWidth: 150,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Security Description',
      field: 'astShrtNm',
      minWidth: 250,
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares/Par Value',
      field: 'shares',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Avg Price',
      field: 'avgPrice',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Proceeds',
      field: 'proceeds',
      minWidth: 180,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Short G/L',
      field: 'short',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Long G/L',
      field: 'iLong',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Gain Loss',
      field: 'gainLoss',
      minWidth: 160,
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
  ];
  let loadedColumns = localStorage.getItem('gridColumns');
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;
  const [row, setRow] = useState(data);
  GridColumns[1].columnMenu= menuWithExcelCheck;
  GridColumns[0].columnMenu= menuWithoutExcelCheck;
  GridColumns[2].columnMenu= menuWithExcelCheck;
  GridColumns[3].columnMenu= menuWithoutExcelCheck;
  GridColumns[4].columnMenu= menuWithoutExcelCheck;
  GridColumns[5].columnMenu= menuWithoutExcelCheck;
  GridColumns[6].columnMenu= menuWithoutExcelCheck;
  GridColumns[7].columnMenu= menuWithoutExcelCheck;
  GridColumns[8].columnMenu= menuWithoutExcelCheck;
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 10,
    skip: 0,
    group: [{ field: 'buySell' }]
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: 'buySell' }]
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExport(processWithGroups(data, updatedState.dataState));
    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : '',
        };
      } else {
        return { ...col, show: false };
      }
    });
    newColumnsState[1].columnMenu= menuWithExcelCheck;
    newColumnsState[0].columnMenu= menuWithoutExcelCheck;
    newColumnsState[2].columnMenu= menuWithExcelCheck;
    newColumnsState[3].columnMenu= menuWithoutExcelCheck;
    newColumnsState[4].columnMenu= menuWithoutExcelCheck;
    newColumnsState[5].columnMenu= menuWithoutExcelCheck;
    newColumnsState[6].columnMenu= menuWithoutExcelCheck;
    newColumnsState[7].columnMenu= menuWithoutExcelCheck;
    newColumnsState[8].columnMenu= menuWithoutExcelCheck;
    return newColumnsState;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
 

  const printPDF = async () => {


    if (allPDFExport !== null) {

      allPDFExport.save();

    }
  };
  const saveColumnStateDb = async () => {
    //Storing column settings in DB
    debugger;
    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 4;//let 4 for Purchase Sale Report Grid
    let ColumnSettings = localStorage.getItem('gridColumns');
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTSaveGridColumn/Index',
      postData,
      config
    )
      .then(response => {

        console.log(response);
        const rowData = response.data;
      })
      .catch((error) => {

        return error;
      });
  }
  const saveColumnsState = (columns) => {
    console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem('gridColumns', currentColumnsState);

    saveColumnStateDb();

  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const [mnrRadioStat, setMnrRadioStat] = useState('checked');
  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;

  const handleClick = () => {
    setLocked(!locked);
  };

  const _grid = React.useRef();

  const [gridChartCheck, setgridChartCheck] = useState('checked');

  const [collapsedState, setCollapsedState] = React.useState([]);


  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    // if (
    //   cellProps.rowType === 'groupHeader' &&
    //   tdElement &&
    //   tdElement.props.role !== 'presentation'
    // ) {
    //   //IMPORTANT - You need to add collection with the columns and their field name
    //   //you can define the Grid columns outside of the Grid and reuse them here.

    //   const columns = GridColumns;
    //   return (
    //     <>
    //       <td
    //         {...tdElement.props}
    //         colSpan={tdElement.props.colSpan - columns.length}
    //       ></td>
    //       {getCells(columns, cellProps)}
    //     </>
    //   );
    // }
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field === 'trdDt') {
        //debugger;
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
            Total
          </td>
        );
      }
      if (cellProps.field === 'proceeds') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }} >
            {formatNumber(cellProps.dataItem.aggregates.proceeds.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'short') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.short.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'iLong') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.iLong.sum, '##,#.00')}
          </td>
        );
      }
      if (cellProps.field === 'gainLoss') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }} >
            {formatNumber(cellProps.dataItem.aggregates.gainLoss.sum, '##,#.00')}
          </td>
        );
      }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "shares") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "avgPrice") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "proceeds") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "short") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "iLong") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "gainLoss") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const exportPDF = () => {
    allPDFExport.save();
  }
  return (

    <div>
      {

        <div className='mx-1 px-1 row d-flex justify-content-between align-items-center my-2'>

          <div className='col'>
            <p className='reportheading fs-6'>Purchase & Sale Report</p>
          </div>

          <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                                    {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

                                </DropdownButton>


                            </div>
          
          {/* <div className='col'>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={excelExport}>Export to Excel</button>
            &nbsp;
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={exportPDF}
            >
              Export to PDF
            </button>
          </div> */}

        </div>

      }

      <div className="card-body">
        <div className="mx-1 my-1 py-1">
          <ExcelExport data={data} ref={_export} fileName={accntSelected.extrnlAcctId + "_Purchase&sale.xlsx"}>


          <ExcelExportColumnGroup
             title={"Account Number: "+JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId+"  Processing Date: " + localStorage.getItem("processingDate") }
             headerCellOptions={{
               textAlign: "left",
             }}
           >
              <ExcelExportColumn field="trdDt" title="Date"  width={100}  />
              <ExcelExportColumn field="buySell" title="Purchase & Sell" width={100}   />
              <ExcelExportColumn field="astShrtNm" title="Security Description" width={270}   /> 
              <ExcelExportColumn field="shares"  width={110} headerCellOptions={{ textAlign: "right", }}    cellOptions={{   format: "#,##0.00", }} title="Shares/Par Value"   />
              <ExcelExportColumn field="avgPrice"  width={150} headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }} title="Avg Price"   />
              <ExcelExportColumn field="proceeds"  width={150} headerCellOptions={{ textAlign: "right", }}    cellOptions={{   format: "#,##0.00", }} title="Proceeds"    />
              <ExcelExportColumn field="short"  width={120} headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}  title="Short"    />
              <ExcelExportColumn field="iLong"  width={120} headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}  title="Long G/L"    />
              <ExcelExportColumn field="gainLoss"  width={120} headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}  title="Gain Loss"    />
      
              
           </ExcelExportColumnGroup>
          </ExcelExport>
            <Grid
              style={{ width: '100%', height: "600px" }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              // scrollable={true}
              resizable={true}
              reorderable={true}
              pageable={true}
              pageSize={10}
              groupable={{
                footer: 'visible',
                enabled: false
              }}
              //
              ref={gridRef.current}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      // footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      {...column}
                      filter={column.filter}
                      columnMenu={column.columnMenu}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenuNoGrp
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
   
        </div>
      </div>

      <GridPDFExport
         margin={{ top: 40, left: 20, right: 20, bottom: 40 }}
        pageTemplate={PageTemplate}
        forcePageBreak=".page-break"
        paperSize="A4"
        scale={0.4}
        allPages={true} 
        //margin="1cm"
        landscape={true}
        repeatHeaders={true}

        ref={(pdfExport) => (allPDFExport = pdfExport)}>
            <Grid
              style={{ width: '100%' }}
              data={resultExport}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={false}
              resizable={false}
              reorderable={false}
              pageable={false}
              pageSize={Number.MAX_VALUE}
              groupable={{
                footer: 'visible',
                enabled: false
              }}

              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={"auto"}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      locked={false}
                      // footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                    {...column}
                    columnMenu={(props) => (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                    />
                  )
              )}
            </Grid>
         
      </GridPDFExport>
    </div>
  )
}

export default PrchsSaleGrid
